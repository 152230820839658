import './whichscores.scss'

const WhichScores = () => (
  <div className="which-scores display-flex flex-xs-column-only cb-gray5-bg cb-padding-xs-top-32 cb-padding-xs-bottom-32 cb-padding-sm-up-top-24 cb-padding-sm-up-bottom-48">
    <div className="align-self-center">
      <div aria-hidden={true} className="which-scores-img cb-margin-top-16" />
    </div>
    <div className="display-flex flex-column flex-xs-fill-only cb-margin-right-24 cb-margin-left-24">
      <h3 className="cb-roboto cb-padding-bottom-16 cb-margin-top-24">Which scores am I sending?</h3>
      <p className="cb-roboto-light cb-font-weight-xs-regular cb-paragraph1-res">
        <strong className="cb-roboto-medium">All released scores</strong> except for those canceled, withheld, or archived are automatically
        sent with your order.
      </p>
      <p className="cb-roboto-light cb-font-weight-xs-regular cb-paragraph1-res">
        If you do not wish to send all your scores, you have an option to{' '}
        <a href="https://apstudents.collegeboard.org/score-reporting-services/withhold-scores" className="cb-black1-color">
          withhold
        </a>{' '}
        or{' '}
        <a href="https://apstudents.collegeboard.org/score-reporting-services/cancel-scores" className="cb-black1-color">
          cancel
        </a>{' '}
        a score.
      </p>
    </div>
  </div>
)

export default WhichScores
