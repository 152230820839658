import { TYPE_INSTITUTION } from '../components/student/pastorders/utils'
import { getDecodedToken } from './auth'
import { formatDate, FORMATS } from './date'
import { APP_LINKS } from './navigation'
import { getPriceProperties } from './orders'

const FLOW_CODE = 'aposrs'
const VIEW_SCOREACTIVE = 'active-scores'
const VIEW_SCORENONE = 'no-score'
const VIEW_SCOREARCHIVED = 'archived-only'
const VIEW_NOORDER = 'no-orders'
const VIEW_BYINSTITUTION = 'view-by-institution'
const VIEW_BYORDER = 'view-by-order'
const PAGE_VIEWSCORES = 'view-scores'
const PAGE_PASTORDERS = 'past-orders'
const PAGE_PANEL = 'panels'

// Error names to be tracked
export const CREDIT_POLICY_ERROR = 'CREDIT_POLICY_ERROR'
export const CATAPULT_ERROR = 'CATAPULT_ERROR'
export const JAVASCRIPT_ERROR = 'JAVASCRIPT_ERROR'
export const PDF_ERROR = 'PDF_ERROR'
export const VIEWSCORES_ERROR = 'VIEWSCORES_ERROR'
export const CMS_ERROR = 'CMS_ERROR'
export const PASTSCORESENDS_ERROR = 'PASTSCORESENDS_ERROR'
export const ORDER_DETAILS_ERROR = 'ORDER_DETAILS_ERROR'
export const TERMS_ERROR = 'TERMS_ERROR'
export const WITHHOLDS_ERROR = 'WITHHOLDS_ERROR'
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR'
export const COMPLETE_ORDER_ERROR = 'COMPLETE_ORDER_ERROR'

const callAnalytics = (appDataLayer, trackType) => {
  try {
    kiwi?.mergeDdl(appDataLayer)
    _satellite?.track(trackType)
  } catch (errKiwiMergeDdl) {
    try {
      _satellite?.logger?.error('Something went wrong with kiwi.mergeDdl(): ' + errKiwiMergeDdl.message)
    } catch (errSatelliteLoggerError) {}
  }
}

const dispatchTrackPage = (pageCode, appViewCode) =>
  callAnalytics({ page: { flowCode: FLOW_CODE, pageCode, appViewCode } }, 'cbTrack.viewInDom')

const dispatch = detail => {
  document.dispatchEvent(
    new CustomEvent('cbTrack-apScoresEvent', {
      bubbles: true,
      detail,
    })
  )
}

/* ERROR TRACKING */
export const trackError = (message, code = 500) => callAnalytics({ appError: { code, message } }, 'cbTrack.appError')

export const useTrackingError = (isError, errorType) => {
  useEffect(() => {
    if (isError) trackError(errorType)
  }, [isError])
}

/* PANEL TRACKING */
export const PANEL_ABOUT_SCORE = { pageCode: PAGE_PANEL, appViewCode: 'about-your-score' }
export const PANEL_PROJECTED = { pageCode: PAGE_PANEL, appViewCode: 'projected-score' }
export const PANEL_INFO = { pageCode: PAGE_PANEL, appViewCode: 'info-score' }
export const PANEL_WITHHOLD = { pageCode: PAGE_PANEL, appViewCode: 'withhold-score' }
export const PANEL_SUBSCORE = { pageCode: PAGE_PANEL, appViewCode: 'course-subscore' }
export const PANEL_ABOUT_AWARD = { pageCode: PAGE_PANEL, appViewCode: 'about-your-award' }
export const PANEL_GRADING = { pageCode: PAGE_PANEL, appViewCode: 'score-grading-scale' }
export const PANEL_CREDITPOLICY = { pageCode: PAGE_PANEL, appViewCode: 'di-credit-policy' }
export const PANEL_REVIEWORDER = { pageCode: PAGE_PANEL, appViewCode: 'review-score-order' }
export const PANEL_ORDERDETAILS = { pageCode: PAGE_PASTORDERS, appViewCode: 'order-detail' }

export const trackPanel = ({ pageCode, appViewCode }) => dispatchTrackPage(pageCode, appViewCode)
/* end panel tracking */

/* PAGE TRACKING */
// view scores and past score sends will be tracked separately because they have custom appViewCodes that require data to load first
export const trackPage = (route = {}) => {
  const { path } = route
  const [ pageCode, ...appViewCode] = path ? path.split('/') : []
  if (path !== APP_LINKS.viewscores && path !== APP_LINKS.pastorders && pageCode)
    dispatchTrackPage(pageCode, appViewCode.join(''))
}

export const trackPageViewScores = (data = {}) => {
  const { hasActiveScore, hasNoScores } = data
  dispatchTrackPage(PAGE_VIEWSCORES, hasNoScores ? VIEW_SCORENONE : hasActiveScore ? VIEW_SCOREACTIVE : VIEW_SCOREARCHIVED)
}

export const trackPagePastScoreSends = (hasData, view) => {
  const type = !hasData ? VIEW_NOORDER : !view ? null : view === TYPE_INSTITUTION ? VIEW_BYINSTITUTION : VIEW_BYORDER
  if (type) dispatchTrackPage(PAGE_PASTORDERS, type)
}
/* end page tracking */

/* EVENT TRACKING */
const getUserTrackData = scores => {
  const decodedToken = getDecodedToken()
  const gradDt = decodedToken?.cb?.dp?.graduationDate
  const userId = decodedToken?.cb?.aid
  return {
    maxApTestYear: scores[0]?.displayYear,
    userId,
    userGy: formatDate(gradDt, FORMATS.year),
  }
}
export const trackViewScores = scores => {
  dispatch({
    type: 'scores-view',
    ...getUserTrackData(scores),
  })
}

export const trackSendScores = (scores, order, props) => {
  const { eOrderId, orderTotal, diCodes, skipPayment } = order
  const { standardPrice } = getPriceProperties(props, skipPayment)
  const { standardSku } = props

  dispatch({
    type: 'scores-send',
    ...getUserTrackData(scores),
    eCommerce: {
      purchase: {
        transaction: {
          type: 'AP Score Sending',
          id: eOrderId,
          revenue: orderTotal,
        },
        products: diCodes.map(d => {
          return {
            category: 'AP',
            id: standardSku,
            price: standardPrice,
            quantity: 1,
            diCode: d.diCode,
          }
        }),
      },
    },
  })
}
/* end event tracking */
