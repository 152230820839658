import { PANEL_SUBSCORE } from '../../../../../../../utils/analytics'
import TooltipPanel from '../../../../../../_common/panel/TooltipPanel'
import ContentLookup from '../../../../../../_common/request/ContentLookup'

const SubscoreCard = ({ subScores, exCode, exName, adminYear }) => {
  const length = subScores.length
  const hasMultiSubscores = length > 1
  const contentKey = hasMultiSubscores ? 'music' : 'calculus'

  return length ? (
    <div
      className="cb-border-top-1 cb-margin-top-16 cb-padding-top-8 cb-align-center
      cb-padding-bottom-8 cb-gray4-border cb-border-solid cb-border-xs-bottom"
    >
      <span className="cb-padding-right-8 cb-no-padding-md-right cb-padding-md-left-4 cb-font-size-xsmall cb-gray1-color vertical-align-middle">
        <span className="cb-text-uppercase cb-padding-right-4">Subscore{hasMultiSubscores ? 's' : null}</span>
        <ContentLookup
          file="subscores"
          type={contentKey}
          component={({ content = {} }) => (
            <TooltipPanel
              trigger={`subscoreBtn-${adminYear}-${exCode}`}
              title={exName}
              content={content.more}
              tracking={PANEL_SUBSCORE}
            />
          )}
        />
      </span>{' '}
      <span className="cb-no-wrap cb-md-wrap">
        {subScores.map(({ score, exNameFormatted }, i) => (
          <span
            key={i}
            className={`cb-font-size-xsmall cb-gray1-color ${
              i ? 'cb-border-left-1 cb-gray4-border cb-border-solid cb-padding-left-8 cb-margin-left-8' : ''
            }`}
          >
            {exNameFormatted}: <strong className="cb-black1-color">{score}</strong>
          </span>
        ))}
      </span>
    </div>
  ) : null
}

SubscoreCard.propTypes = {
  subScores: PropTypes.array.isRequired,
  exCode: PropTypes.string.isRequired,
  exName: PropTypes.string.isRequired,
  adminYear: PropTypes.string.isRequired,
}

export default SubscoreCard
