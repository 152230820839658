import { Stepper as CBStepper, SimpleStep, GlyphStep } from '@cb/apricot-react'

import './stepper.scss'

const Stepper = ({ steps, simple, current = 1, label, left }) => {
  const arr = Array.isArray(steps) ? steps : new Array(steps).fill((v, i) => i)

  return (
    <div className={left ? 'stepper-align-left' : 'stepper-standard'}>
      <CBStepper simple={simple} ariaLabel={label}>
        {arr.map((s, i) => {
          const { disabled, skip, icon, ...props } = s
          const state = disabled ? 'disabled' : skip ? 'skip' : current > i + 1 ? 'complete' : current === i + 1 ? 'active' : null
          const iconName = icon || (state === 'completed' ? 'check-circle' : state === 'active' ? 'disc' : 'circle')
          const componentProps = { ...props, state, icon: iconName }
          const Component = simple ? SimpleStep : GlyphStep
          return <Component key={i} {...componentProps} />
        })}
      </CBStepper>
    </div>
  )
}

Stepper.Proptypes = {
  /** steps can either be an array of steps with custom props per step or a number that will be turned into an array of steps */
  steps: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  simple: PropTypes.bool,
  left: PropTypes.bool,
  current: PropTypes.number,
  label: PropTypes.string.isRequired,
}

export default Stepper
