import { PRIMARY_IND } from '../../../utils/constants'
import orderBy from 'lodash/orderBy'
import { formatYear } from '../../../utils/date'

export default {
  StudentDetails: {
    fields: {
      nonPrimaryApUids(value, { readField }) {
        return readField('apUids')?.filter(a => a.primaryInd !== PRIMARY_IND) || null
      },
      nonPrimaryCandidateIds(value, { readField }) {
        return readField('candidateIds')?.filter(c => c.primaryInd !== PRIMARY_IND) || null
      },
      nonPrimarySsdIds(value, { readField }) {
        return readField('ssdIds')?.filter(s => s.primaryInd !== PRIMARY_IND) || null
      },
      primaryApUid(value, { readField }) {
        return readField('apUids')?.find(a => a.primaryInd === PRIMARY_IND)?.siloId || null
      },
      primaryCandidateId(value, { readField }) {
        return readField('candidateIds')?.find(a => a.primaryInd === PRIMARY_IND)?.siloId || null
      },
      primarySsdId(value, { readField }) {
        return readField('ssdIds')?.find(a => a.primaryInd === PRIMARY_IND)?.siloId || null
      },
      gender(value, { readField }) {
        return readField('genderCd') === 'M' ? 'Male' : readField('genderCd') === 'A' ? 'Another' : 'Female'
      },
    },
  },
  History: {
    fields: {
      displayYear(value, { readField }) {
        return formatYear(readField('adminYear'))
      },
      examsFormatted(value, { readField }) {
        return orderBy(readField('examsTaken'))?.map(e => `AP ${e}`)
      },
    },
  },
}
