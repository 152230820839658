import { Link, Redirect } from 'react-router-dom'
import { AccordionItem, AccordionList, Glyph } from '@cb/apricot-react'
import { FORM_LINK, LEFT_COLUMN_CLASS } from '../../../../utils/constants'
import Layout from '../../../_common/layout/Layout'
import { getQueryParams } from '../../../../utils/app'
import { APP_LINKS } from '../../../../utils/navigation'

import './faqs.scss'
import useCMS from '../../../../services/rest/helpcontent'

const FAQs = () => {
  const { data, loading, error, loaded } = useCMS()
  const { category } = getQueryParams()
  const selected = data?.byCategory.find(d => d.shortTitle === category)

  if (!selected && data?.byCategory) return <Redirect to={APP_LINKS.help} />

  return (
    <Layout
      loaded={Boolean(loaded && selected)}
      loading={loading}
      error={error ? 'An error occurred retrieving FAQs. Please try again later.' : null}
      subtitle="Frequently asked questions"
    >
      <div className={`${LEFT_COLUMN_CLASS} apscores-faqs apscores-accordion-list cb-margin-bottom-24`}>
        <div className="cb-margin-bottom-24 cb-font-size-small">
          <Link to={APP_LINKS.help}>
            <Glyph name="left" size="12" decorative /> Back to Help
          </Link>
        </div>
        <AccordionList title={selected?.topic} controls>
          {selected?.faqs.map((f, i) => (
            <AccordionItem title={f.question} key={i}>
              <div className="apscores-faqs-content" dangerouslySetInnerHTML={{ __html: f.answer }} />
            </AccordionItem>
          ))}
        </AccordionList>
        <p className="cb-font-size-small cb-margin-top-24">
          Still have a question?{' '}
          <a target="_blank" href={FORM_LINK} title="Contact Us Form (Opens in a new window)" className="cb-blue5-color cb-hover">
            Send us an email.
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default FAQs
