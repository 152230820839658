import Band from '../../../_common/band/Band'
import ButtonLink from '../../../_common/links/ButtonLink'
import ActionBar from '../../../_common/actionbar/ActionBar'
import { APP_LINKS } from '../../../../utils/navigation'
import CancelOrder from '../_common/CancelOrder/CancelOrder'
import ReviewStudentInfo from './reviewstudentinfo/ReviewStudentInfo'
import ReviewScoresOrder from './reviewscoresorder/ReviewScoresOrder'
import { CONTAINER_THIN_CLASS } from '../../../../utils/constants'


const ReviewOrder = ({ stepProps, scoresAndAwardsByYear, selected }) => {
  return (
    <>
      <Band title="Review Order Details" subtitle="Send AP Scores" containerClass={CONTAINER_THIN_CLASS} stepProps={stepProps} />
      <div className="container">
        <div className="row">
          <div className={CONTAINER_THIN_CLASS}>
            <ReviewScoresOrder scoresAndAwardsByYear={scoresAndAwardsByYear} selected={selected} />
            <ReviewStudentInfo />
            <CancelOrder />
          </div>
        </div>
      </div>
      <ActionBar
        containerClass={CONTAINER_THIN_CLASS}
        buttons={[
          () => <ButtonLink path={APP_LINKS.sendscores} title="Previous" />,
          () => <ButtonLink primary path={APP_LINKS.deliverymethod} title="Next" />,
        ]}
      />
    </>
  )
}

ReviewOrder.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object),
  scoresAndAwardsByYear: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepProps: PropTypes.object,
}

export default ReviewOrder
