import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { requiresPartitionKey } from '../../utils/auth'
import { transformApplicationProperties } from '../../utils/properties'
import { processScoresAndAwards } from '../../utils/scores'
import { QUERY_PROPERTIES, QUERY_SCORES_AWARDS, QUERY_SELECTED_STUDENT } from './queries/queries'

export const formatAppsyncErrors = error => {
  const { message } = error || {}
  const formattedMsg = message ? message.replace('GraphQL error:', '').trim() : null
  return formattedMsg || 'Please try again.'
}

export const getQueryDataWithoutKey = ({ data }) => data && Object.values(data)[0]

export const isPartitionKeyRequired = query =>
  query?.definitions[0]?.variableDefinitions[0]?.variable?.name?.value === 'partitionKey'

export const getUpdatedVariables = (query, variables = {}, data) => {
  const partitionKey =
    requiresPartitionKey() && isPartitionKeyRequired(query) ? { partitionKey: data.getSelectedStudent?.catapultId } : {}
  return { ...variables, ...partitionKey }
}

export const useCustomQuery = requests => {
  const { loading, data } = useQuery(QUERY_SELECTED_STUDENT)
  const queries = requests.map(r => {
    const { query, variables, ...options } = r
    return useLazyQuery(query, { variables: getUpdatedVariables(query, variables, data), ...options })
  })
  const response = queries.map(q => getQueryDataWithoutKey(q[1]))

  useEffect(() => {
    // execute all queries once selected student query returns data
    queries.forEach(q => q[0]())
  }, [data])

  return {
    loading: loading || Boolean(queries.find(q => q[1].loading)),
    error: queries.find(q => q[1].error),
    data: response.length === 1 ? response[0] : response,
  }
}

export const useCustomLazyQuery = (query, variables) => {
  const { loading, data } = useQuery(QUERY_SELECTED_STUDENT)
  const [action, response] = useLazyQuery(query, { variables: getUpdatedVariables(query, variables, data) })
  return [action, { error: response.error, loading: loading || response.loading, data: getQueryDataWithoutKey(response) }]
}

export const useCustomMutation = (mutation, options) => {
  const { loading, data } = useQuery(QUERY_SELECTED_STUDENT)
  const [action, response] = useMutation(mutation, options)
  const execute = vars => action({ variables: getUpdatedVariables(mutation, vars || options.variables, data) })
  return [
    execute,
    {
      error: response.error?.graphQLErrors.map(g => g.message).join('. '),
      loading: loading || response.loading,
      data: getQueryDataWithoutKey(response),
    },
  ]
}

export const useScoreAndAwardQuery = (additionalRequests = []) => {
  const { data, ...props } = useCustomQuery([{ query: QUERY_SCORES_AWARDS }, ...additionalRequests])
  let processed = {}

  if (additionalRequests.length) {
    const [scoresandawards, ...otherData] = data
    processed = [processScoresAndAwards(scoresandawards), ...otherData]
  } else processed = processScoresAndAwards(data)

  return {
    ...props,
    data: processed,
  }
}

export const usePropertiesQuery = (isEdit = false) => {
  const request = useQuery(QUERY_PROPERTIES)
  return {
    ...request,
    data: transformApplicationProperties(getQueryDataWithoutKey(request), isEdit),
  }
}
