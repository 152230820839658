import { Link as NavLink } from 'react-router-dom'
import { ROUTES } from '../../../utils/navigation'

const Link = ({
  title,
  path,
  button = false,
  naked = false,
  primary = false,
  black = false,
  disabled = false,
  light = false,
  className = '',
  ...props
}) => {
  const isNavLink = ROUTES.find(r => r.path === path) && !disabled
  const classes = `${className} cb-btn ${primary ? 'cb-btn-primary' : ''} ${light ? 'cb-btn-light' : ''} ${
    black ? 'cb-btn-black' : ''
  } ${disabled ? 'cb-disabled' : ''} ${naked ? 'cb-btn-naked' : ''}`
  const common = { className: classes, children: title, tabIndex: disabled ? '-1' : '', 'aria-disabled': disabled, ...props }

  return isNavLink ? <NavLink to={path} {...common} /> : <a href={disabled ? '#' : path} {...common} />
}

Link.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  button: PropTypes.bool,
  primary: PropTypes.bool,
  black: PropTypes.bool,
  disabled: PropTypes.bool,
  light: PropTypes.bool,
  className: PropTypes.string,
}

export default Link
