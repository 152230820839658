import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY_SEARCH_CRITERIA, QUERY_SEARCH_STUDENTS } from '../../../services/graphql/queries/queries'
import { getQueryDataWithoutKey } from '../../../services/graphql/utils'
import SearchResults from './results/SearchResults'
import SearchForm from './form/SearchForm'
import useStudentSearch from '../../../services/local/studentsearch'

const StudentSearch = () => {
  const { prepareSearchCriteria } = useStudentSearch()
  const savedCriteria = getQueryDataWithoutKey(useQuery(QUERY_SEARCH_CRITERIA))
  const [fetch, { data, loading, error }] = useLazyQuery(QUERY_SEARCH_STUDENTS, { fetchPolicy: 'network-only' })

  const getSearchResults = criteria => fetch({ variables: prepareSearchCriteria(criteria) })

  if (savedCriteria)
    return (
      <div aria-live="polite">
        <div className="cb-gray5-bg cb-padding-top-48 cb-padding-bottom-24 cb-border-bottom cb-margin-bottom-32">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-lg-8">
                <h1 className="cb-h3 cb-margin-bottom-32">Student Search</h1>
                <SearchForm savedCriteria={savedCriteria} fetch={getSearchResults} fetching={loading} />
              </div>
            </div>
          </div>
        </div>
        <SearchResults loading={loading} error={error} data={getQueryDataWithoutKey({ data })} />
      </div>
    )

  return null
}

export default StudentSearch
