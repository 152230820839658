import { useHistory } from 'react-router-dom'
import Band from '../../../_common/band/Band'
import ButtonLink from '../../../_common/links/ButtonLink'
import Button from '../../../_common/button/Button'
import ActionBar from '../../../_common/actionbar/ActionBar'
import CancelOrder from '../_common/CancelOrder/CancelOrder'
import DeliverySelection from './deliveryselection/DeliverySelection'
import OrderSummary from './ordersummary/OrderSummary'
import { APP_LINKS } from '../../../../utils/navigation'
import { getPriceProperties } from '../../../../utils/orders'
import { MUTATION_PENDING_ORDER } from '../../../../services/graphql/mutations/mutations'
import useOrderOperations from '../../../../services/local/order'
import DeliveryAlerts from './alerts/DeliveryAlerts'
import ErrorAlert from './alerts/ErrorAlert'
import Spinner from '../../../_common/spinner/Spinner'
import Dialog from '../../../_common/dialog/Dialog'
import { getQueryDataWithoutKey, useCustomMutation, usePropertiesQuery } from '../../../../services/graphql/utils'
import { CONTAINER_THIN_CLASS } from '../../../../utils/constants'
import { QUERY_GET_SERVER_TIME } from '../../../../services/graphql/queries/queries'
import { useQuery } from '@apollo/client'
import useMissingZip from '../missingzip/useMissingZip'

const DeliveryMethod = ({ selected = [], stepProps, skipPayment }) => {
  const history = useHistory()
  const serverTime = useQuery(QUERY_GET_SERVER_TIME, { fetchPolicy: 'network-only' })
  const finalTime = getQueryDataWithoutKey(serverTime)
  const { loading: propsLoading, error: propsError, data: props } = usePropertiesQuery()
  const priceProperties = getPriceProperties(props, skipPayment, finalTime)
  const { preparePendingOrderData, updateOrder } = useOrderOperations()
  const [createPendingOrder, { data: createData, error: createError }] = useCustomMutation(MUTATION_PENDING_ORDER)
  const [creating, setCreating] = useState(false)

  const { component: ZipCodeAlert, showMissingZipAlert, props: zipProps } = useMissingZip()
  const PrevButton = () => <ButtonLink path={APP_LINKS.revieworder} title="Previous" disabled={creating} />
  const NextButton = () => (
    <Button
      primary
      title="Next"
      onClick={() => {
        if (skipPayment) {
          history.push(APP_LINKS.completeorder)
        } else {
          createPendingOrder(preparePendingOrderData())
          setCreating(true)
        }
      }}
      loading={creating || serverTime.loading}
      disabled={showMissingZipAlert}
    />
  )

  useEffect(() => {
    if (createData) {
      const { eOrderId, paymentUrl } = createData
      updateOrder({ eOrderId })
      window.location = paymentUrl
    }
  }, [createData])

  useEffect(() => {
    if (createError) {
      setCreating(false)
      window.scrollTo(0, 0) // move to top of screen to error is visible
    }
  }, [createError])

  return (
    <>
      <Band
        title="Review Order Summary"
        subtitle="Send AP Scores"
        containerClass={CONTAINER_THIN_CLASS}
        stepProps={stepProps}
      />
      <div className="container">
        <div className="row">
          <div className={CONTAINER_THIN_CLASS}>
            {propsLoading && <Spinner center />}
            {propsError && (
              <Dialog error title="Error retrieving data">
                An error occurred retrieving your data. Please refresh and try again.
              </Dialog>
            )}
            {Object.keys(props).length && (
              <>
                {showMissingZipAlert && <ZipCodeAlert {...zipProps} />}
                <ErrorAlert submissionError={Boolean(createError)} />
                <DeliveryAlerts {...props} {...priceProperties} />
                <DeliverySelection skipPayment={skipPayment} />
                <OrderSummary selected={selected} {...priceProperties} />
              </>
            )}
            <CancelOrder showDisclaimer={true} />
          </div>
        </div>
      </div>
      <ActionBar containerClass={CONTAINER_THIN_CLASS} buttons={[PrevButton, NextButton]} />
    </>
  )
}

DeliveryMethod.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object),
  stepProps: PropTypes.object,
}

export default DeliveryMethod
