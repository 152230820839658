import { Table, Column } from '@cb/apricot-react'
import { formatYear } from '../../../../utils/date'

const APRegistrations = ({ tableData }) => {
  return (
    <>
      <h2 className="cb-h4 cb-margin-bottom-16">AP Registrations</h2>
      {tableData?.length ? (
        <Table data={tableData} striped>
          <Column title="AP Registration Number/UID" field="apRegNumber" rowHeader />
          <Column title="AP Test Year" field="adminYear" component={({ value }) => formatYear(value)} />
          <Column
            title="Primary Attending AI"
            field="aiCode"
            component={({ value, row }) => {
              const { aiName } = row
              return `${value} (${aiName})`
            }}
          />
        </Table>
      ) : (
        <p>No Registrations for Past Exam Administrations</p>
      )}
    </>
  )
}

export default APRegistrations
