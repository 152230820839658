import '@cb/apricot/CBSpinner'
import CBUtils from '@cb/apricot/CBUtils'

const Spinner = ({ label = 'Loading...', inverse, center, className = '' }) => {
  const id = CBUtils.uniqueID(5, 'apscores_spinner')

  return (
    <div role="status" aria-labelledby={id}>
      <div
        className={`cb-spinner ${inverse ? 'cb-spinner-light' : ''} ${className}`}
        id={id}
        role="img"
        aria-label={label}
        style={center ? { marginLeft: 'auto', marginRight: 'auto' } : {}}
      >
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

Spinner.propTypes = {
  label: PropTypes.string,
  inverse: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
}

export default Spinner
