import { usePropertiesQuery } from '../../../../services/graphql/utils'
import ContentModule from '../../../_common/contentmodule/ContentModule'
import { isDateWithinRange, formatDate, FORMATS } from '../../../../utils/date'

import './awardsreleasedate.scss'

const AwardsReleaseDate = () => {
  const { data = {} } = usePropertiesQuery()
  const { awardReleaseDate, awardComingSoonDate } = data
  const showModule = isDateWithinRange(awardComingSoonDate, awardReleaseDate)

  return showModule ? (
    <ContentModule
      title="AP Awards"
      link={{
        path: 'https://apstudents.collegeboard.org/awards-recognitions',
        title: 'Learn about AP Awards',
        light: true,
      }}
      className="cb-white-color apscores-award-release cb-margin-bottom-24"
    >
      <p>
        AP Awards will be released <strong>{formatDate(awardReleaseDate, FORMATS.mediumdate)}</strong>. Check back to see if
        you earned an award!
      </p>
    </ContentModule>
  ) : null
}

export default AwardsReleaseDate
