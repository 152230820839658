import { Input } from '@cb/apricot-react'
import { VALID_STATE_ERR, VALID_STATE_HELP, VALID_STATE_SUCCESS } from '../../../../utils/validations'

const ValidationInput = ({ validate, errorMsg, helperMsg = '', successMsg = '', onChange, onClear, required, ...props }) => {
  const [validState, setValidState] = useState(VALID_STATE_HELP)
  const getMsg = state => {
    switch (state) {
      case VALID_STATE_ERR:
        return errorMsg
      case VALID_STATE_SUCCESS:
        return successMsg || helperMsg
      default:
        return helperMsg
    }
  }

  return (
    <Input
      {...props}
      required={required}
      validation={validState}
      validationMsg={getMsg(validState)}
      onClear={e => {
        const state = required ? VALID_STATE_ERR : VALID_STATE_HELP
        setValidState(state)
        onClear && onClear(e)
        onChange('', state)
      }}
      onChange={e => {
        if (validate) {
          const hasValue = Boolean(e.target.value)
          const isValid = (!required || hasValue) && validate(e.target.value)
          const state = isValid ? (hasValue ? VALID_STATE_SUCCESS : VALID_STATE_HELP) : VALID_STATE_ERR
          setValidState(state)
          onChange(e.target.value, state)
        } else onChange(e.target.value)
      }}
    />
  )
}

ValidationInput.propTypes = {
  validate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  errorMsg: PropTypes.string,
  helperMsg: PropTypes.string,
  successMsg: PropTypes.string,
  required: PropTypes.bool,
}

export default ValidationInput
