import orderBy from 'lodash/orderBy'
import { formatYear } from '../../../utils/date'
import { formatSubscore } from '../../../utils/scores'
import { HOLD_EVENT_CODE } from '../../../utils/constants'

const SUFFIX_AWARD_CODES = ['01', '02', '03', '04', '05', '06', '08', '09', '12']
const CANCEL_PRECEDENCE = [92, 19, 4, 87, 17, 94, 95]
const HOLD_PRECEDENCE = [HOLD_EVENT_CODE, 5, 18, 89, 93, 11, 97, 86, 88, 90, 1, 6, 91, 16, 85, 2, 3, 8, 9, 10, 12, 13, 15, 80, 81, 82, 14, 83, 84, 96]
const getEvents = (events, code) => (events ? events.filter(e => e.categoryCode === code) : [])

export default {
  Award: {
    fields: {
      awNameFormatted(value, { readField }) {
        const showSuffix = SUFFIX_AWARD_CODES.includes(readField('awCode'))
        return `${readField('awName')}${showSuffix ? ' Award' : ''}`
      },
      isArchived(value, { readField }) {
        return readField('rowStateInd') === 'A'
      },
    },
  },
  Score: {
    fields: {
      isArchived(value, { readField }) {
        return readField('rowStateInd') === 'A'
      },
      exNameFormatted(value, { readField }) {
        return `AP ${readField('exName')}`
      },
      eventCancel(value, { readField }) {
        const events = readField('exEvents') || []
        const cancelEvents = orderBy(getEvents(events, 'C'), event => {
          const index = CANCEL_PRECEDENCE.findIndex(p => p === event.eventCode)
          return index === -1 ? CANCEL_PRECEDENCE.length : index
        })
        return cancelEvents.length ? cancelEvents[0] : null
      },
      eventHold(value, { readField }) {
        const events = readField('exEvents') || []
        const holdEvents = orderBy(getEvents(events, 'H'), event => {
          const { eventCode } = event
          const index = HOLD_PRECEDENCE.findIndex(h => h === eventCode)
          return index === -1 ? HOLD_PRECEDENCE.length : index
        })
        return holdEvents.length ? holdEvents[0] : null
      },
      eventsInfo(value, { readField }) {
        const events = readField('exEvents') || []
        const infoEvents = orderBy(getEvents(events, 'I'), 'eventCode', 'asc')
        return infoEvents.length ? infoEvents : []
      },
      eventsProjected(value, { readField }) {
        const events = readField('exEvents') || []
        const projectedEvents = orderBy(getEvents(events, 'P'), 'eventCode', 'asc')
        return projectedEvents.length ? projectedEvents : []
      },
      cancelEventsByPrecedence(value, { readField }) {
        const exEvents = readField('exEvents')
        let newEvents
        if (exEvents) {
          newEvents = getEvents(exEvents, 'C').reduce((acc, e, index) => {
            acc.push({ eventCode: e.eventCode, index })
            return acc
          }, [])
        }
        return (
          orderBy(newEvents, event => {
            const newIndex = CANCEL_PRECEDENCE?.findIndex(c => c === event.eventCode)
            if (newIndex === -1) return null
            else return newIndex
          })?.map(e => e.eventCode) || []
        )
      },
      holdEventsByPrecedence(value, { readField }) {
        const holdEvents = getEvents(readField('exEvents'), 'H') || []
        const sevenHold = holdEvents?.find(h => h.eventCode === HOLD_EVENT_CODE)
        const unlockedHoldEvents = orderBy(holdEvents?.filter(h => !h.actionCode && h.eventCode !== HOLD_EVENT_CODE),
          event => {
            const newIndex = HOLD_PRECEDENCE.findIndex(ind => ind === event.eventCode)
            if (newIndex === -1) return null
            else return newIndex
          },
          'asc'
        )
        const lockedHoldEvents = orderBy(
          holdEvents?.filter(h => h.actionCode),
          'eventCode',
          'asc'
        )
        const finalHoldEvents = []
        if (sevenHold) finalHoldEvents.push(sevenHold)
        unlockedHoldEvents?.forEach(event => finalHoldEvents.push(event))
        lockedHoldEvents?.forEach(event => finalHoldEvents.push(event))
        return finalHoldEvents?.map(e => e.eventCode)
      },
    },
  },
  SubScore: {
    fields: {
      exNameFormatted(value, { readField }) {
        return formatSubscore(readField('exName'))
      },
    },
  },
  Withhold: {
    fields: {
      exNameFormatted(value, { readField }) {
        return `AP ${readField('exName')}`
      },
      displayYear(value, { readField }) {
        return formatYear(readField('adminYear'))
      },
    },
  },
  ViewScore: {
    fields: {
      scores(value) {
        return orderBy(value, 'exName', 'asc')
      },
      awards(value) {
        return orderBy(value, 'sortOrder', 'asc')
      },
    },
  },
}
