import partition from 'lodash/partition'
import mem from 'mem'
import { canCreateOrders } from './auth'

export const processScoresAndAwards = mem(data => {
  const DEFAULT = { archivedYears: [], activeScoresAndAwards: [], allScoresAndAwards: [] }
  let processedData = DEFAULT

  if (data)
    processedData = data.reduce((acc, year) => {
      const { scores, awards, displayYear } = year
      const [archiveScores, activeScores] = partition(scores, s => s.isArchived)
      const activeAwards = awards ? awards.filter(a => !a.isArchived) : []
      const hasArchiveScores = archiveScores.length
      const hasActiveData = activeScores.length || activeAwards.length

      return {
        archivedYears: hasArchiveScores ? [...acc.archivedYears, displayYear] : acc.archivedYears,
        activeScoresAndAwards: hasActiveData
          ? [...acc.activeScoresAndAwards, { ...year, scores: activeScores, awards: activeAwards }]
          : acc.activeScoresAndAwards,
        allScoresAndAwards: [...acc.allScoresAndAwards, year],
      }
    }, DEFAULT)

  return {
    ...processedData,
    hasDataLoaded: Boolean(data),
    hasNoScores: data?.length === 0,
    canSendScores: Boolean(data?.find(c => c.hasAtLeastOneReportableScore) && canCreateOrders()),
    hasActiveData: processedData.activeScoresAndAwards.length !== 0,
    hasArchiveData: processedData.archivedYears.length !== 0,
    hasActiveScore: Boolean(processedData.activeScoresAndAwards.find(a => a.scores?.length)),
    hasActiveAward: Boolean(processedData.activeScoresAndAwards.find(a => a.awards?.length)),
  }
})

export const formatSubscore = exName => exName.replace(/music |bc:/i, '')
