import { MulticolorContentGlyph } from '@cb/apricot-react'
import { usePropertiesQuery, useScoreAndAwardQuery } from '../../../../services/graphql/utils'
import { getCBEnv } from '../../../../utils/auth'
import { isDateWithinRange, FORMATS, formatDate } from '../../../../utils/date'
import ContentModule from '../../../_common/contentmodule/ContentModule'

import ButtonLink from '../../../_common/links/ButtonLink'
import '../content.scss'

const TITLE = 'Send Your Scores For Free'
const URL = 'https://myap.collegeboard.org/profile'
const BUTTON = 'Designate Free Score Send'

const FreeSendScores = ({ callout }) => {
  const { data: scores = {} } = useScoreAndAwardQuery()
  const { data: props = {} } = usePropertiesQuery()
  const showModule =
    isDateWithinRange(props.freeSendScoresStartDate, props.freeSendScoresEndDate) &&
    (scores.hasActiveData || !scores.hasArchiveData)
  const deadline = formatDate(props.freeSendScoresEndDate, FORMATS.monthdayattime)
  const sentDate = formatDate(props.diScoreReleaseDate, FORMATS.month)
  let env = '', cbEnv = getCBEnv()

  if (cbEnv === 'palm' || cbEnv === 'oak') env = '-dev'
  if (cbEnv === 'apple' || cbEnv === 'maple' || cbEnv === 'spruce') env = '-stg'
  const apStudentUrl = `https://apstudents${env}.collegeboard.org/sending-scores/free-score-send`

  if (!showModule) return null

  if (callout)
    return (
      <div className="apscores-content-callout cb-card cb-margin-bottom-48 cb-margin-xs-bottom-24">
        <div className="cb-align-center">
          <MulticolorContentGlyph name="megaphone" />
          <h4>Taking an exam?</h4>
          <p className="cb-margin-bottom-24">Each year, you can send one free score report to an institution of your choice.</p>
          <ButtonLink black title={BUTTON} path={apStudentUrl} />
          <p className="cb-margin-top-24 cb-roboto-bold cb-font-size-small">
            Deadline: <span className="cb-no-wrap">{deadline}</span>
          </p>
        </div>
      </div>
    )

  return (
    <ContentModule
      title={TITLE}
      link={{ path: apStudentUrl, title: BUTTON }}
      linkClassName="cb-black1-color"
      className="cb-black1-color cb-margin-bottom-24 cb-white-bg"
    >
      <p>
        If you are registered to take AP Exams in the upcoming administration you can send one free score report by
        designating a college, university, or scholarship program
        by <strong>{deadline}</strong>. Scores will be sent automatically when released in {sentDate}.
      </p>
    </ContentModule>
  )
}

FreeSendScores.propTypes = {
  callout: PropTypes.bool,
}

export default FreeSendScores
