import { Glyph } from '@cb/apricot-react-glyph'
import '@cb/apricot/CBMenuList'
import CBUtils from '@cb/apricot/CBUtils'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../../utils/navigation'

const MenuList = ({ title, links, showGlyph, className = '' }) => {
  const menuId = CBUtils.uniqueID(3, 'menulist-')

  return (
    <>
      <h4 className={`cb-padding-bottom-8 ${className}`} id={menuId}>
        {title}
      </h4>
      <nav className="cb-menu-list cb-menu-list-vertical cb-menu-list-glyph" aria-labelledby={menuId}>
        <ul>
          {links.map(({ link, title, subtitle, ...props }, i) => {
            const isNavLink = ROUTES.find(r => r.path === link?.split('?')[0])
            const common = { ...props, className: 'cb-menu-link' }

            const child = (
              <>
                <span>
                  <span className={subtitle ? 'cb-font-size-regular cb-roboto-medium cb-black1-color' : null}>{title}</span>
                    {subtitle ? <div className="cb-gray1-color" dangerouslySetInnerHTML={{ __html: subtitle }} /> : null}
                </span>
                {showGlyph ? <Glyph name="right" decorative style={{ marginLeft: 'auto' }}/> : null}
              </>
            )

            return (
              <li key={i}>
                {isNavLink ? (
                  <NavLink to={link} {...common}>
                    {child}
                  </NavLink>
                ) : (
                  <a href={link} {...common}>
                    {child}
                  </a>
                )}
              </li>
            )
          })}
        </ul>
      </nav>
    </>
  )
}

MenuList.propTypes = {
    title: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    showGlyph: PropTypes.bool,
    className: PropTypes.string,
}

export default MenuList
