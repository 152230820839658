import orderBy from 'lodash/orderBy'
import { orderVar } from '../../local/order'
import { searchCriteriaVar, selectedStudentVar } from '../../local/studentsearch'
import { formatYear } from '../../../utils/date'

export default {
  Query: {
    fields: {
      getViewScoresAndAwards: {
        merge(existing, incoming) {
          return incoming
            ? orderBy(
                incoming.map(i => ({
                  ...i,
                  displayYear: formatYear(i.adminYear),
                  hasAtLeastOneReportableScore: Boolean(i.scores?.find(r => r.score && r.rowStateInd === 'Y')),
                })),
                'adminYear',
                'desc'
              )
            : []
        },
      },
      getPendingOrder: {
        read() {
          return orderVar()
        },
      },
      getSelectedStudent: {
        read() {
          return selectedStudentVar()
        },
      },
      getSearchCriteria: {
        read() {
          return searchCriteriaVar() || {}
        },
      },
      getAppInfo: {
        merge(existing, incoming) {
          return typeof incoming === 'string' ? JSON.parse(incoming) : incoming
        },
      },
      getRegistrations: {
        merge(existing, incoming) {
          return orderBy(
            incoming?.map(i => ({
              ...i,
              apRegNumber: i?.spKey || i?.apUid,
            })),
            'adminYear',
            'desc'
          )
        },
      },
      getExamHistory: {
        merge(existing, incoming) {
          return incoming?.map(i => ({
            ...i,
            history: orderBy(i.history, 'adminYear', 'desc'),
          }))
        },
      },
      getLookups: {
        merge(existing, incoming) {
          // filter out unuseful properties from lookups
          return JSON.parse(incoming)?.map(lookup => {
            const { src, orderDate, updateDt, ...remaining } = lookup
            return remaining
          })
        },
      },
      getQuicksightReportUrl: {
        merge(existing, incoming) {
          return JSON.parse(incoming)
        },
      },
      getZipCodeRequiredCountries: {
        merge(existing, incoming) {
          return typeof incoming === 'string' ? JSON.parse(incoming) : incoming
        },
      },
    },
  },
}
