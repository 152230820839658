import { JAVASCRIPT_ERROR, trackError } from '../../../utils/analytics'

class ErrorWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: props.error || false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.hasError) {
      trackError(JAVASCRIPT_ERROR, 400)
    }
  }

  render() {
    if (this.state.hasError)
      return (
        <div className="container cb-global-footer-reset cb-padding-top-48 cb-padding-bottom-48">
          <h2 className="cb-h3">There was an issue loading this page</h2>
          <p>Please refresh your browser.</p>
        </div>
      )

    return this.props.children
  }
}

export default ErrorWrapper
