import ScoresOrder from '../../_common/ScoresOrder/ScoresOrder'

const CompleteScoresOrder = ({ scoresAndAwardsByYear, selected }) => (
  <div id="apscores-complete-scores">
    <h3 className="cb-roboto cb-font-weight-xs-medium cb-margin-top-72 cb-margin-xs-top-48 cb-margin-bottom-16">
      AP Scores Order
    </h3>
    <ScoresOrder scoresAndAwardsByYear={scoresAndAwardsByYear} selected={selected} />
  </div>
)

CompleteScoresOrder.propTypes = {
  scoresAndAwardsByYear: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default CompleteScoresOrder
