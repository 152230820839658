import { canViewEventCodes } from './auth'

/**
 * This function appends event codes to strings that it is given. The main scenarios for it's use in this app
 * @param content
 * @param codes
 * @returns {JSX.Element|string|*}
 */
export const appendEventCodes = (content, codes, infoEventCodes) => {
  if (canViewEventCodes() && codes && (content === '' || content)) {
    return `${content}
      <p className="cb-font-size-regular">
        <strong>
          (Code${codes.length > 1 ? 's' : ''} ${codes.join(', ')}) ${infoEventCodes?.length ? '+ ' + infoEventCodes.join(', ') + ' Option Letter' : ''}
        </strong>
      </p>`
  } else return content
}
