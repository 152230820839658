import { Glyph } from '@cb/apricot-react'
import { useScoreAndAwardQuery } from '../../../../services/graphql/utils'
import { PANEL_GRADING } from '../../../../utils/analytics'
import Panel from '../../../_common/panel/Panel'
import ContentLookup from '../../../_common/request/ContentLookup'

const ScoreGradingScale = () => {
  const { data = {} } = useScoreAndAwardQuery()
  const [clicked, setClicked] = useState(false)
  const triggerId = 'scoreGradingScale'

  return data.hasActiveData ? (
    <div className="cb-white-bg cb-padding-8 cb-margin-bottom-48 cb-margin-xs-bottom-24 cb-box-shadow display-flex">
      <div className="cb-margin-left-8 cb-margin-right-16 align-self-center">
        <Glyph circular name="lightbulb" color="blue3" decorative />
      </div>
      <p className="cb-gray1-color cb-font-size-small cb-margin-top-4 cb-margin-bottom-4 align-self-center">
        AP Exams are scored 1 to 5.{' '}
        <a
          href="#"
          id={triggerId}
          className="cb-roboto-medium cb-black1-color display-xs-block display-sm-inline display-md-block"
          onClick={() => setClicked(true)}
        >
          See what each score means
        </a>
      </p>
      <Panel triggerId={triggerId} title="Score Grading Scale" tracking={PANEL_GRADING}>
        <div className="cb-padding-24">
          <ContentLookup file="gradingscale" type={['intro', 'table']} trigger={clicked} />
        </div>
      </Panel>
    </div>
  ) : null
}

export default ScoreGradingScale
