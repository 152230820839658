import orderBy from 'lodash/orderBy'
import { convertArrayToPrettyString } from '../../../../../../utils/app'
import Alert from '../../../../../_common/alert/Alert'

const WithholdAlerts = ({ withholds = [] }) => {
  const processedWithholds = orderBy(
    withholds.reduce((acc, withhold) => {
      const existingIndex = acc.findIndex(a => a.code === withhold.diCode)
      const index = existingIndex !== -1 ? existingIndex : acc.length
      const { courses = [] } = acc[index] || {}
      acc[index] = {
        name: withhold.diName,
        code: withhold.diCode,
        courses: [...courses, `${withhold.exNameFormatted} (${withhold.displayYear})`],
      }
      return acc
    }, []),
    'name'
  )

  return processedWithholds.length ? (
    <Alert title="Withheld Scores">
      {processedWithholds.map(w => (
        <p key={w.code}>
          Your <strong className="cb-font-weight-medium">{convertArrayToPrettyString(w.courses)}</strong>{' '}
          {w.courses.length > 1 ? 'scores were' : 'score was'} withheld from{' '}
          <strong className="cb-font-weight-medium">{w.name}</strong> and will not be sent to that recipient.
        </p>
      ))}
    </Alert>
  ) : null
}

WithholdAlerts.propTypes = {
  withholds: PropTypes.arrayOf(PropTypes.object),
}

export default WithholdAlerts
