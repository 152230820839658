import { Select, Input } from '@cb/apricot-react'
import { VALID_STATE_ERR, VALID_STATE_HELP, VALID_STATE_SUCCESS } from '../../../../utils/validations'
import { MONTHS, DAYS } from '../../../../utils/date'

const getValidState = (value, error) => (error ? VALID_STATE_ERR : value ? VALID_STATE_SUCCESS : VALID_STATE_HELP)

const DateFieldsInput = ({ disabled, label, onChange, fieldName, defaultDay, defaultMonth, defaultYear, validate }) => {
  const [day, setDay] = useState(defaultDay)
  const [month, setMonth] = useState(defaultMonth)
  const [year, setYear] = useState(defaultYear)
  const { yearError, monthError, dayError } = validate ? validate(day, month, year) : {}

  useEffect(() => {
    onChange(day, month, year, getValidState(day || month || year, yearError || monthError || dayError))
  }, [day, month, year])

  return (
    <fieldset disabled={disabled}>
      <legend className="cb-label cb-margin-bottom-4">{label}</legend>
      <div className="row" aria-live="polite">
        <div className="col-xs-4">
          <Select
            ariaLabel="Month"
            values={MONTHS}
            floating
            floatingBg="light"
            name={`${fieldName}-month`}
            onChange={setMonth}
            validation={getValidState(month, monthError)}
            validationMsg={monthError}
            defaultValue={defaultMonth}
          />
        </div>
        <div className="col-xs-4">
          <Select
            ariaLabel="Day"
            values={DAYS}
            floating
            floatingBg="light"
            name={`${fieldName}-day`}
            onChange={setDay}
            validation={getValidState(day, dayError)}
            validationMsg={dayError}
            defaultValue={defaultDay}
          />
        </div>
        <div className="col-xs-4">
          <Input
            label="Year"
            floating
            floatingBg="light"
            name={`${fieldName}-year`}
            onChange={e => setYear(e.target.value)}
            onClear={e => setYear('')}
            maxLength={4}
            validation={getValidState(year, yearError)}
            validationMsg={yearError}
            defaultValue={defaultYear}
          />
        </div>
      </div>
    </fieldset>
  )
}

DateFieldsInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  defaultDay: PropTypes.string,
  defaultMonth: PropTypes.string,
  defaultYear: PropTypes.string,
  validate: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
}

export default DateFieldsInput
