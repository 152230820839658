import { Modal } from '@cb/apricot-react'
import Alert from '../../../../../_common/alert/Alert'
import { convertArrayToPrettyString } from '../../../../../../utils/app'
import ContentLookup from '../../../../../_common/request/ContentLookup'

const ScoreHoldAlerts = ({ holds = [] }) => {
    const [sevenHoldCourses, oneHoldCourses, fiveHoldCourses, sixHoldCourses, eighteenHoldCourses, eightyTwoHoldCourses, ninetySixHoldCourses, elevenHoldCourses, otherHoldCourses] = holds.reduce(
        (acc, hold) => {
            const handledEventCodes = [7, 1, 5, 6, 18, 82, 96, 11];
            const eventCode = hold.eventHold.eventCode
            const index = handledEventCodes.indexOf(eventCode) >= 0 ? handledEventCodes.indexOf(eventCode) : handledEventCodes.length;
            const { courses = [], hasUnlockedEvent } = acc[index] || {}
            acc[index] = {
                courses: [...courses, `${hold.exNameFormatted} (${hold.displayYear})`],
                hasUnlockedEvent: hasUnlockedEvent || hold.eventHold.actionCode !== 'L',
            }
            return acc
        },
        [{ courses: [] }, { courses: [] }]
    )
    const sevenHoldsLength = sevenHoldCourses?.courses.length
    const oneHoldsLength = oneHoldCourses?.courses.length
    const fiveHoldsLength = fiveHoldCourses?.courses.length
    const sixHoldsLength = sixHoldCourses?.courses.length
    const eighteenHoldsLength = eighteenHoldCourses?.courses.length
    const eightyTwoHoldsLength = eightyTwoHoldCourses?.courses.length
    const ninetysixHoldsLength = ninetySixHoldCourses?.courses.length
    const elevenHoldsLength = elevenHoldCourses?.courses.length
    const otherHoldsLength = otherHoldCourses?.courses.length

    const LearnMore = ({ title, trigger, content }) => (
        <>
            <a href="#" id={trigger} className="cb-black1-color">
                Learn More
            </a>
            <Modal title={title} trigger={trigger} cancelButtonText="Close">
                <div dangerouslySetInnerHTML={{ __html: content.more }} />
            </Modal>
        </>
    )

    const HoldAlert =
        ({ eventCode, triggerName, courses = [], title = `Score${courses?.length > 1 ? 's' : ''} Not Available`,
            message = <>A score is not available to be sent for <BoldMessage string={convertArrayToPrettyString(courses)} />.</> }) => (
            <Alert title={title}>
                {message}{' '}
                <ContentLookup
                    file="eventsHold"
                    type={eventCode}
                    component={({ content = {} }) => <LearnMore content={content} trigger={triggerName} title={title} />}
                />
            </Alert>
        )

    const BoldMessage = ({ string }) => (
        <strong className="cb-font-weight-medium">{string}</strong>
    )

    const holdTitle = `In Progress Score${otherHoldsLength > 1 ? 's' : ''}`
    const otherHoldsMessage = <>When your <BoldMessage string={convertArrayToPrettyString(otherHoldCourses?.courses)} />{' '}
        {otherHoldsLength > 1 ? 'scores come in, they ' : 'score comes in, it'} will be sent automatically with no additional cost.</>
    const ninetySixHoldMessage = <>When your <BoldMessage string={convertArrayToPrettyString(ninetySixHoldCourses?.courses)} />{' '}
        {ninetysixHoldsLength > 1 ? 'scores come in, they ' : 'score comes in, it'} will be sent automatically with no additional cost.</>

    return (
        <>
            {sevenHoldsLength ? (
                <HoldAlert
                    eventCode="7"
                    courses={sevenHoldCourses.courses}
                    triggerName="sevenHoldLearnMore"
                />
            ) : null}
            {fiveHoldsLength ? (
                <HoldAlert
                    eventCode="5"
                    courses={fiveHoldCourses.courses}
                    triggerName="fiveHoldLearnMore"
                />
            ) : null}
            {eighteenHoldsLength? (
                <HoldAlert
                    eventCode="18"
                    courses={eighteenHoldCourses.courses}
                    triggerName="eighteenHoldLearnMore"
                />
            ) : null}
            {eightyTwoHoldsLength ? (
                <HoldAlert
                    eventCode="82"
                    title={`Incomplete Cancellation or Withhold Request${eightyTwoHoldsLength > 1 ? 's' : ''}`}
                    courses={eightyTwoHoldCourses.courses}
                    triggerName="eightyTwoHoldLearnMore"
                />
            ) : null}
            {oneHoldsLength ? (
                <HoldAlert
                    eventCode="1"
                    courses={oneHoldCourses.courses}
                    triggerName="oneHoldLearnMore"
                />
            ) : null}
            {sixHoldsLength ? (
                <HoldAlert
                    eventCode="6"
                    courses={sixHoldCourses.courses}
                    triggerName="sixHoldLearnMore"
                />
            ) : null}
            {ninetysixHoldsLength ? (
                <HoldAlert
                    eventCode="96"
                    title={`In Progress Score${ninetysixHoldsLength > 1 ? 's' : ''}`}
                    courses={ninetySixHoldCourses.courses}
                    triggerName="ninetySixHoldLearnMore"
                    message={ninetySixHoldMessage}
                />
            ) : null}
            {elevenHoldsLength ? (
                <HoldAlert
                    eventCode="11"
                    courses={elevenHoldCourses.courses}
                    triggerName="elevenHoldLearnMore"
                />
            ) : null}
            {otherHoldsLength ? (
                <HoldAlert
                    eventCode="default"
                    title={holdTitle}
                    courses={otherHoldCourses.courses}
                    message={otherHoldsMessage}
                    triggerName="otherHoldsLearnMore"
                />
            ) : null}
        </>
    )
}

ScoreHoldAlerts.propTypes = {
    holds: PropTypes.arrayOf(PropTypes.object),
}

export default ScoreHoldAlerts
