import { useEffect } from 'react'
import CBForm from '@cb/apricot/CBForm'
import CBUtils from '@cb/apricot/CBUtils'
import { Glyph } from '@cb/apricot-react'

import './autosuggest.scss'

const BODY_CLASS = 'apscores-autosuggest-open'

const Autosuggest = ({ label, placeholder, id, fetch, select, disabled, glyph }) => {
  const inputId = `autosuggest_input_${id}`
  const listId = `autosuggest_list_${id}`
  const eventId = `autosuggest_event_${id}`
  const inputRef = useRef(null)
  const containerRef = useRef(null)
  const [data, setData] = useState([])
  const [focusedOnDropdown, setFocusOnDropdown] = useState(false)
  const [focusedOnInput, setFocusOnInput] = useState(false)
  const [focusedOnClose, setFocusOnClose] = useState(false)
  const focusedOnAutosuggest = focusedOnDropdown || focusedOnInput

  const updateDropdownItems = async e => {
    const { term, data } = await fetch(e.target.value)
    setFocusOnInput(true)
    setFocusOnClose(false)
    if (term === inputRef.current.value) setData(data)
  }

  useEffect(() => {
    if(focusedOnInput) {
      setTimeout(() => window.scrollTo(0,0), 500);
    }
  }, [focusedOnInput])

  const dropdownListener = e => {
    const { type } = e.data
    if (!inputRef.current) return
    if (type === 'open') setFocusOnDropdown(true)
    else if (type === 'close') {
      setFocusOnDropdown(false)
      setData([])
    } else if (type === 'change') {
      CBUtils.addClass(containerRef.current, 'cb-focus-elem-ci')
      CBUtils.addClass(CBUtils.getPreviousSibling(inputRef.current), 'cb-focus-fl')
    }
  }

  const selectionCallback = (e, label) => {
    select && select(label, inputRef.current.value)
    if (inputRef.current) CBUtils.getNextSibling(inputRef.current, 'button').click()
  }

  useEffect(() => {
    const customEvent = new CustomEvent(eventId)
    customEvent.data = data
    containerRef.current.dispatchEvent(customEvent)
  }, [data])

  useEffect(() => {
    if (focusedOnAutosuggest && !focusedOnClose) CBUtils.addClass(document.body, BODY_CLASS)
    else CBUtils.removeClass(document.body, BODY_CLASS)
  }, [focusedOnAutosuggest, focusedOnClose])

  useEffect(() => {
    CBForm.inputDropdown({
      elem: containerRef.current,
      event: eventId,
      callBack: selectionCallback,
    })
    label && CBForm.floatingLabel(inputRef.current)
    containerRef.current.addEventListener('apricot_inputDropdown', dropdownListener)
    return () => {
      containerRef.current?.removeEventListener('apricot_inputDropdown', dropdownListener)
    }
  }, [])

  return (
    <div
      className={`apscores-autosuggest cb-input-dropdown-overflow ${focusedOnAutosuggest ? 'apscores-autosuggest-open' : ''} ${
        focusedOnClose ? 'apscores-autosuggest-active-close' : ''
      }`}
    >
      <div
        ref={containerRef}
        className={`cb-input cb-floating-label cb-input-dropdown cb-btn-input cb-clear-input ${disabled ? 'cb-disabled' : ''}`}
      >
        <div>
          {label ? <label htmlFor={inputId}>{label}</label> : null}
          <div className="cb-input-icon-right">
            <input
              type="text"
              ref={inputRef}
              id={inputId}
              data-testid='test-autosuggest'
              placeholder={placeholder}
              aria-label={label || placeholder}
              aria-autocomplete="list"
              autoComplete="off"
              role="combobox"
              aria-expanded={false}
              aria-haspopup="listbox"
              aria-controls={listId}
              disabled={disabled}
              onChange={updateDropdownItems}
              onFocus={e => {
                e.target.select()
                setFocusOnInput(true)
                setFocusOnClose(false)
              }}
              onBlur={() => setFocusOnInput(false)}
            />
            {glyph ? <i className={`cb-glyph cb-${glyph}`} aria-hidden={true} /> : null}
            <button
              type="button"
              aria-controls={inputId}
              className="cb-btn cb-btn-square cb-btn-greyscale"
              tabIndex="-1"
              aria-hidden={true}
              onClick={async e => {
                setFocusOnClose(true)
              }}
            >
              <Glyph name="x-mark" decorative />
            </button>
          </div>
        </div>
        <div className="cb-dropdown-input" data-cb-input="dropdown-input">
          <div className="cb-dropdown-menu">
            <ul id={listId} role="listbox"></ul>
          </div>
        </div>
      </div>
    </div>
  )
}

Autosuggest.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
  select: PropTypes.func,
  disabled: PropTypes.bool,
  glyph: PropTypes.string,
}

export default Autosuggest
