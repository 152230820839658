import { useHistory } from 'react-router-dom'
import { Modal } from '@cb/apricot-react'
import { APP_LINKS } from '../../../../../utils/navigation'
import Button from '../../../../_common/button/Button'
import useOrderOperations from '../../../../../services/local/order'

const CancelOrder = ({ showDisclaimer = false }) => {
  const { clearDiSelections } = useOrderOperations()
  const history = useHistory()
  const modalTriggerId = 'cancel-order'

  const cancel = () => {
    clearDiSelections()
    history.push(APP_LINKS.viewscores)
  }

  const Footer = () => (
    <div className="cb-xs-align-center">
      <Button naked closeModal>
        No, Don't Cancel
      </Button>
      <Button primary closeModal onClick={cancel}>
        Yes, Cancel
      </Button>
    </div>
  )

  return (
    <div className="cb-margin-top-72 cb-padding-sm-up-top-48 cb-margin-xs-top-48">
      <p>
        <a href="#" id={modalTriggerId} className="cb-link-black">
          Cancel Order
        </a>
      </p>
      {showDisclaimer && (
        <p className="cb-roboto-italic">
          By using this service and providing your credit card you acknowledge and agree that your credit card will be charged.
          The College Board will not use this card for any other purpose and will not store the credit card number in our system.
        </p>
      )}
      <Modal title="Cancel Order" trigger={modalTriggerId} footer={<Footer />}>
        Are you sure you want to cancel your request to send scores? Any selections you have made will not be saved.
      </Modal>
    </div>
  )
}

CancelOrder.propTypes = {
  showDisclaimer: PropTypes.bool,
}

export default CancelOrder
