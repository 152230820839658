import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { isStudent, requiresTAndCs } from '../../utils/auth'
import { APP_LINKS, ROUTES } from '../../utils/navigation'
import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY_GET_TERMS, QUERY_SELECTED_STUDENT } from '../../services/graphql/queries/queries'
import { getQueryDataWithoutKey, usePropertiesQuery } from '../../services/graphql/utils'
import AdminNavigation from '../admin/navigation/Navigation'
import useStudentSearch from '../../services/local/studentsearch'
import Layout from '../_common/layout/Layout'
import useActivity, { TRACK_STUDENT_LOGIN } from '../../services/local/tracking'
import { trackPage } from '../../utils/analytics'
import Spinner from '../_common/spinner/Spinner'
import React from 'react'
import { CONTAINER_WIDE_CLASS, LEFT_COLUMN_CLASS } from '../../utils/constants'

const Routes = () => {
  const appRef = useRef(undefined)
  const student = getQueryDataWithoutKey(useQuery(QUERY_SELECTED_STUDENT))
  const location = useLocation()
  const isStudentRole = isStudent()
  const requiresTerms = requiresTAndCs()
  const { setStudentSearch } = useStudentSearch()
  const { admin } = ROUTES.find(r => r.path === location.pathname) || {}
  const isAdminViewingAsStudent = !admin && !isStudentRole
  const RedirectComponent = () => (
    <Redirect to={isStudentRole || isAdminViewingAsStudent ? APP_LINKS.viewscores : APP_LINKS.studentsearch} />
  )
  const { data: props, error } = usePropertiesQuery()
  const [getTerms, terms] = useLazyQuery(QUERY_GET_TERMS)
  const acceptedTerms = getQueryDataWithoutKey(terms)
  const { track } = useActivity(TRACK_STUDENT_LOGIN)
  // student will at least be an empty object when loaded
  const isLoading = !props || (requiresTerms && !terms.data && !terms.error) || terms.loading || !student
  const isError = error || terms.error

  useEffect(() => {
    const currentComponent = ROUTES.find(r => r.path === location.pathname)
    window.scrollTo(0, 0)
    // this is to deal with an issue with apricot,
    // where the modal's close function is never fired when the parent component is unmounted
    // the only operation we need to ensure is called is remove the body class
    // this class prevents scrolling, so if it is not removed, users can no longer scroll to see all the content
    document.body.classList.remove('cb-modal-open')
    // Change title of browser tab when component changes
    document.title = `${currentComponent?.pagetitle} | AP Scores | AP Students`
    trackPage(currentComponent)
    appRef?.current?.focus()
  }, [location])

  useEffect(() => {
    setStudentSearch() // needed for CSRs
    if (isStudentRole) track()
  }, [])

  useEffect(() => {
    if (requiresTerms && props?.termsAndConditionVersion) getTerms({ variables: { version: props.termsAndConditionVersion } })
  }, [props])

  if (isError)
    return <Layout error="An error occurred retrieving your scores and awards. Please try again later." useNav={false} />

  if (isLoading) return <Spinner center className="cb-margin-top-72" />

  // Force redirect whenever student has not signed Terms And Conditions
  if (location.pathname !== APP_LINKS.terms && isStudentRole && acceptedTerms === false) return <Redirect to={APP_LINKS.terms} />

  // Redirect back to main flow if on terms page and student has signed Terms and Conditions
  if (acceptedTerms && location.pathname === APP_LINKS.terms) return <RedirectComponent />

  if (isAdminViewingAsStudent && !student.catapultId) return <Redirect to={APP_LINKS.studentsearch} />

  const { href } = window.location
  if (isStudentRole && href.includes(props?.apScoreAdminUrl))
    return (
      <Layout useNav={false} loaded={true}>
        <p className={CONTAINER_WIDE_CLASS}>
          It looks like you might be in the wrong place. To view and send AP Scores Online, please visit {' '}
          <a href={props.apStudentsUrl}>{props.apStudentsUrl}</a>
        </p>
      </Layout>
    )
  if (!isStudentRole && href.includes(props?.apStudentsUrl))
    return (
      <Layout useNav={false} loaded={true}>
        <p className={CONTAINER_WIDE_CLASS}>
          It looks like you might be in the wrong place. To access the AP Scores CSR Student Admin Panel, please visit {' '}
          <a href={props.apScoreAdminUrl}>{props.apScoreAdminUrl}</a>
        </p>
      </Layout>
    )

  return (
    <div tabIndex="0" ref={appRef} className="cb-no-focus">
      {!isStudentRole ? <AdminNavigation selectedStudent={student || {}} /> : null}
      <Switch>
        {ROUTES.map((r, i) =>
          !r.authCheck || r.authCheck() ? <Route key={i} exact path={r.path} component={r.component} /> : null
        )}
        <Route render={RedirectComponent} />
      </Switch>
    </div>
  )
}

export default Routes
