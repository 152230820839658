import { CircularButton, Modal } from '@cb/apricot-react'

const IdsComponent = ({ primaryId, idsList, activeLabel, inactiveLabel }) =>
  (
    <>
      <div>
        {primaryId ? `${primaryId} (${activeLabel})` : null}
      </div>
      {idsList?.map((n, ind) => (
        <div key={ind}>
          {n.siloId} ({inactiveLabel})
        </div>
      ))}
    </>
  )

const trigger = 'studentEmailModal'

const AccountDetails = ({
  loginId,
  personStatus,
  email,
  personId,
  nonPrimarySsdIds = [],
  nonPrimaryApUids = [],
  nonPrimaryCandidateIds = [],
  primaryCandidateId,
  primarySsdId,
  primaryApUid,
  catapultId,
  oktaId,
  accountStatus,
  preferredFirstName
}) => {
  return (
    <>
      <h2 className="cb-h4">Account Details</h2>
      <dl className="cb-dl">
        <dt>Login ID</dt>
        <dd>{loginId}</dd>
        <dt>Account Status</dt>
        <dd>{accountStatus}</dd>
        <dt>Person Status</dt>
        <dd>{personStatus ? 'Active' : 'Inactive'}</dd>
        <dt>Email Address
          <CircularButton
            id={trigger}
            className={`cb-btn-black cb-btn-xs cb-margin-top-16 cb-margin-left-8 display-inline-block`}
            icon="question"
            aria-haspopup="dialog"
            label={`Open Student Email Information modal`}
          />
        </dt>
        <dd>{email}</dd>
        <dt>Person Id</dt>
        <dd>{personId}</dd>
        <dt>Approved SSD Id</dt>
        <dd>
          <IdsComponent primaryId={primarySsdId} idsList={nonPrimarySsdIds} activeLabel="Active" inactiveLabel="Inactive" />
        </dd>
        <dt>AP UID</dt>
        <dd>
          <IdsComponent primaryId={primaryApUid} idsList={nonPrimaryApUids} activeLabel="Active" inactiveLabel="Inactive" />
        </dd>
        <dt>Candidate ID</dt>
        <dd>
          <IdsComponent
            primaryId={primaryCandidateId}
            idsList={nonPrimaryCandidateIds}
            activeLabel="Primary"
            inactiveLabel="Not Primary"
          />
        </dd>
        <dt>Catapult ID</dt>
        <dd>{catapultId}</dd>
        <dt>Okta ID</dt>
        <dd>{oktaId}</dd>
        <dt>Preferred Name</dt>
        <dd>{preferredFirstName}</dd>
      </dl>
      <Modal
        title="Student Email Address Information"
        trigger={trigger}
        cancelButtonText="Close"
        analytics={false}
      >
        <p>This is the email address on the student's account.
          If the account has been migrated to OKTA, this field may not always match the verified email address being used as the OKTA Login ID.</p>
      </Modal>
    </>
  )
}

export default AccountDetails
