import { Glyph } from '@cb/apricot-react'
import { Link } from 'react-router-dom'
import { APP_LINKS } from '../../../../utils/navigation'

const FAQsOrders = () => (
  <div className="cb-white-bg cb-padding-8 cb-margin-bottom-48 cb-margin-xs-bottom-24 cb-box-shadow display-flex">
    <div className="cb-margin-left-8 cb-margin-right-16 align-self-center">
      <Glyph circular name="question" color="blue3" decorative />
    </div>
    <p className="cb-gray1-color cb-font-size-small cb-margin-top-4 cb-margin-bottom-4 align-self-center">
      Have a question about orders?{' '}
      <Link
        to={`${APP_LINKS.faqs}?category=sendingscores`}
        className="cb-roboto-medium cb-black1-color display-xs-block display-sm-inline display-md-block"
      >
        Use our FAQs.
      </Link>
    </p>
  </div>
)

export default FAQsOrders
