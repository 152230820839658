export const VALID_STATE_ERR = 'error'
export const VALID_STATE_HELP = 'general'
export const VALID_STATE_SUCCESS = 'success'

export const validateExactLength = (value, length) => value?.length === length

export const validateMinimumLength = (value, length) => value?.length >= length

export const validateNumber = value => /^\d+$/.test(value)

export const validateVersion = value => /^[v]\d+$/.test(value)

export const validateUrl = value => /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/.test(value)

export const validateAlphaNumeric = value => /^[a-z\d]+$/i.test(value)

export const validateFormattedString = value => /^[a-z\d${ }!.@#$%^&*()\\|/?"':;~`]+$/i.test(value)

export const validateAlphaNumberWithLimitedCharacters = value => /^[-.~, 'A-Za-z0-9]+$/.test(value) // lettes, numbers, space, hyphen, tilde, period, comma

export const validateLimitedCharacterStringWithWildCard = (value, minimumLength) => {
  const index = value.indexOf('*')
  if (index === -1) return validateAlphaNumberWithLimitedCharacters(value) && validateMinimumLength(value, minimumLength)
  else {
    const stringLastIndex = value?.length - 1
    const stringWithoutLastCharacter = value.substring(0, stringLastIndex)
    return (
      index === stringLastIndex &&
      validateAlphaNumberWithLimitedCharacters(stringWithoutLastCharacter) &&
      validateMinimumLength(stringWithoutLastCharacter, minimumLength)
    )
  }
}
