import { Glyph, NumericGlyph } from '@cb/apricot-react'
import Button from '../../../../_common/button/Button'
import useOrderOperations from '../../../../../services/local/order'

import './diselections.scss'
import CreditPolicyPanel from './creditpolicymodal/CreditPolicyPanel'

const DiSelections = ({ selected }) => {
  const { removeSelectedDi } = useOrderOperations()
  const numOfSelections = selected.length

  return (
    <div className="apscores-sendscores-diselections" aria-live="polite" aria-atomic>
      {numOfSelections ? (
        <>
          <h3 className="cb-font-size-regular cb-font-weight-medium">
            Your Selections{' '}
            <NumericGlyph inverted color="blue5">
              {numOfSelections}
            </NumericGlyph>
          </h3>
          {selected.map(s => {
            const creditPolicyId = `credit-policy-panel-${s.diCode}`

            return (
              <div
                key={s.diCode}
                className="apscores-sendscores-diselection cb-box-shadow cb-border-radius cb-margin-bottom-24 cb-margin-xs-bottom-16 display-flex justify-content-between"
              >
                <div>
                  <p className="cb-roboto-medium">
                    {s.diName} ({s.diCode})
                  </p>
                  <p className="cb-no-margin">
                    {s.diCity ? `${s.diCity}, ` : ''} {s.diState || s.diCountry}
                  </p>
                  {s.isCollege ? (
                    <>
                      <p className="cb-margin-top-8 cb-margin-xs-top-16">
                        <a id={creditPolicyId} href="#" className="cb-link-black">
                          See Credit Policy
                        </a>
                      </p>
                      <CreditPolicyPanel diInformation={s} title="Credit Policy" panelTriggerId={creditPolicyId} />
                    </>
                  ) : null}
                </div>
                <div className="align-self-center">
                  <Button naked className="cb-btn-black cb-no-padding" onClick={() => removeSelectedDi(s)}>
                    <Glyph name="x-mark" className="cb-no-margin" decorative />
                    <span className="sr-only">remove {s.diName}</span>
                  </Button>
                </div>
              </div>
            )
          })}
        </>
      ) : null}
    </div>
  )
}

DiSelections.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default DiSelections
