import Button from '../button/Button'
import { Glyph } from '@cb/apricot-react'
import { useCustomLazyQuery } from '../../../services/graphql/utils'
import { PDF_ERROR, useTrackingError } from '../../../utils/analytics'

const renderPDF = base64Pdf => {
  // To get around the size limitation of chrome convert to blob
  try {
    const byteCharacters = atob(base64Pdf)
    const byteNumbers = new Array(byteCharacters.length)
    const byteLength = byteNumbers.length
    for (let i = 0; i < byteLength; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    return { file: URL.createObjectURL(file) }
  } catch (err) {
    console.error('Error creating pdf object url')
    return { error: 'Pdf unavailable' }
  }
}

const PDFModule = ({ title, subtitle, query, variables = {}, tracking }) => {
  const downloadRef = useRef(null)
  const [fetchPDFData, { loading, data, error: fetchError }] = useCustomLazyQuery(query, variables)
  const [fileUrl, setFileUrl] = useState(null)
  const [downloadError, setDownloadError] = useState(null)
  const hasError = fetchError || downloadError

  useEffect(() => {
    if (data) {
      const { file, error } = renderPDF(data.base64Pdf || data)
      if (file) setFileUrl(file)
      if (error) setDownloadError(error)
    }
  }, [data])

  useEffect(() => {
    if (fileUrl) downloadRef.current.click()
  }, [fileUrl])

  useTrackingError(downloadError || fetchError, {  message: PDF_ERROR })

  return (
    <div className="cb-card cb-card-link cb-margin-bottom-48 cb-margin-xs-bottom-24 cb-padding-bottom-8 cb-padding-top-24 cb-padding-right-8 cb-padding-left-24">
      <div className="cb-card-header cb-no-margin">
        <div className="cb-text-icon-overline">
          <p>Document</p>
        </div>
      </div>
      <div className="cb-card-content">
        <h2 className="cb-card-title cb-no-margin">{title}</h2>
        <p className="cb-card-desc">{subtitle}</p>
        <div className="cb-card-action cb-no-margin align-items-center">
          <ul className="cb-meta-tags">
            <li>PDF</li>
          </ul>
          <Button
            tracking={tracking}
            naked
            className="cb-btn-black cb-btn-icon-loading"
            disabled={Boolean(hasError || loading)}
            loading={loading}
            onClick={() => {
              if (fileUrl) downloadRef.current.click()
              else fetchPDFData()
            }}
          >
            <Glyph name="download" decorative />
            <span className="sr-only">Download {title} PDF</span>
          </Button>
          <a className="display-none" href={fileUrl} download={`${title}.pdf`} ref={downloadRef} />
        </div>
        {hasError ? (
          <div className="cb-font-size-xsmall cb-red1-color">{title} PDF is unavailable. Please try again later.</div>
        ) : null}
      </div>
    </div>
  )
}

PDFModule.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
  tracking: PropTypes.string.isRequired,
}

export default PDFModule
