import { useHistory } from 'react-router-dom'
import { APP_LINKS } from '../../../../utils/navigation'
import Autosuggest from '../../../_common/forms/autosuggest/Autosuggest'

const SearchBar = ({ questions }) => {
  const history = useHistory()

  return (
    <Autosuggest
      glyph="search"
      label="Search"
      id="helpSearchBar"
      fetch={term => ({ term, data: questions.filter(q => q.label?.toLowerCase().includes(term.toLowerCase())) })}
      select={label => {
        const { category, index } = questions?.find(q => q.label === label) || {}
        if (category && index !== undefined) history.push(`${APP_LINKS.faqs}?category=${category}&faq=${index}`)
      }}
    />
  )
}

SearchBar.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SearchBar
