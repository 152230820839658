import { Glyph } from '@cb/apricot-react'
import orderBy from 'lodash/orderBy'
import { QUERY_WITHHOLDS } from '../../../../../../../services/graphql/queries/queries'
import { formatDate, FORMATS } from '../../../../../../../utils/date'
import { useCustomQuery } from '../../../../../../../services/graphql/utils'
import { canViewEventCodes } from '../../../../../../../utils/auth'
import { WITHHOLD_EVENT_CODE } from '../../../../../../../utils/constants'

const ScoreWithhold = ({ exCode, adminYear }) => {
  const { data = {} } = useCustomQuery([{ query: QUERY_WITHHOLDS }])
  const orderedWithholdsByCourse = orderBy(
    data.length && data?.filter(i => i.exCode === exCode && i.adminYear === adminYear),
    'diName',
    'asc'
  )

  return orderedWithholdsByCourse.length ? (
    <div className="cb-gray5-bg cb-padding-16 cb-margin-24 cb-font-size-small">
      <h5 className="cb-roboto-bold cb-margin-bottom-8">
        Withheld Score {canViewEventCodes() ? `(Code ${WITHHOLD_EVENT_CODE})` : ``}
      </h5>
      <p className="cb-gray1-color cb-margin-bottom-8">
        This score has been withheld from the following recipient(s) and will not be sent in future score sends to that
        recipient(s).
      </p>
      <ul className="cb-no-text-list cb-margin-bottom-8 cb-text-list-no-indent cb-gray1-color">
        {orderedWithholdsByCourse.map(d => (
          <li key={`${d.diCode}-${d.exCode}-${d.adminYear}`}>
            <strong>{d.diName}</strong> ({d.diCode}) {formatDate(d.reqDate, FORMATS.shortdate)}
          </li>
        ))}
      </ul>
      <div className="display-flex">
        <Glyph color="gray1" size="xs" name="lightbulb" className="cb-padding-right-8 cb-padding-top-8" decorative />
        <p className="cb-font-size-small cb-text-italic cb-gray1-color">
          Remember that this score will be sent to any other score recipients.
        </p>
      </div>
    </div>
  ) : null
}

ScoreWithhold.propTypes = {
  exCode: PropTypes.string.isRequired,
  adminYear: PropTypes.string.isRequired,
}

export default ScoreWithhold
