import PDFModule from '../../_common/pdfmodule/PDFModule'
import Layout from '../../_common/layout/Layout'
import ScoresAndAwards from './scoresandawards/ScoresAndAwards'
import SendScores from '../content/sendscores/SendScores'
import FreeSendScores from '../content/freesendscores/FreeSendScores'
import MissingScores from '../content/missingscores/MissingScores'
import RelatedLinks from '../content/relatedlinks/RelatedLinks'
import AwardsReleaseDate from '../content/awardsreleasedate/AwardsReleaseDate'
import ScoreGradingScale from '../content/scoregradingscale/ScoreGradingScale'
import ArchivedScores from '../content/archivedscores/ArchivedScores'
import ScoreReleaseDate from '../content/scorereleasedate/ScoreReleaseDate'
import RegisterForAP from '../content/registerforap/RegisterForAP'
import { usePropertiesQuery, useScoreAndAwardQuery } from '../../../services/graphql/utils'
import useActivity, { TRACK_STUDENT_SCORES } from '../../../services/local/tracking'
import { QUERY_AWARDS_PDF, QUERY_SCORE_REPORT_PDF } from '../../../services/graphql/queries/queries'
import { formatDate, FORMATS } from '../../../utils/date'
import { canViewArchives } from '../../../utils/auth'
import { LEFT_COLUMN_CLASS, RIGHT_COLUMN_CLASS } from '../../../utils/constants'
import { trackPageViewScores, trackViewScores, useTrackingError, VIEWSCORES_ERROR } from '../../../utils/analytics'
import useViewport from '../../../utils/viewport'

const ViewScores = () => {
  const { mobile, tablet } = useViewport()
  const { loading, error, data } = useScoreAndAwardQuery()
  const { activeScoresAndAwards, allScoresAndAwards, hasActiveAward, hasActiveScore, hasDataLoaded, hasNoScores } = data
  const { track } = useActivity(TRACK_STUDENT_SCORES)
  const isSmallFormat = mobile || tablet

  useEffect(() => {
    if (hasDataLoaded) {
      if (!hasNoScores) track()
      trackViewScores(allScoresAndAwards)
      trackPageViewScores(data)
    }
  }, [hasDataLoaded])

  useTrackingError(error, { message: VIEWSCORES_ERROR })

  const FloaterContent = () => (
    <>
      <RegisterForAP />
      <FreeSendScores />
      <MissingScores />
      <SendScores />
      <RelatedLinks />
    </>
  )

  return (
    <Layout
      loaded={hasDataLoaded}
      loading={loading}
      error={error ? 'An error occurred retrieving your scores and awards. Please try again later.' : null}
    >
      <div className={LEFT_COLUMN_CLASS}>
        <ScoresAndAwards data={canViewArchives() ? allScoresAndAwards : activeScoresAndAwards} />
        <ArchivedScores />
        <ScoreReleaseDate />
        <AwardsReleaseDate />
        {isSmallFormat ? null : <FloaterContent />}
      </div>
      <div className={RIGHT_COLUMN_CLASS}>
        <ScoreGradingScale />
        <SendScores callout />
        {hasActiveScore ? (
          <PDFModule
            tracking="ap-score-report.pdf"
            title="Score Report"
            subtitle="Download your score report"
            query={QUERY_SCORE_REPORT_PDF}
            variables={{ date: formatDate(new Date(), FORMATS.shortdate) }}
          />
        ) : null}
        {hasActiveAward ? (
          <PDFModule
            tracking="ap-award-certificate.pdf"
            title="Awards"
            subtitle="Download your awards certificate"
            query={QUERY_AWARDS_PDF}
          />
        ) : null}
      </div>
      {isSmallFormat ? (
        <div className="col-xs-12">
          <FloaterContent />
        </div>
      ) : null}
    </Layout>
  )
}

export default ViewScores
