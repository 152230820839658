import { Select, TagSet, SpacerV } from '@cb/apricot-react'
import { ORDER_OPTIONS } from '../../utils'
import Autosuggest from '../../../../_common/forms/autosuggest/Autosuggest'
import ToggleButtons from '../../../../_common/forms/togglebuttons/ToggleButtons'
import { SORT_DIRECTIONS, SORT_OPTIONS } from '../../utils'
import { useEffect } from 'react'

const Search = ({ onChange, data, filter, defaultView }) => {
  const [options, setOptions] = useState(data)
  const [selectedInstitutions, setSelectedInstitutions] = useState([])
  const [currentInstitution, setCurrentInstitution] = useState(null)
  const [view, setView] = useState(defaultView)
  const sortOptions = SORT_OPTIONS[view]
  const { value: defaultSort, direction: defaultDirection } = sortOptions.find(o => o.default) || {}
  const [sort, setSort] = useState(defaultSort)
  const [direction, setDirection] = useState(defaultDirection)

  useEffect(() => {
    onChange({ view, sort, direction })
  }, [view, sort, direction])

  // flip to default sort options when changing view
  useEffect(() => {
    setDirection(defaultDirection)
    setSort(defaultSort)
  }, [view])

  useEffect(() => {
    setDirection(sortOptions.find(o => o.value === sort).direction)
  }, [sort])

  useEffect(() => {
    filter(selectedInstitutions)
    setOptions(data.filter(o => !selectedInstitutions.find(s => s.label === o.label)))
  }, [selectedInstitutions])

  useEffect(() => {
    const institution = options.find(d => d.label === currentInstitution)
    if (institution) {
      setSelectedInstitutions([...selectedInstitutions, institution])
      setCurrentInstitution(null) // reset current selection
    }
  }, [currentInstitution])

  return (
    <>
      <div className="row">
        <div className="col-sm-4">
          <ToggleButtons name="orderView" onChange={setView} defaultValue={view} buttons={ORDER_OPTIONS} />
        </div>
        <div className="col-sm-8">
          <div className="display-flex">
            <Select values={sortOptions} value={sort} onChange={setSort} ariaLabel="Sort By" />
            <div className="cb-margin-left-4">
              <ToggleButtons name="sort" onChange={setDirection} defaultValue={direction} buttons={SORT_DIRECTIONS} />
            </div>
          </div>
        </div>
      </div>
      <Autosuggest
        glyph="filter"
        placeholder="Filter by Institution(s)"
        id="diSearchBar"
        disabled={!options.length}
        fetch={term => ({ term, data: options.filter(d => d.label.toLowerCase().includes(term.toLowerCase())) })}
        select={setCurrentInstitution}
        onB
      />
      <div role="region">
        <div aria-live="polite" aria-atomic>
          <span className="sr-only">Institution Added</span>
          {selectedInstitutions.length ? (
            <>
              <SpacerV size={16} />
              <TagSet
                label="Selected Institutions"
                offScreen="Remove"
                tags={selectedInstitutions.map(s => s.label)}
                onClick={(tag, e) => {
                  e.preventDefault()
                  setSelectedInstitutions(selectedInstitutions.filter(s => s.label !== tag))
                }}
              />
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  filter: PropTypes.func.isRequired,
  defaultView: PropTypes.string.isRequired,
}

export default Search
