import { Link, useLocation } from 'react-router-dom'
import { MulticolorContentGlyph } from '@cb/apricot-react'
import ContentModule from '../../../_common/contentmodule/ContentModule'
import ButtonLink from '../../../_common/links/ButtonLink'
import { APP_LINKS } from '../../../../utils/navigation'
import { useScoreAndAwardQuery } from '../../../../services/graphql/utils'

import './sendscores.scss'

const SendScores = ({ callout }) => {
  const { pathname } = useLocation()
  const { data = {} } = useScoreAndAwardQuery()
  const showPastScoresLink = pathname !== APP_LINKS.pastorders

  if (!data.canSendScores) return null

  if (callout)
    return (
      <div className="apscores-content-callout cb-blue2-bg cb-white-color cb-card cb-box-shadow cb-margin-bottom-48 cb-margin-xs-bottom-24">
        <div className="cb-align-center">
          <MulticolorContentGlyph name="send-scores" color="white" size="md" />
          <h4>Send Your Scores</h4>
          <p className="cb-font-size-xs-small cb-padding-bottom-24">Send scores to colleges and universities.</p>
          <ButtonLink path={APP_LINKS.sendscores} title="Send AP Scores" light className="cb-white-color" />
          {showPastScoresLink ? (
            <Link to={APP_LINKS.pastorders} className="display-block cb-font-size-small cb-white-color cb-padding-top-8">
              See past scores sends
            </Link>
          ) : null}
        </div>
      </div>
    )

  return (
    <ContentModule
      title="Sending AP Scores"
      className="cb-white-bg apscores-send-scores-module"
      link={{ path: APP_LINKS.sendscores, title: 'Send AP Scores', button: true }}
    >
      <p className="cb-margin-bottom-24">You can send scores anytime. Keep in mind the following:</p>
      <ul className="cb-text-list-no-indent">
        <li>
          <div className="cb-margin-bottom-4">Send Your Scores for College Credit</div>
          <div className="cb-gray1-color cb-margin-bottom-4">
            Sending your scores enables you to receive AP credit and/or placement, helping you register for the right courses and save time and money in college.
            Colleges, universities, and scholarship programs that you send your score to may also use your information and official score report to contact you with information about admissions, educational, financial aid, and scholarship opportunities.
          </div>
          <div className="cb-gray1-color">
            <a href="https://apstudents.collegeboard.org/getting-credit-placement/search-policies" className="cb-black1-color">
              Find Colleges Offering Credit and Placement
            </a>
          </div>
        </li>
        <li className="cb-margin-top-24">
          <div className="cb-margin-bottom-4">All Released Scores Are Sent</div>
          <div className="cb-gray1-color">
            All released scores for all years, except for those canceled, withheld, or archived are automatically sent
          </div>
        </li>
        <li className="cb-margin-top-24">
          <div className="cb-margin-bottom-4">Keep a Credit Card Handy</div>
          <div className="cb-gray1-color">The delivery fee is $15 per report.</div>
        </li>
      </ul>
    </ContentModule>
  )
}

SendScores.propTypes = {
  callout: PropTypes.bool,
}
export default SendScores
