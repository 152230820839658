import Score from './score/Score'
import Award from './award/Award'

import './scoresandawards.scss'

const ScoresAndAwards = ({ data }) => {
  const Heading = ({ year, title, archived }) => {
    return (
      <h3 className="apscores-heading-divider">
        <div className="display-flex align-items-center">
          {archived ? (
            <div className="cb-padding-right-8 archived-label cb-font-weight-black cb-border-radius-8 cb-orange1-bg cb-white-color cb-margin-right-8">
              Archived
            </div>
          ) : null}
          <div className="cb-margin-right-24 year-label">{year ? `${year} ${title}` : title}</div>
          <span className="display-inline-block cb-gray4-bg" />
        </div>
      </h3>
    )
  }
  const hasData = data?.length

  if (hasData)
    return data.map(d => {
      const { adminYear, displayYear, scores = [], awards = [] } = d
      const hasScores = scores?.length
      const hasAwards = awards?.length
      const archivedScores = scores?.some(a => a.isArchived) || false
      const archivedAwards = awards?.some(a => a.isArchived) || false

      return (
        <div key={d.adminYear}>
          {hasScores ? (
            <div className="cb-margin-bottom-72">
              <Heading year={displayYear} title="Scores" archived={archivedScores} />
              {scores?.map(s => (
                <Score key={`${adminYear}-${s.exCode}`} {...s} adminYear={adminYear} />
              ))}
            </div>
          ) : null}
          {hasAwards ? (
            <div className="cb-margin-bottom-72">
              <Heading year={displayYear} title="Awards" archived={archivedAwards} />
              {awards?.map(a => (
                <Award key={`${adminYear}-${a.awCode}`} {...a} adminYear={adminYear} />
              ))}
            </div>
          ) : null}
        </div>
      )
    })

  return null
}

ScoresAndAwards.propTypes = { data: PropTypes.array }

export default ScoresAndAwards
