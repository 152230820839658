import { formatDate, FORMATS } from '../../../../utils/date'

const StudentCompareTable = ({ history, studentDetails }) => {
  const Address = ({ address1, address2, address3, city, state, zip, province, countryName, intlPostalCode }) => (
    <td>
      <div>{address1}</div>
      <div>{address2 && address3 ? `${address2}, ${address3}` : address2 ? address2 : ''}</div>
      <div>
        {city && province ? `${city}, ${province}` : city ? `${city},` : ''} {state} {zip || intlPostalCode}
        <div>{countryName ? countryName : ''}</div>
      </div>
    </td>
  )

  const ExamHistory = ({ history }) => (
    <td>
      {history?.length ? (
        history.map(h => (
          <div className="cb-margin-bottom-24" key={`${h.adminYear}-${h.apId}`}>
            <div className="cb-h5 cb-roboto-bold">{h.displayYear}</div>
            <div className="cb-roboto-medium cb-font-size-small">AP Registration Number/UID: {h.apId}</div>
            <div className="cb-roboto-medium cb-font-size-small cb-margin-bottom-8">
              Primary Attending AI: {h.aiCode} ({h.aiName})
            </div>
            {h.examsFormatted.map(e => (
              <div key={e}>{e}</div>
            ))}
          </div>
        ))
      ) : (
        <p>No AP Exam History</p>
      )}
    </td>
  )

  return studentDetails ? (
    <table className="cb-table">
      <thead>
        <tr>
          <th scope="col" className="cb-table-callout"></th>
          <th scope="col" className="cb-align-center cb-h4">
            {studentDetails[0].firstName} {studentDetails[0].middleInitial ? `${studentDetails[0].middleInitial}.` : ''}{' '}
            {studentDetails[0].lastName}
          </th>
          <th scope="col" className="cb-align-center cb-h4">
            {studentDetails[1].firstName} {studentDetails[1].middleInitial ? `${studentDetails[1].middleInitial}.` : ''}{' '}
            {studentDetails[1].lastName}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" className="cb-table-callout">
            <strong className="cb-roboto-medium">Person Id</strong>
          </th>
          <td>{studentDetails[0].personId}</td>
          <td>{studentDetails[1].personId}</td>
        </tr>
        <tr>
          <th scope="row" className="cb-table-callout">
            <strong className="cb-roboto-medium">DOB</strong>
          </th>
          <td>{formatDate(studentDetails[0].birthDate, FORMATS.shortdate)}</td>
          <td>{formatDate(studentDetails[1].birthDate, FORMATS.shortdate)}</td>
        </tr>
        <tr>
          <th scope="row" className="cb-table-callout">
            <strong className="cb-roboto-medium">Gender</strong>
          </th>
          <td>{studentDetails[0].gender}</td>
          <td>{studentDetails[1].gender}</td>
        </tr>
        <tr>
          <th scope="row" className="cb-table-callout">
            <strong className="cb-roboto-medium">Address</strong>
          </th>
          <Address {...studentDetails[0]} />
          <Address {...studentDetails[1]} />
        </tr>
        <tr>
          <th scope="row" className="cb-table-callout">
            <strong className="cb-roboto-medium">Exam History</strong>
          </th>
          <ExamHistory {...history[0]} />
          <ExamHistory {...history[1]} />
        </tr>
      </tbody>
    </table>
  ) : (
    <p>One or both of the students being compared was not properly retrieved.</p>
  )
}

StudentCompareTable.propTypes = {
  history: PropTypes.array.isRequired,
  studentDetails: PropTypes.array.isRequired,
}

export default StudentCompareTable
