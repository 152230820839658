import { useQuery, useLazyQuery } from '@apollo/client'
import Spinner from '../../_common/spinner/Spinner'
import Dialog from '../../_common/dialog/Dialog'
import {
  QUERY_GET_REGISTRATIONS,
  QUERY_FULL_STUDENT_DETAILS,
  QUERY_SELECTED_STUDENT,
} from '../../../services/graphql/queries/queries'
import { useHistory } from 'react-router-dom'
import { APP_LINKS } from '../../../utils/navigation'
import { getQueryDataWithoutKey } from '../../../services/graphql/utils'
import AccountDetails from './sections/AccountDetails'
import StudentProfile from './sections/StudentProfile'
import APRegistrations from './sections/APRegistrations'

const StudentDetails = () => {
  const selectedStudent = getQueryDataWithoutKey(useQuery(QUERY_SELECTED_STUDENT))
  const [searchFetch, { data, loading, error }] = useLazyQuery(QUERY_FULL_STUDENT_DETAILS)
  const [registrationFetch, { data: regData }] = useLazyQuery(QUERY_GET_REGISTRATIONS)
  const details = getQueryDataWithoutKey({ data })
  const registrations = regData?.getRegistrations
  const history = useHistory()

  useEffect(() => {
    if (!selectedStudent?.catapultId && !selectedStudent?.personId) history.replace(APP_LINKS.studentsearch)
    else {
      searchFetch({ variables: { personId: [selectedStudent?.personId], allData: true } })
      registrationFetch({ variables: { partitionKey: selectedStudent?.catapultId } })
    }
  }, [selectedStudent])

  if (selectedStudent)
    return (
      <div className="container cb-padding-top-48">
        <h1 className="cb-h3 cb-margin-bottom-32">
          Student Details: {selectedStudent.firstName} {selectedStudent.lastName}
        </h1>
        {loading ? <Spinner center /> : null}
        {error ? (
          <Dialog error title="Error retrieving data">
            An error occurred retrieving student details. Please refresh and try again.
          </Dialog>
        ) : null}
        {details?.length ? (
          <>
            {' '}
            <AccountDetails {...details[0]} catapultId={selectedStudent.catapultId} />
            <hr className="cb-margin-top-48 cb-margin-bottom-48" />
            <StudentProfile {...details[0]} />
            <hr className="cb-margin-top-48 cb-margin-bottom-48" />
            <APRegistrations tableData={registrations} />
          </>
        ) : null}
      </div>
    )

  return null
}

export default StudentDetails
