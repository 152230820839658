import { useScoreAndAwardQuery } from '../../../../services/graphql/utils'
import { APP_LINKS } from '../../../../utils/navigation'
import MenuList from '../../../_common/menulist/MenuList'

const RelatedLinks = () => {
  const { data = {} } = useScoreAndAwardQuery()
  const isArchiveOnly = data.hasArchiveData && !data.hasActiveData
  const links = [
    {
      link: 'https://apstudents.collegeboard.org/getting-credit-placement/search-policies',
      title: 'Find Colleges Offering Credit',
    },
    { link: 'https://apstudents.collegeboard.org/about-ap-exams/past-exam-dates', title: 'Past Exam Dates' },
    { link: APP_LINKS.help, title: 'FAQs' },
  ]

  const nonArchivedLinks = [
    {
      link: 'https://apstudents.collegeboard.org/score-reporting-services/withhold-scores',
      title: 'Withhold Scores',
    },
    {
      link: 'https://apstudents.collegeboard.org/score-reporting-services/cancel-scores',
      title: 'Cancel Scores',
    },
  ]

  return <MenuList title="Related" links={[...links, ...(isArchiveOnly ? [] : nonArchivedLinks)]} />
}

export default RelatedLinks
