import { Glyph } from '@cb/apricot-react'
import Stepper from '../../../_common/stepper/Stepper'
import {
  getOrderStatus,
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_COMPLETE,
  ORDER_STATUS_ICONS,
  ORDER_STATUS_PLACED,
  ORDER_STATUS_PROCESSING,
} from '../utils'
import OrderDetails from './OrderDetails'
import DeliveryStatus from './DeliveryStatus'

const OrderStatus = ({ order }) => {
  const { orderId, dis, orderStatus, resend, orderDate, channel } = order
  const currentStep = orderStatus === ORDER_STATUS_COMPLETE ? 3 : orderStatus === ORDER_STATUS_PLACED ? 1 : 2

  const getSteps = () => {
    const hasCancel = orderStatus === ORDER_STATUS_CANCELED
    const steps = [
      {
        icon: ORDER_STATUS_ICONS[ORDER_STATUS_PLACED],
        state: 'active',
        label: getOrderStatus(ORDER_STATUS_PLACED, resend).status,
      },
      {
        icon: ORDER_STATUS_ICONS[ORDER_STATUS_PROCESSING],
        label: getOrderStatus(ORDER_STATUS_PROCESSING, resend).status,
        disabled: hasCancel,
      },
      {
        icon: ORDER_STATUS_ICONS[ORDER_STATUS_COMPLETE],
        label: getOrderStatus(ORDER_STATUS_COMPLETE, resend).status,
        disabled: hasCancel,
      },
    ]

    if (hasCancel)
      steps.splice(1, 2, {
        icon: ORDER_STATUS_ICONS[ORDER_STATUS_CANCELED],
        label: getOrderStatus(ORDER_STATUS_CANCELED, resend).status,
      })

    return steps
  }

  return (
    <div className="cb-margin-left-24 cb-margin-right-24 cb-margin-bottom-16">
      <Stepper steps={getSteps()} current={currentStep} />

      <h3 className="cb-h5 cb-font-weight-xs-medium cb-padding-bottom-8 cb-padding-top-8 cb-border-bottom">Institutions</h3>
      {dis.map(d => (
        <div key={d.diCode} className="cb-padding-bottom-16 cb-padding-top-16 cb-border-bottom">
          <div className="display-flex justify-content-between">
            <div>
              <Glyph name={ORDER_STATUS_ICONS[d.orderStatus]} color="black1" size="12" decorative />
            </div>
            <div className="flex-grow-1 cb-margin-left-8">
              <div className="display-flex justify-content-between">
                <div>
                  <h4 className="cb-font-size-regular cb-margin-right-8">
                    {d.diName} ({d.diCode})
                  </h4>
                  <div className="cb-font-size-small">
                    {d.diCity || d.diState || d.diCountry ? (
                      <div>
                        {d.diCity}
                        {d.diCity && (d.diState || d.diCountry) ? `, ${d.diState || d.diCountry}` : ''}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>{getOrderStatus(d.orderStatus, resend).status}</div>
              </div>
              <div className="cb-font-size-small display-inline-block vertical-align-middle">
                <DeliveryStatus {...d} orderDate={orderDate} channel={channel} orderId={orderId} showDisclaimer={true} />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="cb-margin-top-32 cb-align-center">
        <OrderDetails {...order} />
      </div>
    </div>
  )
}

OrderStatus.propTypes = {
  order: PropTypes.object.isRequired,
}

export default OrderStatus
