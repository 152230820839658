import { formatDate, FORMATS } from '../../../../utils/date'

const StudentProfile = ({
  birthDate,
  address1,
  address2,
  address3,
  aiCode,
  hsGradDate,
  city,
  state,
  zip,
  countryName,
  province,
  intlPostalCode,
  orgName,
}) => {
  return (
    <>
      <h2 className="cb-h4">Student Profile</h2>
      <dl className="cb-dl">
        <dt>Date of Birth</dt>
        <dd>{formatDate(birthDate, FORMATS.shortdate)}</dd>
        <dt>Address</dt>
        <dd>
          <div>{address1}</div>
          <div>{address2 && address3 ? `${address2}, ${address3}` : address2 ? address2 : ''}</div>
          <div>
            {city && province ? `${city}, ${province}` : city ? `${city},` : ''} {state} {zip || intlPostalCode}
            <div>{countryName ? countryName : ''}</div>
          </div>
        </dd>
        <dt>Expected HS Graduation Date</dt>
        <dd>{hsGradDate ? formatDate(hsGradDate, FORMATS.shortdate) : null}</dd>
        <dt>AI Code</dt>
        <dd>{aiCode}</dd>
        <dt>Organization Name</dt>
        <dd>{orgName}</dd>
      </dl>
    </>
  )
}

export default StudentProfile
