import { LEFT_COLUMN_CLASS } from '../../../../utils/constants'
import Layout from '../../../_common/layout/Layout'
import SearchBar from '../SearchBar/SearchBar'
import MenuList from '../../../_common/menulist/MenuList'
import { APP_LINKS } from '../../../../utils/navigation'
import useCMS from '../../../../services/rest/helpcontent'
import { CMS_ERROR, useTrackingError } from '../../../../utils/analytics'

const HelpCenter = () => {
  const { data = {}, loading, error, loaded } = useCMS()
  const { byQuestion = [], byCategory = [], title = '' } = data

  useTrackingError(error, { message: CMS_ERROR })

  return (
    <Layout
      loaded={loaded}
      loading={loading}
      error={error ? 'An error occurred retrieving FAQs. Please try again later.' : null}
      subtitle="Frequently asked questions"
    >
      <div className={LEFT_COLUMN_CLASS}>
        <h2 className="cb-h4 cb-margin-bottom-8">Looking for help?</h2>
        <p className="cb-margin-bottom-24 cb-roboto-light">
          Enter in keywords to search for FAQs related to your question or topic of interest.
        </p>
        <SearchBar questions={byQuestion} />
        <MenuList
          title={title}
          links={byCategory.map(d => ({
            link: `${APP_LINKS.faqs}?category=${d.shortTitle}`,
            title: d.topic,
            subtitle: d.overviewDescription,
          }))}
          showGlyph
          className="cb-margin-top-72"
        />
      </div>
    </Layout>
  )
}

export default HelpCenter
