import { useEffect, useState } from 'react'
import Spinner from '../../../../../_common/spinner/Spinner'
import Dialog from '../../../../../_common/dialog/Dialog'
import Panel from '../../../../../_common/panel/Panel'
import { CircularButton, Column, Table, Tooltip } from '@cb/apricot-react'
import { useQuery } from '@apollo/client'
import { QUERY_LOOKUPS } from '../../../../../../services/graphql/queries/queries'
import orderBy from 'lodash/orderBy'
import { CREDIT_POLICY_ERROR, PANEL_CREDITPOLICY, useTrackingError} from '../../../../../../utils/analytics'

const CreditPolicyPanel = ({ panelTriggerId, title, diInformation }) => {
  const { diCode, diName, diCity, diState, collegeAPUrl, creditAwarded, placementAwarded, policyDescription, creditPolicy } =
    diInformation
  const [policyData, setPolicyData] = useState(false)
  const apCreditAndPlacementData = [
    { creditForAP: 'Offers credit for qualifying AP Exam scores' },
    { placementForAP: 'Offers placement into advanced courses for qualifying AP Exam scores' },
  ]
  const { loading, data, error } = useQuery(QUERY_LOOKUPS, { variables: { lookupName: 'examCode' } })
  const tooltipTriggerId = `credit-tooltip-${diCode}`

  const getCourseName = examCode => {
    if (data) {
      const lookups = data.getLookups
      const { exName } = lookups.find(code => code.sortKey === examCode)
      return `AP ${exName}`
    }
    return null
  }

  useEffect(() => {
    if (data && creditPolicy?.length) {
      // retrieve exam names from lookup
      const fullPolicyData = orderBy(
        creditPolicy.map(c => {
          const courseName = getCourseName(c.examCode)
          const { awardedCredit, equivalentCourses, minScore } = c
          return { courseName, minScore, awardedCredit, equivalentCourses }
        }),
        'courseName',
        'asc'
      )
      setPolicyData(fullPolicyData)
    }
  }, [data])

  useTrackingError(error, { message: CREDIT_POLICY_ERROR} )

  return (
    <Panel title={title} triggerId={panelTriggerId} tracking={PANEL_CREDITPOLICY}>
      <div className="cb-padding-24">
        <h4 className="cb-roboto-medium">
          {diName} ({diCode})
        </h4>
        <div>
          <p>
            {diCity}, {diState}
          </p>
          <p>{policyDescription}</p>
          {collegeAPUrl ? (
            <p>
              For more information,{' '}
              <a target="_blank" href={collegeAPUrl} title={`${diName} Credit Policy (Opens in a new window)`}>
                view the credit policy on this college's website.
              </a>
            </p>
          ) : null}
          {policyData ? (
            <p className="cb-margin-bottom-24">
              Additional restrictions and requirements may apply to the policy listed below. For more detailed information, visit
              the college's AP policy on the Web or contact the college directly.
            </p>
          ) : (
            <p className="cb-margin-bottom-24">
              We do not have information on this college. Please visit the college's AP policy on the Web or contact the college
              directly.
            </p>
          )}
          <ul className="cb-text-list-feature cb-margin-bottom-24">
            {apCreditAndPlacementData.map((d, ind) => {
              const whichAwarded = d.creditForAP || d.placementForAP
              const isAwarded = d.creditForAP ? creditAwarded : placementAwarded
              return (
                <li key={`credit-policy-table-row-${ind}`}>
                  <div className="display-flex cb-font-size-small">
                    <span className="flex-grow-1">{whichAwarded}</span>
                    <span className={isAwarded ? 'cb-glyph cb-check cb-green1-color' : 'cb-glyph cb-error'}>
                      <span className="sr-only">{isAwarded ? 'Yes' : 'No'}</span>
                    </span>
                  </div>
                </li>
              )
            })}
          </ul>
          {loading ? (
            <div className="cb-margin-top-72">
              <Spinner center />
            </div>
          ) : null}
          {error ? (
            <Dialog error title="Error Loading Information">
              <p>An error occurred retrieving credit policy information. Please try again later.</p>
            </Dialog>
          ) : null}
          {policyData && data ? (
            <Table data={policyData} condensed responsive>
              <Column title="Exam Name" field="courseName" sortable groupValues />
              <Column
                title="Min Score Required"
                field="minScore"
                sortable
                headerComponent={() => (
                  <>
                    Min Score Required
                    <CircularButton
                      label="Credit Policy Tooltip"
                      id={tooltipTriggerId}
                      className="cb-btn-blue cb-btn-xs cb-margin-left-4"
                      icon="question"
                      light
                    />
                    <Tooltip trigger={tooltipTriggerId} light>
                      <span className="cb-font-size-xs-small">
                        This is the lowest qualifying score to earn credit and/or placement
                      </span>
                    </Tooltip>
                  </>
                )}
              />
              <Column title="# of Credits" field="awardedCredit" sortable />
              <Column title="Equivalent Courses" field="equivalentCourses" sortable />
            </Table>
          ) : null}
        </div>
      </div>
    </Panel>
  )
}

CreditPolicyPanel.propTypes = {
  panelTriggerId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  diInformation: PropTypes.object.isRequired,
}

export default CreditPolicyPanel
