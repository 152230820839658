import ButtonLink from '../links/ButtonLink'

import './contentmodule.scss'

const ContentModule = ({ link, children, title, className, linkClassName }) => (
  <div
    className={`apscores-content-module cb-padding-32 cb-padding-3xl-48 cb-padding-xl-48 cb-padding-xs-24 cb-margin-bottom-24 cb-border cb-gray3-border ${className}`}
  >
    {title ? <h4 className="cb-margin-bottom-16">{title}</h4> : null}
    {children}
    {link ? <ButtonLink {...link} black className={`cb-margin-top-24 ${linkClassName}`} /> : null}
  </div>
)

ContentModule.propTypes = {
  light: PropTypes.bool,
  link: PropTypes.object,
  linkClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
}

export default ContentModule
