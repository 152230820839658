import ContentLookup from '../../../../_common/request/ContentLookup'
import Alert from '../../../../_common/alert/Alert'
import Panel from '../../../../_common/panel/Panel'
import { getDateRange, formatDate, FORMATS } from '../../../../../utils/date'

const FILE = 'alerts'

const Alerts = ({ isBlackoutPeriod, blackoutStartDate, blackoutEndDate, isUpdatedDeliveryAlertPeriod }) => (
  <ContentLookup
    file={FILE}
    type={['blackout', 'deliverygeneral', 'upgradedDelivery']}
    component={({ content = {} }) => {
      const showBlackoutAlert = isBlackoutPeriod && content.blackout && content.blackout.title
      const showGeneralAlert = content.deliverygeneral && content.deliverygeneral.title

      const convertDates = (content = '', start, end, format = FORMATS.monthday) =>
        content
          .replace(/{start}/g, formatDate(start, format))
          .replace(/{end}/g, formatDate(end, format))
          .replace(/{year}/g, formatDate(start, FORMATS.year))
          .replace(/{range}/g, getDateRange(start, end))
      const LearnMore = ({ content, title, id }) =>
        content ? (
          <>
            <a href="#" id={id} className="cb-black1-color">
              Learn More
            </a>
            <Panel triggerId={id} title={title}>
              <div className="cb-padding-24" dangerouslySetInnerHTML={{ __html: content }} />
            </Panel>
          </>
        ) : null

      return showBlackoutAlert || showGeneralAlert || isUpdatedDeliveryAlertPeriod ? (
        <div className="cb-margin-bottom-16 cb-margin-xs-bottom-32">
          {showBlackoutAlert && (
            <Alert title={content.blackout.title}>
              {convertDates(content.blackout.body, blackoutStartDate, blackoutEndDate)}{' '}
              <LearnMore
                content={convertDates(content.blackout.more, blackoutStartDate, blackoutEndDate, FORMATS.monthdaytime)}
                title={content.blackout.title}
                id="blackoutAlertPanel"
              />
            </Alert>
          )}
          {isUpdatedDeliveryAlertPeriod && (
              <Alert content={content}  title={content.upgradedDelivery?.title || ''} id="upgradedDeliveryAlert">
                {content.upgradedDelivery?.body}
              </Alert>
          )}
          {showGeneralAlert && (
            <Alert title={content.deliverygeneral.title}>
              {content.deliverygeneral.body}{' '}
              <LearnMore
                content={content.deliverygeneral.more}
                title={content.deliverygeneral.title}
                id="deliveryGeneralAlertPanel"
              />
            </Alert>
          )}
        </div>
      ) : null
    }}
  />
)

Alerts.propTypes = {
  isBlackoutPeriod: PropTypes.bool,
  blackoutStartDate: PropTypes.string.isRequired,
  blackoutEndDate: PropTypes.string.isRequired,
}

export default Alerts
