import { Expandable } from '@cb/apricot-react'
import ContentLookup from '../../../../../../_common/request/ContentLookup'

const ScoreDescription = ({ score, subScores }) => {
  return (
    <ContentLookup
      file="scoredescriptions"
      type={score}
      component={({ content = {} }) => (
        <div className="cb-padding-24">
          <div className="row cb-margin-bottom-16">
            <div className="col-xs-3 align-self-center cb-align-center">
              <div className="cb-roboto-black apscores-score-num">
                <span className="sr-only">Score: </span> {score}
              </div>
              <div className="display-inline-block cb-border-solid cb-gray4-border cb-border-top-1">1 to 5</div>
            </div>
            <div
              className="col-xs-9 cb-gray1-color cb-gray4-border cb-border-solid cb-border-left-1"
              dangerouslySetInnerHTML={{ __html: content.recommendation }}
            />
          </div>
          <div
            className="cb-font-size-small cb-gray1-color cb-margin-bottom-16"
            dangerouslySetInnerHTML={{ __html: content.equivalency }}
          />
          <ContentLookup
            file="gradingscale"
            type={['intro', 'table']}
            component={({ content }) =>
              content ? (
                <Expandable openLabel="Hide what each score means" closedLabel="See what each score means">
                  <div dangerouslySetInnerHTML={{ __html: Object.values(content).join('') }} />
                </Expandable>
              ) : null
            }
          />
          {subScores ? (
            <ContentLookup
              file="subscores"
              type={subScores.length > 1 ? 'music' : 'calculus'}
              component={({ content = {} }) => (
                <div className="cb-border-top-1 cb-gray4-border cb-border-solid cb-margin-top-24 cb-padding-top-24 cb-font-size-small">
                  <h5>Subscores</h5>
                  <div className="cb-margin-bottom-16 cb-margin-top-16" dangerouslySetInnerHTML={{ __html: content.panel }} />
                  {subScores.map(({ score, exNameFormatted }, i) => (
                    <div key={i}>
                      {exNameFormatted}: <strong>{score}</strong>
                    </div>
                  ))}
                  <div className="cb-margin-top-16">
                    <Expandable openLabel="Read less" closedLabel="Read more">
                      <div dangerouslySetInnerHTML={{ __html: content.accordion }} />
                    </Expandable>
                  </div>
                </div>
              )}
            />
          ) : null}
        </div>
      )}
    />
  )
}

ScoreDescription.propTypes = {
  score: PropTypes.number.isRequired,
  subScores: PropTypes.array,
}

export default ScoreDescription
