import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY_ACTIVITY_LOG, QUERY_SELECTED_STUDENT } from '../../../services/graphql/queries/queries'
import Dialog from '../../_common/dialog/Dialog'
import Spinner from '../../_common/spinner/Spinner'
import { Column, Table } from '@cb/apricot-react'
import { APP_LINKS } from '../../../utils/navigation'
import { useHistory } from 'react-router-dom'
import { formatDate, FORMATS } from '../../../utils/date'
import orderBy from 'lodash/orderBy'

const ActivityLog = () => {
  const history = useHistory()
  const { loading: studentLoading, error: studentError, data: student } = useQuery(QUERY_SELECTED_STUDENT)
  const { firstName, lastName, catapultId } = student?.getSelectedStudent || {}
  const [getActivityLog, { loading, error, data }] = useLazyQuery(QUERY_ACTIVITY_LOG, {
    variables: { catapultId },
    fetchPolicy: 'network-only',
  })
  const activityLog = data?.getActivityLog || []

  useEffect(() => {
    if (student && !catapultId) history.replace(APP_LINKS.studentsearch)
    if (catapultId) getActivityLog()
  }, [student])

  return (
    <div className="container cb-padding-top-48">
      <h1 className="cb-h3 cb-margin-bottom-32">
        Activity Log: {firstName} {lastName}
      </h1>
      {studentLoading || loading ? (
        <Spinner className="cb-margin-top-16" />
      ) : studentError || error ? (
        <Dialog title="Error retrieving student info" className="cb-margin-top-16" error />
      ) : student && activityLog.length ? (
        <div className="cb-margin-top-24">
          <Table responsive data={orderBy(activityLog, ['activityDate', 'activityName'], 'desc')}>
            <Column
              field="activityDate"
              title="Date"
              component={field => formatDate(field.value, FORMATS.fulldatetime)}
              rowHeader
            />
            <Column field="activityName" title="Activity" />
            <Column field="activitySource" title="Source" />
            <Column field="userId" title="User ID" />
            <Column field="remarks" title="Remarks" />
          </Table>
        </div>
      ) : (
        <p>No Activity logged for this account.</p>
      )}
    </div>
  )
}

export default ActivityLog
