import { convertUpperCaseToTitleCase } from '../../../../../utils/app'
import { formatDate, FORMATS } from '../../../../../utils/date'

const OrderDetails = ({ student, order }) => {
  const items = [
    { label: 'Order Number', value: order.orderId },
    { label: 'Order Date', value: formatDate(order.orderDate, FORMATS.mediumdate) },
    {
      label: 'Billed To',
      value: order.creditCardType
        ? `${convertUpperCaseToTitleCase(order.creditCardType)}, ending in ${order.lastFourDigitsOfCard}`
        : 'N/A',
    },
    { label: 'AP ID', value: student.apUid },
  ]

  return (
    <div className="apscores-order-details">
      <h3 className="cb-font-weight-xs-medium cb-roboto cb-margin-xs-bottom-16 cb-margin-bottom-24">Order Details</h3>
      {items.map((item, i) => (
        <div key={i} className="cb-border-1 cb-border-bottom cb-margin-bottom-24 cb-margin-xs-bottom-16 cb-paragraph1-res">
          <div className="cb-font-size-small">
            <strong className="cb-roboto-bold">{item.label}</strong>
          </div>
          <div className="cb-roboto-light cb-font-weight-xs-regular cb-padding-bottom-24 cb-padding-xs-bottom-16">
            {item.value}
          </div>
        </div>
      ))}
    </div>
  )
}

export default OrderDetails
