import { CONTAINER_WIDE_CLASS, ROW_CLASS } from '../../../utils/constants'
import Dialog from '../dialog/Dialog'
import Spinner from '../spinner/Spinner'
import Header from './header/Header'
import Navigation from './navigation/Navigation'

const Layout = ({ loading, error, loaded, children, title, subtitle, useNav = true }) => {
  return (
    <div className="cb-gray5-bg cb-global-footer-reset">
      <Header title={title} subtitle={subtitle} />
      {useNav ? <Navigation /> : null}
      <div className="container cb-margin-sm-up-top-72 cb-margin-xs-top-48">
        {loading && <Spinner center />}
        {error && (
          <div className="row">
            <div className={CONTAINER_WIDE_CLASS}>
              <Dialog error title="Error retrieving data">
                {error}
              </Dialog>
            </div>
          </div>
        )}
        {loaded ? <div className={ROW_CLASS}>{children}</div> : null}
      </div>
    </div>
  )
}

Layout.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  loaded: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  useNav: PropTypes.bool,
}

export default Layout
