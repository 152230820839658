import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import addBusinessDays from 'date-fns/addBusinessDays'
import isValid from 'date-fns/isValid'
import differenceInHours from 'date-fns/differenceInHours'
import CBData from '@cb/apricot/CBData'
import format from 'date-fns-tz/format'

export const FORMATS = {
  fulldate: 'PPPP', // Saturday, May 29th 1900
  fulldatetime: "MMMM d, yyyy 'at' h:mm:ss aa zzz ",
  shortdate: 'P', // 05/29/1900
  mediumdate: 'MMMM d, yyyy',
  monthday: 'MMMM d', // May 29
  monthdaytime: 'MMMM d, h:mm aaaa', // May 29, 7:00 p.m.
  monthdayattime: 'PPPp z', // May 29, 1900 at 7pm
  year: 'yyyy',
  month: 'MMMM',
}

const addLeadingZero = val => (val < 10 ? `0${val}` : val)
export const MONTHS = CBData.monthlong.map((m, i) => ({ value: i ? addLeadingZero(i) : '', label: m.name }))
export const DAYS = [
  { value: '', label: 'Day' },
  ...Array.from(Array(31), (_, x) => ({ value: addLeadingZero(x + 1), label: x + 1 })),
]
export const getYears = (startFromYear, valueDigitAmt = 4) => {
  const arrLength = new Date().getFullYear() - startFromYear + 1
  return [
    { value: '', label: '' },
    ...Array.from(Array(arrLength), (_, x) => {
      const d = new Date()
      const year = d.getFullYear() - x
      const yearStringLen = year.toString()?.length
      let valYear = ''
      if (valueDigitAmt === 2) valYear = year.toString().substring(yearStringLen - 2, yearStringLen)
      if (valueDigitAmt === 4) valYear = year.toString()
      return { value: valYear, label: year }
    }),
  ]
}

export const parse = d => (typeof d === 'string' ? parseISO(d) : d)

export const getDateWithoutTime = d => d && d.split('T')[0]

export const formatDate = (d, formatter = FORMATS.shortdate) => (d ? format(parse(d), formatter) : null)

export const formatDateToEasternTime = (d, formatter = FORMATS.fulldatetime) =>
  format(parse(d), formatter, { timeZone: 'America/New_York' })

export const isDateWithinRange = (beforeDate, afterDate, d) => {
  const date = d ? parse(d) : new Date()
  return isAfter(date, parse(beforeDate)) && isBefore(date, parse(afterDate))
}

export const isDateCurrent = (d, maxAllowedDiff = 1) => {
  const diff = d ? differenceInHours(new Date(), parse(d)) : 0
  return diff < maxAllowedDiff
}

export const addBusinessDaysToDate = (date = new Date(), num) => addBusinessDays(parse(date), num)

export const getDateRange = (start, end, formatter = FORMATS.monthday) =>
  `${formatDate(start, formatter)} - ${formatDate(end, formatter)}`

export const isValidDate = date => isValid(parse(date))
export const isValidISODateFormat = date => /^\d{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/.test(date)
export const isValidISOTimeFormat = time =>
  /(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]):(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])/.test(time)
export const formatYear = year => {
  let adminYear = 2000 + Number(year)
  const currentYear = Number(formatDate(new Date(), FORMATS.year))
  if (adminYear > currentYear + 1) {
    adminYear = 1900 + Number(year)
    return adminYear
  } else return adminYear
}

export const getTimestamp = () => new Date().toISOString()

export const TODAY_DATE = new Date()
export const TODAY_ISO = new Date().toISOString()
