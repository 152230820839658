import CBUtils from '@cb/apricot/CBUtils'
import html2pdf from 'html2pdf.js'
import { formatDate, FORMATS } from '../../../utils/date'
import Button from '../button/Button'

import './pdfdownload.scss'

const PDF_MAX_HEIGHT = 740
const BUFFER = 150

const PDFDownload = ({ pdfContainerId, pdfTitle, filename, buttonTitle, className, pdfMaxHeight = PDF_MAX_HEIGHT, tracking }) => {
  const [processing, setProcessing] = useState(false)
  const processContainer = useRef(null)

  const printToPdf = async () => {
    setProcessing(true)
    const content = document.getElementById(pdfContainerId)
    const pdfContainer = processContainer.current.appendChild(document.createElement('div'))
    pdfContainer.className = 'pdf-container'
    const pages = []

    try {
      // select all top header elements on the page and get their siblings
      // calculate the height of each element and divide them into pages
      Array.from(content.querySelectorAll('h2, h3')).forEach(elem => {
        Array.from(CBUtils.parent(elem).children).forEach(c => {
          // need to append to document for proper height calculation
          const classes = Array.from(c.classList)
          const component = pdfContainer.appendChild(c.cloneNode(true))
          const height = component.offsetHeight
          const isHeader = ['H2', 'H3', 'H4'].includes(c.tagName)

          // skip stepper and buttons
          if (classes.find(c => c.includes('stepper') || c.includes('cb-modal-title')) || c.tagName === 'BUTTON') return

          const mobileClasses = classes.filter(c => c.includes('xs-'))
          component.classList.remove(...mobileClasses) // remove mobile classes to standardize spacing across breakpoints
          const pagesCount = pages.length
          const currentIndex = pagesCount ? pagesCount - 1 : 0
          const { totalHeight: currentHeight = 0 } = pages[currentIndex] || {}
          const updatedHeight = currentHeight + height
          const pageIndexToAddElementTo =
            (isHeader && pdfMaxHeight - updatedHeight < BUFFER) || updatedHeight > pdfMaxHeight ? pagesCount : currentIndex
          const { totalHeight = 0, element = document.createElement('div') } = pages[pageIndexToAddElementTo] || {}
          element.appendChild(component)
          pages[pageIndexToAddElementTo] = { totalHeight: totalHeight + height, element }
        })
      })

      let worker = html2pdf().set({
        filename,
        jsPDF: { unit: 'pt', format: 'letter', precision: 1 },
        html2canvas: {
          ignoreElements: element => {
            const { tagName, classList } = element
            return tagName === 'BUTTON' || tagName === 'A' || classList.contains('cb-glyph')
          },
          scale: 2,
          scrollX: 0,
          scrollY: 0,
        },
      })

      pages.forEach(({ element }, i) => {
        const pageNum = i + 1
        const pageContainer = processContainer.current.appendChild(document.createElement('div'))
        pageContainer.className = 'pdf-container'
        element.className = 'pdf-content'
        const header = document.createElement('div')
        header.className = 'pdf-header'
        header.innerText = pdfTitle
        const footer = document.createElement('div')
        footer.className = 'pdf-footer display-flex justify-content-between'
        footer.innerHTML = `<div>&copy; ${formatDate(new Date(), FORMATS.year)} The College Board</div><div>Page ${pageNum} of ${
          pages.length
        }</div>`
        pageContainer.appendChild(header)
        pageContainer.appendChild(element)
        pageContainer.appendChild(footer)

        if (i > 0)
          worker = worker
            .get('pdf')
            .then(pdf => pdf.addPage())
            .from(pageContainer)
            .toContainer()
            .toCanvas()
            .toPdf()
        else worker = worker.from(pageContainer).toPdf()
      })

      await worker.save()

      // reset download container
      processContainer.current.innerHTML = ''
    } catch (err) {
      console.error('pdf download error', err)
    }
    setProcessing(false)
  }

  return (
    <div className={className}>
      <Button tracking={tracking} className="cb-btn-black cb-no-print" onClick={printToPdf} loading={processing}>
        <span className="cb-glyph cb-download cb-padding-right-8 cb-no-margin" aria-hidden="true" />
        {buttonTitle}
      </Button>
      <div className="pdf-process-container" ref={processContainer} />
    </div>
  )
}

PDFDownload.propTypes = {
  pdfContainerId: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  pdfTitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  pdfMaxHeight: PropTypes.number,
  tracking: PropTypes.string,
}

export default PDFDownload
