import { Checkbox } from '@cb/apricot-react'
import chunk from 'lodash/chunk'

const CheckboxColumns = ({ legend, onChange, name, checkboxes, selected = [], numOfColumns = 2 }) => {
  const count = checkboxes.length
  const columns = chunk(checkboxes, Math.ceil(count / numOfColumns))

  return (
    <fieldset>
      <legend className="cb-label cb-margin-bottom-16">{legend}</legend>
      <div className="display-flex">
        {columns.map((fields, i) => (
          <div key={i} className="cb-margin-right-72">
            {fields.map(f => (
              <Checkbox
                className="cb-margin-bottom-16"
                key={f.value}
                label={`${f.label} (${f.value})`}
                name={`${name}_${f.value}`}
                checked={Boolean(selected.find(s => s === f.value))}
                onChange={checked => onChange(checked ? [...selected, f.value] : selected.filter(s => s !== f.value))}
              />
            ))}
          </div>
        ))}
      </div>
    </fieldset>
  )
}

CheckboxColumns.propTypes = {
  legend: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checkboxes: PropTypes.array.isRequired,
  numOfColumns: PropTypes.number,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}

export default CheckboxColumns
