import Stepper from '../stepper/Stepper'

import './band.scss'

const Band = ({ title, subtitle, children, containerClass = 'col-xs-12', stepProps }) => (
  <div className="apscores-band cb-band cb-band-hero cb-gray5-bg">
    <div className="container">
      <div className="row cb-band-hero-height-auto">
        <div className={containerClass}>
          <div className="cb-band-hero-content">
            {stepProps ? <Stepper simple left {...stepProps} /> : null}
            {subtitle ? <div className="apscores-band-subtitle cb-text-uppercase">{subtitle}</div> : null}
            <h2 className="apscores-band-title cb-band-hero-title">{title}</h2>
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
)

Band.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  containerClass: PropTypes.string,
  stepProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Band
