import { QUERY_PROPERTIES, QUERY_STUDENT_INFO } from '../../../../../services/graphql/queries/queries'
import { useCustomQuery } from '../../../../../services/graphql/utils'
import { ORDER_PARAM } from '../../../../../services/local/order'
import Spinner from '../../../../_common/spinner/Spinner'
import StudentInformation from '../../_common/StudentInformation/StudentInformation'

const ReviewStudentInfo = () => {
  const { loading, data = [] } = useCustomQuery([{ query: QUERY_PROPERTIES }, { query: QUERY_STUDENT_INFO }])
  const [properties = [], info] = data
  let appId

  if (properties) {
    appId = properties?.find(p => p.propName === 'appId')?.propValue
  }

  if (loading)
    return (
      <div className="cb-margin-top-72">
        <Spinner center />
      </div>
    )

  if (info)
    return (
      <>
        <StudentInformation {...info} title="Review Your Information" />
        {appId && (
          <div className="cb-paragraph1-res cb-margin-top-16">
            <a
              href={`https://account.collegeboard.org/iamweb/secure/smartUpdate?appId=${appId}&DURL=${window.location.href}?${ORDER_PARAM}=true`}
              className="cb-black1-color"
            >
              Edit Account Information
            </a>
          </div>
        )}
      </>
    )

  return null
}

export default ReviewStudentInfo
