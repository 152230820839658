import ContentLookup from '../../../../../_common/request/ContentLookup'
import TooltipPanel from '../../../../../_common/panel/TooltipPanel'
import { convertArrayToPrettyString } from '../../../../../../utils/app'
import { PANEL_INFO, PANEL_PROJECTED, PANEL_WITHHOLD } from '../../../../../../utils/analytics'

const HEADING_CLASSES =
  'cb-roboto cb-font-weight-xs-medium cb-padding-sm-up-top-16 cb-padding-bottom-24 cb-padding-xs-bottom-16 cb-border-1 cb-border-bottom cb-margin-top-48'
const ITEM_CLASSES =
  'cb-roboto-light cb-font-weight-xs-regular cb-padding-top-24 cb-padding-bottom-24 cb-padding-xs-top-16 cb-padding-xs-bottom-16 cb-border-1 cb-border-bottom cb-paragraph1-res'

// the structure of this html needs to remain flat (ie: no surrounding divs)
// this is needed for the print order confirmation
const ScoresAndAwards = ({ data }) => 
  data.map((d, i) => (
    <Fragment key={`scores-awards-${d.adminYear}`}>
      {d.filteredScores?.length ? (
        <>
          <h4 className={i ? HEADING_CLASSES : HEADING_CLASSES.replace('48', '16')}>{d.displayYear} Scores</h4>
          {d.filteredScores.map(score => {
            const infoEventCodesAllowed = [31, 32]
            const hasSubscores = score.subScores && score.subScores.length
            const hasInfoEvents = score.eventsInfo && score.eventsInfo.length
            // display infotrmational events 31,32 only if score has hold event

            const filteredEvents = score.eventsInfo.filter(event => !infoEventCodesAllowed.includes(event.eventCode))
            const hasProjEvents = score.eventsProjected && score.eventsProjected.length 
            return (
              <div className={`${ITEM_CLASSES} ${hasSubscores ? `flex-column` : ''}`} key={`${score.exCode}-${d.adminYear}`}>
                <div className="display-flex justify-content-between">
                  <h5>{score.exNameFormatted}</h5>
                  <div>{score.score}</div>
                </div>
                {hasSubscores &&
                  score.subScores.map(sub => (
                    <div
                      key={`${sub.exCode}-${d.adminYear}`}
                      className="display-flex justify-content-between cb-font-size-regular cb-font-size-xs-small cb-padding-top-8"
                    >
                      <div>Subscore: {sub.exNameFormatted}</div>
                      <div>{sub.score}</div>
                    </div>
                  ))}
                {hasProjEvents
                  ? score.eventsProjected.map(p => (
                      <ContentLookup
                        file="eventsProjected"
                        type={p.eventCode}
                        key={p.eventCode}
                        component={({ content = {} }) => (
                          <div
                            key={p.eventCode}
                            className="cb-font-size-regular cb-font-size-xs-small cb-padding-top-8 cb-gray1-color"
                          >
                            {content.desc || p.eventDesc}{' '}
                            {content.more ? (
                              <TooltipPanel
                                srOnlySupplementalText={`${d.displayYear} ${score.exNameFormatted}`}
                                trigger={`projected-${score.exName}-${p.eventCode}-${d.displayYear}`}
                                title="Projected Score"
                                content={content.more}
                                responsive
                                tracking={PANEL_PROJECTED}
                              />
                            ) : null}
                          </div>
                        )}
                      />
                    ))
                  : null}
                {score.sendableSubset ? (
                  <div className="cb-gray1-color cb-font-size-regular cb-font-size-xs-small">
                    Note: This score <strong className="cb-roboto-medium">will</strong> be sent to{' '}
                    {convertArrayToPrettyString(score.sendableSubset)}.{' '}
                    <ContentLookup
                      file="orders"
                      type="review"
                      component={({ content = {} }) => (
                        <TooltipPanel
                          trigger={`withholds-${score.exCode}-${d.adminYear}`}
                          title={`${score.exNameFormatted} Withholds`}
                          content={content.withholdspanel}
                          responsive
                          tracking={PANEL_WITHHOLD}
                        />
                      )}
                    />
                  </div>
                ) : null}
                {hasInfoEvents
                  ? filteredEvents.map(e => (
                      <ContentLookup
                        file="eventsInformational"
                        type={e.eventCode}
                        key={e.eventCode}
                        component={({ content = {} }) => (
                          <div
                            key={e.eventCode}
                            className="cb-font-size-regular cb-font-size-xs-small cb-padding-top-8 cb-gray1-color"
                          >
                            <span className="cb-padding-right-8">
                              <strong className="cb-roboto-medium">Exam Note: </strong>
                              {content.desc || e.eventDesc}
                            </span>
                            {content.panel ? (
                              <TooltipPanel
                                srOnlySupplementalText={`${d.displayYear} ${score.exNameFormatted}`}
                                trigger={`score-informational-${score.exName}-${e.eventCode}-${d.displayYear}`}
                                title="About Your Score"
                                content={content.panel}
                                responsive
                                tracking={PANEL_INFO}
                              />
                            ) : null}
                          </div>
                        )}
                      />
                    ))
                  : null}
              </div>
            )
          })}
        </>
      ) : null}       
      {d.hasAwards ? (
        <>
          <h4 className={HEADING_CLASSES}>{d.displayYear} Awards</h4>
          {d.awards.map(a => (
            <div className={ITEM_CLASSES} key={`${d.adminYear}-${a.awCode}`}>
              {a.awName}
            </div>
          ))}
        </>
      ) : null}
    </Fragment>
  ))

ScoresAndAwards.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ScoresAndAwards
