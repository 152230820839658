import CBUtils from '@cb/apricot/CBUtils'
import { CloseButton } from '@cb/apricot-react'
import { trackPanel } from '../../../utils/analytics'

import './panel.scss'

const BODY_CLASS = 'apscores-panel-open'

const Panel = ({ triggerId, defaultOpen = false, onClose, title, children, tracking }) => {
  const [open, setOpen] = useState(defaultOpen)
  const [transitionOut, setTransitionOut] = useState(false)

  useEffect(() => {
    if (open && tracking) trackPanel(tracking)
  }, [open])

  const togglePanel = (e, val) => {
    const newOpen = val !== undefined ? val : !open

    e && e.preventDefault()

    if (!newOpen && open) {
      document.body.classList.remove(BODY_CLASS)
      setTransitionOut(true)
      setTimeout(() => {
        setTransitionOut(false)
        setOpen(false)
        onClose && onClose()
        document.getElementById(triggerId)?.focus()
      }, 500)
    } else if (newOpen) {
      setTransitionOut(false)
      setOpen(true)
      document.body.classList.add(BODY_CLASS)
    }
  }

  const PanelContent = () => {
    // needs to be child component, so event listeners are properly removed when panel closes
    const containerRef = useRef(null)
    const closeBtnId = 'apscores-panel-close'

    const trapFocus = e => {
      const { FOCUSABLE_ELEMENTS, whichKey } = CBUtils
      const tabbablePanelElements = Array.from(containerRef.current.querySelectorAll(FOCUSABLE_ELEMENTS))
      const [firstTabbable] = tabbablePanelElements
      const lastTabbable = tabbablePanelElements[tabbablePanelElements.length - 1]
      const isElemInPanel = tabbablePanelElements.find(ele => ele === e.target)
      const which = whichKey(e)

      if (which === 'ESC') togglePanel(e, false)
      else if (!isElemInPanel && which === 'TAB') {
        const focusElement = e.shiftKey ? lastTabbable : firstTabbable
        e.preventDefault()
        focusElement.focus()
      }
    }

    useEffect(() => {
      document.getElementById(closeBtnId).focus()
      document.addEventListener('keyup', trapFocus)
      return () => {
        document.removeEventListener('keyup', trapFocus)
      }
    }, [])

    return (
      <div className="apscores-panel-content" ref={containerRef} aria-modal role="dialog">
        <div className="apscores-panel-heading cb-box-shadow-bottom cb-align-center">
          <CloseButton onClick={e => togglePanel(e, false)} aria-describedby="apscores-panel-header" id={closeBtnId} />
          <h3 id="apscores-panel-header" className="cb-roboto-medium cb-font-size-regular">
            {title}
          </h3>
        </div>
        <div className="apscores-panel-body cb-align-left">{children}</div>
      </div>
    )
  }

  useEffect(() => {
    const trigger = document.getElementById(triggerId)
    trigger?.addEventListener('click', togglePanel)
    if (open) document.body.classList.add(BODY_CLASS)
    return () => {
      trigger?.removeEventListener('click', togglePanel)
      // ensure body class is removed on unmount
      document.body.classList.remove(BODY_CLASS)
    }
  }, [triggerId])

  useEffect(() => {
    togglePanel(null, defaultOpen)
  }, [defaultOpen])

  return open ? (
    <div className={`apscores-panel ${transitionOut ? 'transitioning' : ''}`} aria-labelledby={triggerId}>
      <div className="apscores-panel-overlay" tabIndex="-1" onClick={e => togglePanel(e, false)} />
      {open ? <PanelContent /> : null}
    </div>
  ) : null
}

Panel.propTypes = {
  triggerId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  onClose: PropTypes.func,
  title: PropTypes.string,
  defaultOpen: PropTypes.bool,
  tracking: PropTypes.object,
}

export default Panel
