import { getQueryParams } from './app'
import { addBusinessDaysToDate, getDateRange, isDateWithinRange } from './date'

export const VALUE_Y = 'Y'
export const VALUE_N = 'N'

export const isResend = resend => resend === VALUE_Y

export const getPriceProperties = (props = {}, skipPayment, date) => {
  const isBlackoutPeriod = isDateWithinRange(props.blackoutStartDate, props.blackoutEndDate, date)
  const isStandardOverridePeriod = isDateWithinRange(props.standardOverrideStartDate, props.standardOverrideEndDate, date)
  const isUpdatedDeliveryAlertPeriod = isDateWithinRange(props.upgradedDeliveryAlertStartDate, props.upgradedDeliveryAlertEndDate, date)
  const rangeStartDate = isBlackoutPeriod ? props.blackoutEndDate : date
  const rangeStartDay = 5 
  const rangeEndDay = 9

  return {
    isBlackoutPeriod,
    isUpdatedDeliveryAlertPeriod,
    standardPrice: skipPayment ? 0 : isStandardOverridePeriod ? props.standardOverridePrice : props.standardPrice,
    deliveryDateRange:
      date ? getDateRange(addBusinessDaysToDate(rangeStartDate, rangeStartDay), addBusinessDaysToDate(rangeStartDate, rangeEndDay))
        : null,
    
  }
}

export const getTotalPrice = (selected, standard) => selected.reduce((total) => total + standard, 0)

export const getDeliveryTitle = () => 'Standard Delivery'

export const getEcommStatus = () => getQueryParams().status
