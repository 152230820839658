import Band from '../../../_common/band/Band'
import IntroModal from './intromodal/IntroModal'
import ButtonLink from '../../../_common/links/ButtonLink'
import ActionBar from '../../../_common/actionbar/ActionBar'
import DIAutosuggest from './diautosuggest/DIAutosuggest'
import { APP_LINKS } from '../../../../utils/navigation'
import WhichScores from './whichscores/WhichScores'
import CreditPolicy from './creditpolicy/CreditPolicy'
import DiSelections from './diselections/DiSelections'
import CancelOrder from '../_common/CancelOrder/CancelOrder'
import { CONTAINER_THIN_CLASS } from '../../../../utils/constants'

const ScoreRecipient = ({ selected = [], previous = [], welcomeViewed, stepProps }) => (
  <>
    <Band title="Select Scores Recipient" subtitle="Send AP Scores" containerClass={CONTAINER_THIN_CLASS} stepProps={stepProps}>
      <DIAutosuggest previous={previous} selected={selected} />
    </Band>
    <IntroModal welcomeViewed={welcomeViewed} />
    <div className="container">
      <div className="row">
        <div className={CONTAINER_THIN_CLASS}>
          <DiSelections selected={selected} />
          <WhichScores />
          <CreditPolicy />
          <CancelOrder />
        </div>
      </div>
    </div>
    <ActionBar
      containerClass={CONTAINER_THIN_CLASS}
      buttons={[
        null,
        () => <ButtonLink primary disabled={selected.length === 0} path={APP_LINKS.revieworder} title="Continue" />,
      ]}
    />
  </>
)

ScoreRecipient.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object),
  previous: PropTypes.arrayOf(PropTypes.object),
  welcomeViewed: PropTypes.bool,
  stepProps: PropTypes.object,
}

export default ScoreRecipient
