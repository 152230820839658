import { CircularButton } from '@cb/apricot-react'
import Panel from './Panel'

const TooltipPanel = ({ title, trigger, content, responsive, srOnlySupplementalText, tracking }) => {
  return content ? (
    <>
      <CircularButton
        id={trigger}
        className={`cb-btn-black ${responsive ? 'cb-btn1-res' : 'cb-btn-xs'} display-inline-block`}
        icon="question"
        aria-haspopup="dialog"
        label={`Open ${srOnlySupplementalText} ${title} panel`}
      />
      <Panel title={title} triggerId={trigger} tracking={tracking}>
        <div className="cb-padding-24">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Panel>
    </>
  ) : null
}

TooltipPanel.propTypes = {
  title: PropTypes.string.isRequired,
  trigger: PropTypes.string.isRequired,
  content: PropTypes.string,
  responsive: PropTypes.bool,
  srOnlySupplementalText: PropTypes.string,
  tracking: PropTypes.object,
}

export default TooltipPanel
