import { PrimaryButton, Button as SecondaryButton, NakedButton } from '@cb/apricot-react'

const Button = ({
  primary = false,
  naked = false,
  light = false,
  disabled = false,
  loading = false,
  onClick,
  title,
  className,
  id,
  children,
  closeModal,
  type = 'button',
  ariaLabel,
  tracking
}) => {
  const Component = naked ? NakedButton : primary ? PrimaryButton : SecondaryButton
  const modalTrigger = closeModal ? { 'data-cb-modal-close': true } : {}

  return (
    <Component
      data-cbtrack-download={tracking}
      id={id}
      light={light}
      disabled={disabled}
      loading={loading}
      onClick={disabled || loading ? null : onClick}
      className={className}
      type={type}
      aria-label={ariaLabel}
      {...modalTrigger}
    >
      {title || children}
    </Component>
  )
}

const eitherOrCheck = (props, propName, componentName) => {
  if (!props.title && !props.children) return new Error(`One of the props 'title' or 'icon' is required in ${componentName}`)
}

Button.propTypes = {
  primary: PropTypes.bool,
  naked: PropTypes.bool,
  light: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  title: eitherOrCheck,
  children: eitherOrCheck,
  closeModal: PropTypes.bool,
  type: PropTypes.string,
  ariaLabel: PropTypes.string,
  tracking: PropTypes.string,
}

export default Button
