import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import mem from 'mem'
import { RIGHT_COLUMN_CLASS, LEFT_COLUMN_CLASS } from '../../../utils/constants'
import Layout from '../../_common/layout/Layout'
import FreeSendScores from '../content/freesendscores/FreeSendScores'
import SendScores from '../content/sendscores/SendScores'
import ArchivedScores from '../content/archivedscores/ArchivedScores'
import Search from './actions/search/Search'
import { SpacerV } from '@cb/apricot-react'
import { SORT_OPTIONS, TYPE_INSTITUTION } from './utils'
import ByInstitution from './byinstitution/ByInstitution'
import ByOrder from './byorder/ByOrder'
import FAQsOrders from '../content/faqs/FAQsOrders'
import { useCustomQuery } from '../../../services/graphql/utils'
import { QUERY_ORDERS_SENDSCORES } from '../../../services/graphql/queries/queries'
import { PASTSCORESENDS_ERROR, trackPagePastScoreSends, useTrackingError } from '../../../utils/analytics'

const getCombined = mem(data => {
  const { orders = [], sendscores = [] } = data
  // when combining data from the overall order and a specific di order matters.
  // the overall order needs to come second so orderStatus is the status of the overall order and not a specific di
  const ungroupedOrders = orders.reduce((arr, o) => [...arr, ...o.dis.map(d => ({ ...d, ...o, order: true }))], [])
  const groupedInstitutions = groupBy([...ungroupedOrders, ...sendscores], 'diCode')

  return Object.values(groupedInstitutions).map(g => ({
    items: orderBy(g, 'date', 'desc'),
    diCode: g[0].diCode,
    diName: g[0].diName,
    updateDt: g.reduce((d, { date }) => (d < date ? date : d), ''),
  }))
})

const PastOrders = () => {
  const { data, loading, error } = useCustomQuery([{ query: QUERY_ORDERS_SENDSCORES, fetchPolicy: 'cache-and-network' }])
  const [settings, setSettings] = useState({})
  const [filter, setFilter] = useState([])
  const [visibleData, setVisibleData] = useState({ institution: [], order: [] })
  const { view, sort, direction } = settings
  const isInstitutionView = !view || view === TYPE_INSTITUTION
  const { sortFunc } = SORT_OPTIONS[view]?.find(f => f.value === sort) || {}
  const dataset = isInstitutionView ? visibleData.institution : visibleData.order
  const orderedData = sortFunc ? sortFunc(dataset, direction) : dataset
  const hasOrders = data?.orders.length
  const hasSendScores = data?.sendscores.length
  const combined = getCombined(data || {})

  useEffect(() => {
    const hasFilters = filter.length
    let institution = combined
    let order = data?.orders || []

    if (hasFilters) {
      institution = institution.filter(d => filter.find(f => f.diCode === d.diCode))
      order = order.filter(d => filter.find(f => d.dis.find(d => d.diCode === f.diCode)))
    }

    if (data) setVisibleData({ institution, order })
  }, [data, filter])

  useEffect(() => {
    if (data) trackPagePastScoreSends(hasOrders || hasSendScores, view)
  }, [data, view])

  useTrackingError(error, { message: PASTSCORESENDS_ERROR })

  return (
    <Layout
      loaded={Boolean(data) && !loading}
      loading={loading}
      error={error ? 'An error occurred retrieving your past score sends. Please try again later.' : null}
      subtitle="Your past score sends and orders"
    >
      <div className={LEFT_COLUMN_CLASS}>
        {!hasSendScores && !hasOrders ? (
          <p className="cb-margin-bottom-48">You do not have past score sends.</p>
        ) : (
          <>
            <Search
              onChange={setSettings}
              data={combined.map(d => ({ label: `${d.diName} (${d.diCode})`, diCode: d.diCode }))}
              filter={setFilter}
              hasOrders={hasOrders}
              hasSendScores={hasSendScores}
              defaultView={TYPE_INSTITUTION}
            />
            <SpacerV size={24} />
            {isInstitutionView ? (
              orderedData.map((d, i) => <ByInstitution {...d} key={`${d.diCode}-${i}`} />)
            ) : !hasOrders ? (
              <p className="cb-margin-bottom-48">You do not have any score orders.</p>
            ) : (
              orderedData.map((d, i) => <ByOrder order={d} key={`${d.orderDate}-${d.orderId}-${i}`} />)
            )}
          </>
        )}
      </div>
      <div className={RIGHT_COLUMN_CLASS}>
        <FAQsOrders />
        <ArchivedScores callout />
        <SendScores callout />
        <FreeSendScores callout />
      </div>
    </Layout>
  )
}

export default PastOrders
