import { QUERY_WITHHOLDS } from '../../../../../services/graphql/queries/queries'
import Spinner from '../../../../_common/spinner/Spinner'
import Dialog from '../../../../_common/dialog/Dialog'
import ScoresAndAwards from './scoresandawards/ScoresAndAwards'
import WithholdAlerts from './alerts/WithholdAlerts'
import CanceledAlerts from './alerts/CanceledAlerts'
import ScoreHoldAlerts from './alerts/ScoreHoldAlerts'
import { useCustomQuery } from '../../../../../services/graphql/utils'
import { useTrackingError, WITHHOLDS_ERROR } from '../../../../../utils/analytics'
import useMissingZip from '../../missingzip/useMissingZip'

const ScoresOrder = ({ scoresAndAwardsByYear, selected }) => {
  const { loading, error, data = {} } = useCustomQuery([{ query: QUERY_WITHHOLDS }])
  const numSelected = selected.length
  let hasAtLeastOneSendableScore = false
  let activeWithholds = []
  let cancelEvents = []
  let holdEvents = []
  // remove withheld schools that have not been selected
  const withholdsFilterBySelected = data?.length ? data.filter(w => selected.find(s => s.diCode === w.diCode)) : []
  // filter out exams that do not have a score (cancel, in progress) and store these exams in cancelEvents & holdEvents
  // filter where the score will not be sent because all selected schools have withholds
  // store all withholds tied to a score in activeWithholds array to be sent to WithholdAlert component
  // set hasAtLeastOneSendableScore flag
  // added hasAwards variable so that it can be used to see if awards are available
  const filteredScoresByYear = scoresAndAwardsByYear.map(year => {
    const hasAwards = year?.awards?.length
    const filteredScores = year.scores.reduce((acc, score) => {
      const withholds = withholdsFilterBySelected.filter(w => w.exCode === score.exCode && w.adminYear === year.adminYear)
      const numWitholds = withholds.length
      const isScoreSendable = score.score && numWitholds !== numSelected
      const sendableSubset = numWitholds
        ? selected.reduce((acc, s) => (withholds.find(w => w.diCode === s.diCode) ? acc : [...acc, s.diName]), [])
        : null
      const event = { ...score, ...year }
      if (score.score) activeWithholds.push(...withholds)
      if (isScoreSendable) hasAtLeastOneSendableScore = true
      if (score.eventCancel) cancelEvents.push(event)
      if (score.eventHold && !score.eventCancel) holdEvents.push(event)
      return isScoreSendable ? [...acc, { ...score, sendableSubset }] : acc
    }, [])
    return { ...year, filteredScores, hasAwards }
  })
  const { component: ZipCodeAlert, showMissingZipAlert, props } = useMissingZip()

  useTrackingError(error, { message: WITHHOLDS_ERROR })

  if (loading) return <Spinner center />

  if (error)
    return (
      <Dialog error title="Error retrieving data">
        An error occurred retrieving your score data for your order. Please try again later.
      </Dialog>
    )

  return (
    <>
      {showMissingZipAlert && <ZipCodeAlert {...props} />}
      <WithholdAlerts withholds={activeWithholds} />
      <ScoreHoldAlerts holds={holdEvents} />
      <CanceledAlerts cancelEvents={cancelEvents} />
      {hasAtLeastOneSendableScore ? (
        <ScoresAndAwards data={filteredScoresByYear} />
      ) : (
        <p className="cb-margin-top-48 cb-h4 cb-padding-bottom-24 cb-padding-xs-bottom-16 cb-border-1 cb-border-bottom">
          You have no scores or awards that will be sent.
        </p>
      )}
    </>
  )
}

ScoresOrder.propTypes = {
  scoresAndAwardsByYear: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ScoresOrder
