import { useHistory } from 'react-router-dom'
import { Checkbox, Column, Dropdown, DropdownItem, Table } from '@cb/apricot-react'
import Spinner from '../../../_common/spinner/Spinner'
import Dialog from '../../../_common/dialog/Dialog'
import { APP_LINKS } from '../../../../utils/navigation'
import useStudentSearch from '../../../../services/local/studentsearch'
import Button from '../../../_common/button/Button'
import { formatDate, FORMATS } from '../../../../utils/date'
import { useQuery } from '@apollo/client'
import { QUERY_GET_APP_INFO } from '../../../../services/graphql/queries/queries'
import { getQueryDataWithoutKey } from '../../../../services/graphql/utils'

const MAX_NUMBER_RETURNED = 100

const SearchResults = ({ loading, error, data }) => {
  const history = useHistory()
  const { setSelectedStudent } = useStudentSearch()
  const { students, total } = data || {}
  const resultsCount = students?.length
  const hasMultiResults = resultsCount > 1
  const [selections, setSelections] = useState(resultsCount === 1 ? [students[0]] : [])
  const selectionsCount = selections.length
  const hasNoSelections = selectionsCount === 0
  const canNavigateToStudentExperience = selectionsCount === 1 && selections[0]?.catapultId
  const canNavigationToCompare = selectionsCount === 2 && selections.every(s => s.catapultId)
  const setSelectionsAndNavigate = link => {
    const { firstName, lastName, personId, catapultId } = selections[0]
    setSelectedStudent({
      firstName,
      lastName,
      personId,
      catapultId,
      compareCatapultId: selections[1]?.catapultId,
      comparePersonId: selections[1]?.personId,
    })
    setTimeout(() => history.push(link))
  }
  const appData = getQueryDataWithoutKey(useQuery(QUERY_GET_APP_INFO))

  useEffect(() => {
    const updatedSelections = students?.length === 1 ? [students[0]] : []
    setSelections(updatedSelections)
  }, [students])

  if (loading) return <Spinner center />

  if (students || error)
    return (
      <div className="container">
        <h2 className="cb-h3 cb-margin-bottom-24">Results</h2>
        {error ? (
          <Dialog error title="Error retrieving data">
            An error occurred retrieving your search results. Please refresh and try again.
          </Dialog>
        ) : resultsCount ? (
          <>
            <p>
              Your search criteria has produced {total} result{total > 1 ? 's' : ''}.{' '}
              {total > MAX_NUMBER_RETURNED
                ? `Only the first ${MAX_NUMBER_RETURNED} will appear in the results. Please
              further refine your criteria.`
                : null}
            </p>
            <div className="cb-margin-bottom-8 cb-margin-top-16">
              <Dropdown
                title="Actions"
                className="display-inline-block cb-roboto-medium cb-dropdown-flush-left"
                disabled={hasNoSelections}
              >
                <DropdownItem
                  label="View Student Info"
                  onClick={() => setSelectionsAndNavigate(APP_LINKS.studentdetails)}
                  disabled={selectionsCount !== 1}
                />
                <DropdownItem
                  label="View Scores & Orders"
                  onClick={() => setSelectionsAndNavigate(APP_LINKS.viewscores)}
                  disabled={!canNavigateToStudentExperience}
                />
                <DropdownItem
                  label="View Activity Log"
                  onClick={() => setSelectionsAndNavigate(APP_LINKS.activitylog)}
                  disabled={!canNavigateToStudentExperience}
                />
                <DropdownItem
                  label="Compare Students"
                  onClick={() => setSelectionsAndNavigate(APP_LINKS.studentcompare)}
                  disabled={!canNavigationToCompare}
                />
              </Dropdown>
              {hasMultiResults ? (
                <Button
                  naked
                  className="cb-btn-black cb-no-padding cb-margin-left-24"
                  disabled={hasNoSelections}
                  onClick={() => setSelections([])}
                >
                  Deselect all
                </Button>
              ) : null}
            </div>
            <Table data={students} responsive={true} striped={true}>
              <Column
                field="personId"
                className="cb-column-checkbox-only"
                headerComponent={() => <span className="sr-only">Select to Compare</span>}
                component={({ value, row }) => {
                  const { firstName, lastName } = students.find(s => s.personId === value)
                  return (
                    <Checkbox
                      ariaLabel={`Select ${firstName} ${lastName}`}
                      checked={Boolean(selections.find(s => s.personId === value))}
                      className="cb-checkbox-sm cb-margin-top-4"
                      onChange={checked => {
                        setSelections(checked ? [...selections, row] : selections.filter(s => s.personId !== value))
                      }}
                    />
                  )
                }}
              />
              <Column field="loginId" title="Login ID" sortable={hasMultiResults} />
              <Column field="lastName" title="Last Name" sortable={hasMultiResults} />
              <Column field="firstName" title="First Name" sortable={hasMultiResults} />
              <Column field="middleInitial" title="Middle Initial" sortable={hasMultiResults} />
              <Column
                field="birthDate"
                title="DOB"
                sortable={hasMultiResults}
                component={({ value }) => value && formatDate(value, FORMATS.shortdate)}
              />
              <Column field="email" title="Email Address" sortable={hasMultiResults} />
              <Column field="aiCode" title="AI Code" sortable={hasMultiResults} />
              <Column field="state" title="State" sortable={hasMultiResults} />
              <Column
                field="applicationIds"
                title="Mapped Applications"
                component={({ value }) =>
                  // Additional check for when appData is still loading
                  value && appData
                    ? value.map(v =>
                        appData[v] ? (
                          <div key={`${appData[v]}`}>
                            {appData[v]} ({v})
                          </div>
                        ) : null
                      )
                    : null
                }
              />
              <Column
                field="personStatus"
                title="Person Status"
                sortable={hasMultiResults}
                component={({ value }) => (value ? 'Active' : 'Inactive')}
              />
            </Table>
          </>
        ) : (
          <p>There are no students matching that criteria. Please modify your search and try again.</p>
        )}
      </div>
    )

  return null
}

SearchResults.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

export default SearchResults
