import { Redirect, useLocation } from 'react-router-dom'
import { QUERY_PENDING_ORDER } from '../../../services/graphql/queries/queries'
import useOrderOperations from '../../../services/local/order'
import Spinner from '../../_common/spinner/Spinner'
import Dialog from '../../_common/dialog/Dialog'
import { getEcommStatus } from './../../../utils/orders'
import { SENDSCORES_ROUTES, APP_LINKS } from '../../../utils/navigation'
import { useScoreAndAwardQuery } from '../../../services/graphql/utils'
import { GET_ORDER_ERROR, useTrackingError } from '../../../utils/analytics'
import { isDateWithinRange } from '../../../utils/date'
import { usePropertiesQuery } from '../../../services/graphql/utils'

const SendScores = () => {
  const firstRoute = SENDSCORES_ROUTES[0]
  const lastRoute = SENDSCORES_ROUTES[SENDSCORES_ROUTES.length - 1]
  const { setOrder } = useOrderOperations()
  const { pathname } = useLocation()
  const { loading, error, data = [] } = useScoreAndAwardQuery([{ query: QUERY_PENDING_ORDER }])
  const [scores, order = {}] = data
  const { selected, skipPayment, sessionId } = order
  const { step, component: Component } = SENDSCORES_ROUTES.find(r => r.path === pathname) || SENDSCORES_ROUTES[0]
  const isLastRoute = step === lastRoute.step
  const isFirstRoute = step === firstRoute.step
  const hasSelections = selected && selected.length
  const status = getEcommStatus()

  const { data: props = {} } = usePropertiesQuery()
  const downtimeActive = isDateWithinRange(props.downtimeStartDate, props.downtimeEndDate)
  useEffect(() => {
    setOrder()
  }, [])
  
  useTrackingError(error, { message: GET_ORDER_ERROR })
  
  if (downtimeActive) {
    return (
      <div className="container cb-padding-top-24">
        <h1>Score Sends are not Available</h1>
        <hr/>
        <p>The application is undergoing mainteinance.  Sending Scores is not possible at this time.  Please check back later.  </p>
        
        <a href="/view-scores">View Scores</a>
      </div>
    )
  }
  
  // sessionId should always exist in pending order query, if it's not there, setOrder has not finished executing yet.
  if (loading || !sessionId) return <Spinner center className="cb-margin-top-72" />

  if (error)
    return (
      <div className="container">
        <Dialog error title="Error retrieving data">
          An error occurred retrieving your order data. Please try again later.
        </Dialog>
      </div>
    )

  if (scores.hasDataLoaded && !scores.canSendScores) return <Redirect to={APP_LINKS.viewscores} />

  // when payment is success, ecomm will include status=0 query param
  // all other statuses are errors and should be redirected to delivery method page
  // alternatively csrs can select a free score send, in that case we skip the payment page
  if (isLastRoute && status !== '0' && !skipPayment)
    return <Redirect to={{ pathname: APP_LINKS.deliverymethod, search: window.location.search }} />

  if (hasSelections || isFirstRoute)
    return (
      <Component
        {...order}
        scoresAndAwardsByYear={scores.activeScoresAndAwards}
        stepProps={{ steps: lastRoute.step, current: step, label: `Send Scores Stepper - Step ${step}` }}
      />
    )

  if (!hasSelections) return <Redirect to={APP_LINKS.sendscores} />

  return null
}

export default SendScores
