import orderBy from 'lodash/orderBy'
import { isResend } from '../../../utils/orders'

export const TYPE_INSTITUTION = 'diName'
export const TYPE_ORDER = 'orderDate'
export const TYPE_ACTIVITY = 'updateDt'
export const TYPE_STATUS = 'orderStatus'
export const ORDER_OPTIONS = [
  {
    label: 'Institution',
    value: TYPE_INSTITUTION,
  },
  {
    label: 'Order',
    value: TYPE_ORDER,
  },
]

export const SORT_ASC = 'asc'
export const SORT_DESC = 'desc'

export const SORT_DIRECTIONS = [
  { label: 'sorted-ascending', value: SORT_ASC, icon: 'order-asc' },
  { label: 'sorted-descending', value: SORT_DESC, icon: 'order-desc' },
]

export const SORT_OPTIONS = {
  [TYPE_INSTITUTION]: [
    {
      label: 'Sort By: Activity Date',
      value: TYPE_ACTIVITY,
      default: true,
      direction: SORT_DESC,
      sortFunc: (data, dir = SORT_DESC) => orderBy(data, [TYPE_ACTIVITY, TYPE_INSTITUTION], dir),
    },
    {
      label: 'Sort By: Institution',
      value: TYPE_INSTITUTION,
      direction: SORT_ASC,
      sortFunc: (data, dir = SORT_ASC) => orderBy(data, [TYPE_INSTITUTION, TYPE_ACTIVITY], dir),
    },
  ],
  [TYPE_ORDER]: [
    {
      label: 'Sort By: Order Date',
      value: TYPE_ORDER,
      default: true,
      direction: SORT_DESC,
      sortFunc: (data, dir) => orderBy(data, [TYPE_ORDER, d => SORTED_ORDER_STATUS.indexOf(d[TYPE_STATUS])], [dir, SORT_DESC]),
    },
    {
      label: 'Sort By: Order Status',
      value: TYPE_STATUS,
      direction: SORT_ASC,
      sortFunc: (data, dir) => orderBy(data, [d => SORTED_ORDER_STATUS.indexOf(d[TYPE_STATUS]), TYPE_ORDER], [dir, SORT_DESC]),
    },
  ],
}

export const CHANNEL_FREESCORESEND = 'R'
export const CHANNEL_STUDENT = 'W'
export const CHANNEL_CSR = 'C'

export const getChannel = channel => {
  switch (channel) {
    case CHANNEL_STUDENT:
      return 'Online Order'
    case CHANNEL_FREESCORESEND:
      return 'Free Score Send'
    default:
      return 'Customer Service Order'
  }
}

export const isOnlineOrder = channel => channel === CHANNEL_CSR || channel === CHANNEL_STUDENT
export const isFreeScoreSend = channel => channel === CHANNEL_FREESCORESEND

export const ORDER_STATUS_COMPLETE = 'S'
export const ORDER_STATUS_PROCESSING = 'P'
export const ORDER_STATUS_PLACED = 'O'
export const ORDER_STATUS_CANCELED = 'C'

export const getOrderStatus = (status, resend) => {
  const isResendOrder = isResend(resend)
  const addResend = text => `${isResendOrder ? 'Resend' : ''} ${text}`
  switch (status) {
    case ORDER_STATUS_COMPLETE:
      return { title: addResend('Sent'), status: 'Sent' }
    case ORDER_STATUS_CANCELED:
      return { title: addResend('Canceled'), status: addResend('Canceled') }
    case ORDER_STATUS_PLACED:
      return { title: isResendOrder ? 'Resend Requested' : 'Ordered', status: isResendOrder ? 'Resend Requested' : 'Ordered' }
    default:
      // processing, error
      return { title: 'Processing', status: 'Processing' }
  }
}
export const ORDER_STATUS_ICONS = {
  [ORDER_STATUS_COMPLETE]: 'east',
  [ORDER_STATUS_PROCESSING]: 'pending',
  [ORDER_STATUS_PLACED]: 'cart',
  [ORDER_STATUS_CANCELED]: 'x-mark',
}
export const SORTED_ORDER_STATUS = [ORDER_STATUS_PLACED, ORDER_STATUS_CANCELED, ORDER_STATUS_PROCESSING, ORDER_STATUS_COMPLETE]
