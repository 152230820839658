import axios from 'axios'
import getEnvConfig from '../../utils/environment'
import { orderVar } from '../local/order'
import CBData from '@cb/apricot/CBData'

const MIN_CHARACTER_LIMIT = 2
const MAX_SUGGESTIONS = 10
const COUNTRY_OBJ = CBData.countries.reduce((obj, c) => ({ ...obj, [c.iso.toUpperCase()]: c.name }), {})

const stash = {}

export default async term => {
  const { selected: exclusions = [] } = orderVar()

  try {
    const { orgSearchUrl, orgSearchApiKey } = getEnvConfig()
    const key = Number.isInteger(parseInt(term, 10)) && term.length === 4 ? 'dicode' : 'name'
    let results = []

    if (term.length < MIN_CHARACTER_LIMIT) return []

    if (stash[term]) results = stash[term]
    else {
      const { data } = await axios.get(orgSearchUrl, {
        headers: { 'x-api-key': orgSearchApiKey },
        params: { [key]: term, ap_pgm: 'Y', size: MAX_SUGGESTIONS * 10 },
      })
      results = data.hits.hits.reduce(
        (acc, { _source: s }) =>
          s.di_code
            ? [
                ...acc,
                {
                  diCode: s.di_code,
                  diName: s.org_full_name,
                  diCity: s.org_city || '',
                  diState: s.org_state_cd || '',
                  diCountry: COUNTRY_OBJ[s.org_country_iso_cd] || '',
                  isCollege: s.org_type_cd !== '4',
                  creditPolicy:
                    s.apcp.map(a => {
                      const examCode = a.ap_exam_cd,
                        equivalentCourses = a.course_equivalents,
                        awardedCredit = a.credit_awarded,
                        minScore = a.score3 === 'Y' ? 3 : a.score4 === 'Y' ? 4 : 5
                      return {
                        examCode,
                        equivalentCourses,
                        awardedCredit,
                        minScore,
                      }
                    }) || [],
                  policyDescription: s.ap_policy_descr || '',
                  creditAwarded: s.ap_credit_awarded_ind === 'Y',
                  placementAwarded: s.ap_placement_awarded_ind === 'Y',
                  collegeAPUrl: s.ap_url || '',
                },
              ]
            : acc,
        []
      )

      stash[term] = results
    }

    return results.filter(({ diCode }) => !exclusions.find(e => e.diCode === diCode)).slice(0, MAX_SUGGESTIONS)
  } catch (err) {
    return []
  }
}
