import ContentModule from '../../../_common/contentmodule/ContentModule'
import { formatDate, FORMATS, formatYear, isDateWithinRange } from '../../../../utils/date'
import { usePropertiesQuery } from '../../../../services/graphql/utils'

import './scorereleasedate.scss'

const ScoreReleaseDate = () => {
  const { data = {} } = usePropertiesQuery()
  const showModule = isDateWithinRange(data.scoreComingSoonDate, data.scoreReleaseDate)

  return showModule ? (
    <ContentModule
      title="AP Scores"
      link={{
        title: 'Learn More About AP Scores',
        path: 'https://apstudents.collegeboard.org/about-ap-scores',
        light: true,
      }}
      className="cb-white-color cb-margin-bottom-24 apscores-score-release"
    >
      <p>
        Your {formatDate(data.scoreReleaseDate, FORMATS.year)} scores will be available{' '}
        <strong>{formatDate(data.scoreReleaseDate, FORMATS.mediumdate)}</strong>.
      </p>
    </ContentModule>
  ) : null
}

export default ScoreReleaseDate
