import './actionbar.scss'

export const BODY_CLASS = 'apscores-actionbar-open'

const ActionBar = ({ buttons, containerClass = 'col-xs-12' }) => {
  const [LeftButton, RightButton] = buttons

  useEffect(() => {
    document.body.classList.add(BODY_CLASS)
    return () => document.body.classList.remove(BODY_CLASS)
  }, [])

  return (
    <div className="apscores-actionbar cb-box-shadow-top" role="region" aria-label="action bar">
      <div className="container">
        <div className="row">
          <div className={`${containerClass} display-flex justify-content-between`}>
            {LeftButton ? (
              <div className="flex-fill cb-margin-right-8">
                <LeftButton />
              </div>
            ) : null}
            {RightButton ? (
              <div className="flex-fill cb-align-right">
                <RightButton />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.func, PropTypes.null).isRequired,
  containerClass: PropTypes.string,
}

export default ActionBar
