import { Input } from '@cb/apricot-react'
import CBUtils from '@cb/apricot/CBUtils'
import { formatDateToEasternTime, isValidDate, isValidISODateFormat, isValidISOTimeFormat } from '../../../../utils/date'

const DateTimeInput = ({ date, isUTCFormat, onChange, label, disabled, paragraphClassNames, boldDate }) => {
  const [defaultDay = '', defaultTime = ''] = date ? date.toString().split('T') : ''
  const [day, setDay] = useState(defaultDay)
  const [time, setTime] = useState(defaultTime.split('.')[0])
  const [valid, setValid] = useState(date ? isValidDate(date) : true)
  const [fullDate, setFullDate] = useState(date)
  const msgId = CBUtils.uniqueID(5, 'apscores_date_input')
  const errorMsgId = CBUtils.uniqueID(5, 'apscores_date_error')
  const isTimeValid = !time || isValidISOTimeFormat(time)
  const isDayValid = !day || isValidISODateFormat(day)

  useEffect(() => {
    const combinedDate = day || time ? `${day}T${time || '00:00:00'}.000Z` : null
    const isUpdateValid = combinedDate ? isValidDate(combinedDate) && isDayValid && isTimeValid : true
    setFullDate(combinedDate)
    setValid(isUpdateValid)
    onChange(combinedDate, isUpdateValid)
  }, [time, day])

  return (
    <fieldset aria-live="polite">
      <legend className="cb-font-size-small cb-roboto-medium cb-margin-bottom-4">
        {label} {isUTCFormat ? '(UTC)' : null}
      </legend>
      <div className="display-flex justify-content-between">
        <div style={{ width: '48%' }}>
          <Input
            value={day}
            clearable={false}
            placeholder="YYYY-MM-DD"
            onChange={e => setDay(e.target.value)}
            disabled={disabled}
            ariaLabel={`${label} Date`}
            ariaDescribedby={msgId}
            validation={(!valid && isTimeValid) || !isDayValid ? 'error' : 'general'}
            validationMessageId={errorMsgId}
          />
        </div>
        <div style={{ width: '48%' }}>
          <Input
            value={time}
            clearable={false}
            placeholder="hh:mm:ss"
            onChange={e => setTime(e.target.value)}
            disabled={disabled}
            ariaLabel={`${label} Time`}
            ariaDescribedby={msgId}
            validation={!isTimeValid ? 'error' : 'general'}
            validationMessageId={errorMsgId}
          />
        </div>
      </div>
      {!valid ? (
        <p className="cb-input-helper cb-validation-error" id={errorMsgId}>
          Error: Invalid date. Format needs to be "YYYY-MM-DD" for date field and "hh:mm:ss" for time field.
        </p>
      ) : null}
      {isUTCFormat && valid ? (
        <p className={`cb-input-helper ${paragraphClassNames}`} id={msgId}>
          Dates are stored as UTC.{' '}
          {boldDate ? (
            <strong className="cb-roboto-bold">
              {fullDate ? `Eastern timezone conversion: ${formatDateToEasternTime(fullDate)}` : null}
            </strong>
          ) : (
            <>{fullDate ? `Eastern timezone conversion: ${formatDateToEasternTime(fullDate)}` : null}</>
          )}
        </p>
      ) : null}
    </fieldset>
  )
}

DateTimeInput.propTypes = {
  isUTCFormat: PropTypes.bool,
  date: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  paragraphClassNames: PropTypes.string,
  boldDate: PropTypes.bool,
}

export default DateTimeInput
