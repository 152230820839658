import axios from 'axios'
import getEnvConfig from '../../../utils/environment'
import Spinner from '../spinner/Spinner'

let fetching = []
const stash = {}

const fetchContent = async file => {
  const { contentUrl } = getEnvConfig()
  try {
    const { data } = await axios.get(`${contentUrl}/${file}.json`)
    stash[file] = data
    return data
  } catch (err) {
    return {}
  }
}

const ContentLookup = ({ file, type, trigger = true, component: Component, renderLoader = false }) => {
  const [content, setContent] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const isMultiKeys = Array.isArray(type)

  useEffect(() => {
    const getContent = async () => {
      let data = stash[file]
      
      if (!data) {
        setLoading(true)
        // prevent multiple/concurrent requests
        if (fetching.includes(file)) await new Promise(resolve => setTimeout(resolve, 1000))
        else fetching = [...fetching, file]
      data = stash[file] || (await fetchContent(file))
      fetching = fetching.filter(f => f !== file)
      setLoading(false)
    }
    
    const formattedContent = isMultiKeys
    ? Object.keys(data).reduce((obj, key) => (type.includes(key) ? { ...obj, [key]: data[key] } : obj), {})
    : data[type]
    setContent(formattedContent)
    }

    if (trigger) getContent()
  }, [trigger])

  if (renderLoader && loading) return <Spinner center />

  return Component ? (
    <Component content={content} />
  ) : content ? (
    <div dangerouslySetInnerHTML={{ __html: isMultiKeys ? Object.values(content).join(' ') : content }} />
  ) : null
}

ContentLookup.propTypes = {
  file: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  trigger: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  renderLoader: PropTypes.bool,
}

export default ContentLookup
