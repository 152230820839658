import React, { useState } from 'react'
import ProjectedCard from './ProjectedCard'
import SubscoreCard from './SubscoreCard'
import AboutPanel from '../../panels/AboutPanel'
import Card from '../../../../../../_common/card/Card'
import ContentLookup from '../../../../../../_common/request/ContentLookup'

const ScoreAvailable = props => {
  const { exCode, exNameFormatted, score, subScores, adminYear, eventsProjected, eventsInfo } = props
  const panelTriggerId = `score-${adminYear}-${exCode}`
  const bannerClass = `apscores-banner apscores-banner-score apscores-banner-${exCode}`
  const [openAboutPanel, setOpenAboutPanel] = useState(false)

  const LeftColumn = () => (
    <>
      <div className={`apscores-badge apscores-badge-score apscores-badge-score-${score}`}>
        <span className="sr-only">Your Score</span>
        {score}
      </div>
      <ProjectedCard
        border={!subScores}
        events={eventsProjected}
        adminYear={adminYear}
        exCode={exCode}
        exName={exNameFormatted}
      />
      <SubscoreCard subScores={subScores || []} exCode={exCode} exName={exNameFormatted} adminYear={adminYear} />
    </>
  )

  const RightColumn = () => (
    <ContentLookup
      file="scoredescriptions"
      type={score.toString()}
      component={({ content = {} }) => (
        <>
          <p className="apscores-intro" dangerouslySetInnerHTML={{ __html: content.intro }} />
          <p>
            <a
              className="cb-btn cb-btn-black cb-margin-sm-up-right-16 cb-margin-xs-bottom-8"
              href={
                content.link || `https://apstudents.collegeboard.org/getting-credit-placement/search-policies/course/${exCode}`
              }
            >
              {content.cpbutton}
            </a>
            <a id={panelTriggerId} className="cb-padding-xs-top-4 display-xs-block-only cb-font-size-small" href="#" onClick={() => setOpenAboutPanel(true)}>
              About your <span className="sr-only">{exNameFormatted}</span> score
            </a>
          </p>
        </>
      )}
    />
  )

  return (
    <>
      <Card
        type={`score-${exCode}`}
        title={exNameFormatted}
        components={[LeftColumn, RightColumn]}
        classes={[bannerClass]}
        align="right"
        testId={`score-${exNameFormatted.replaceAll(' ', '_')}-20${adminYear}-${score}`}
      />
      <AboutPanel
        bannerClass={bannerClass}
        panelTriggerId={panelTriggerId}
        title={exNameFormatted}
        score={score}
        subScores={subScores}
        eventsProjected={eventsProjected}
        eventsInfo={eventsInfo}
        exCode={exCode}
        adminYear={adminYear}
        open={openAboutPanel}
        onClose={() => setOpenAboutPanel(false)}
      />
    </>
  )
}

ScoreAvailable.propTypes = {
  exCode: PropTypes.string.isRequired,
  exNameFormatted: PropTypes.string.isRequired,
  exEvents: PropTypes.array,
  score: PropTypes.number.isRequired,
  subScores: PropTypes.array,
  adminYear: PropTypes.string.isRequired,
}

export default ScoreAvailable
