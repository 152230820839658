import set from 'lodash/set'
import get from 'lodash/get'
import uniq from 'lodash/uniq'

export const SHOW_EVENT = 'show.apscores'
export const HIDE_EVENT = 'hide.apscores'

export const getContainers = container =>
  container ? container.split(', ').reduce((acc, c) => [...acc, ...document.querySelectorAll(c)], []) : []

export const APP_CONFIG = 'apscores.app'

export const getAppConfig = (attribute = APP_CONFIG) => get(window, attribute)

export const setAppConfig = (options, attribute = APP_CONFIG) => set(window, attribute, options)

export const getQueryParams = () => {
  const params = window.location.href.split('?')
  const test = params.slice(1, params.length).reduce((acc, param) => {
    const values = param.split('&').reduce((acc, p) => {
      const [key, value] = p.split('=')
      return key ? { ...acc, [key]: value } : acc
    }, {})
    return { ...acc, ...values }
  }, {})
  return test
}

export const convertArrayToPrettyString = (arr, dedup = true, sort = true) => {
  const processed = dedup ? uniq(arr) : arr
  const sorted = sort ? processed.sort() : processed
  const length = sorted.length
  return length === 0
    ? ''
    : length === 1
    ? sorted[0]
    : `${sorted.slice(0, length - 1).join(', ')}${length > 2 ? ', ' : ''} and ${sorted[length - 1]}`
}

export const convertUpperCaseToTitleCase = text =>
  text
    ? text
        .split(' ')
        .reduce((final, word) => `${final}${word.substring(0, 1)}${word.length > 1 ? word.substring(1).toLowerCase() : ''} `, '')
    : ''
