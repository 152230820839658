import { Checkbox } from '@cb/apricot-react'
import Button from '../../_common/button/Button'
import { MUTATION_ACCEPT_TERMS } from '../../../services/graphql/mutations/mutations'
import { useMutation } from '@apollo/client'
import { getQueryDataWithoutKey, usePropertiesQuery } from '../../../services/graphql/utils'
import { APP_LINKS } from '../../../utils/navigation'
import { useHistory } from 'react-router-dom'
import Layout from '../../_common/layout/Layout'
import { LEFT_COLUMN_CLASS } from '../../../utils/constants'
import { TERMS_ERROR, useTrackingError } from '../../../utils/analytics'

const TermsAndConditions = () => {
  const [acceptTC, { data, loading, error }] = useMutation(MUTATION_ACCEPT_TERMS, {
    update(cache, { data: acceptTermsAndConditions }) {
      cache.modify({
        fields: {
          getTermsAndConditions() {
            return acceptTermsAndConditions
          },
        },
      })
    },
  })
  const { data: props, loading: propsLoading, error: propsError } = usePropertiesQuery()
  const [checkBoxState, setCheckboxState] = useState(false)
  const history = useHistory()
  const terms = getQueryDataWithoutKey({ data })
  const hasError = error || propsError

  useEffect(() => {
    if (terms) history.push(APP_LINKS.viewscores)
  }, [terms])

  useTrackingError(error, { message: TERMS_ERROR })

  return (
    <Layout
      loaded={!!props}
      loading={propsLoading}
      error={hasError ? 'An error occurred saving your acceptance of terms and conditions. Please try again later.' : null}
      useNav={false}
    >
      <div className={LEFT_COLUMN_CLASS}>
        <h2 className="cb-h3 cb-margin-top-16">Terms And Conditions for AP Scores</h2>
        <p className="cb-margin-top-16 cb-margin-bottom-16">
          By accessing your AP scores online, you acknowledge that you are bound by the{' '}
          <a
            className="cb-blue2-color cb-color"
            href="https://apstudents.collegeboard.org/ap-2020-2021/about-2021-ap-exams/terms-conditions"
            target="_blank"
            title="AP Exam Terms and Conditions (Opens in a new window)"
          >
            AP Exam Terms and Conditions
          </a>{' '}
          and{' '}
          <a
            className="cb-blue2-color cb-color"
            href="https://privacy.collegeboard.org"
            target="_blank"
            title="College Board's Privacy Policies (Opens in a new window)"
          >
            College Board's Privacy Policies
          </a>
          . The score report you access on this portal cannot be used as an official score report, which some schools may require
          for credit awarding purposes.
        </p>
        <Checkbox
          className="cb-margin-top-32 cb-wrap"
          label="I have read and accept the terms of service"
          ariaLabel="Terms and Conditions Checkbox"
          onChange={checked => {
            setCheckboxState(checked)
          }}
        />
        <div className="cb-margin-top-24 cb-align-right">
          <Button
            title="Continue"
            primary
            disabled={!checkBoxState || loading}
            loading={loading}
            onClick={() => {
              acceptTC({ variables: { version: props.termsAndConditionVersion } })
            }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default TermsAndConditions
