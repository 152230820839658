import { Modal, Checkbox } from '@cb/apricot-react'
import Button from '../../../../_common/button/Button'
import { getStorageItem, setStorageItem, removeStorageItem } from '../../../../../utils/storage'
import { usePropertiesQuery } from '../../../../../services/graphql/utils'
import useOrderOperations from '../../../../../services/local/order'

import './intromodal.scss'

export const STORAGE_KEY = 'introSendScores'

const IntroModal = ({ welcomeViewed }) => {
  const [open, setOpen] = useState(false)
  const { updateOrder } = useOrderOperations()
  const close = () => {
    setOpen(false)
    updateOrder({ welcomeViewed: true })
  }

  useEffect(() => {
    const show = getStorageItem(STORAGE_KEY)
    setOpen(!(show || welcomeViewed))
  }, [])

  const Footer = () => (
    <div className="display-flex flex-grow-1 flex-xs-column-reverse-only">
      <div className="align-self-center">
        <Checkbox
          label="Don't show this again"
          onChange={checked => {
            if (checked) setStorageItem(STORAGE_KEY, 'Y')
            else removeStorageItem(STORAGE_KEY)
          }}
        />
      </div>
      <div className="cb-align-right cb-align-xs-center flex-grow-1 cb-margin-xs-bottom-16">
        <Button primary title="Send AP Scores" onClick={close} />
      </div>
    </div>
  )

  return (
    <Modal title="Get Ready to Send AP Scores" className="apscores-intro-modal" open={open} onClose={close} footer={<Footer />}>
      <ul className="cb-padding-left-24 cb-gray1-color">
        <li className="cb-margin-bottom-24 cb-margin-xs-bottom-16">
          Decide on which college or scholarship program you want to send your scores to
        </li>
        <li>Get your credit card ready</li>
      </ul>
    </Modal>
  )
}

IntroModal.propTypes = {
  welcomeViewed: PropTypes.bool,
}

export default IntroModal
