import { makeVar } from '@apollo/client'
import { getAuthSession, getTrackingUserId, isStudent } from '../../utils/auth'
import { getQueryParams } from '../../utils/app'
import { getSessionJSON, setSessionJSON } from '../../utils/storage'
import { APP_LINKS, ROUTE_BASENAME } from '../../utils/navigation'
import { isDateCurrent } from '../../utils/date'

const MAX_SELECTIONS = 99
export const STORAGE_KEY = 'order'
export const ORDER_PARAM = 'activeOrder'
export const orderVar = makeVar({})

// ORDER DATA
// welcomeViewed - step 1, has viewed intro modal, should appear only once per login session
// selected - step 1
// previous - step 1, consists of removed or cleared selections
// orderDate - step 3
// eOrderId - step 3, post pending order request
// skipPayment - step 3, free score send flag

const useOrderOperations = () => {
  const { sessionId } = getAuthSession()

  const disableDiSelection = () => {
    const { selected = [] } = orderVar()
    return selected.length >= MAX_SELECTIONS
  }

  const setOrder = () => {
    const { [ORDER_PARAM]: activeOrder } = getQueryParams()
    const isActiveOrder = activeOrder === 'true'
    const orderFromStorage = getSessionJSON(STORAGE_KEY)
    const shouldStartNewOrder = orderFromStorage.complete || !isDateCurrent(orderFromStorage.orderDate)
    const order =
      shouldStartNewOrder || (orderFromStorage.sessionId !== sessionId && !isActiveOrder)
        ? { sessionId }
        : { ...orderFromStorage, sessionId }

    orderVar(order)
    setSessionJSON(STORAGE_KEY, order)
  }

  const updateOrder = updates => {
    const cachedOrder = orderVar()
    const updatedOrder = { ...cachedOrder, ...updates }
    orderVar(updatedOrder)
    setSessionJSON(STORAGE_KEY, updatedOrder)
  }

  const addSelectedDi = (addition = {}) => {
    const { selected = [], previous = [] } = orderVar()
    const exists = selected.find(s => s.diCode === addition.diCode)
    !exists &&
      addition.diCode &&
      updateOrder({
        selected: [...selected, addition],
        previous: previous.filter(s => s.diCode === addition.diCode),
      })
  }

  const removeSelectedDi = (removal = {}) => {
    const { selected = [], previous = [] } = orderVar()
    removal.diCode &&
      updateOrder({
        selected: selected.filter(s => s.diCode !== removal.diCode),
        previous: [...previous, removal],
      })
  }

  const clearDiSelections = () => {
    const { selected = [], previous = [] } = orderVar()
    updateOrder({
      selected: [],
      previous: [...selected, ...previous],
      skipPayment: false,
    })
  }

  const updateSelectedDi = (diCode, updates) => {
    const { selected = [] } = orderVar()
    let updated = [...selected]
    const index = updated.findIndex(s => s.diCode === diCode)
    updated[index] = { ...updated[index], ...updates }
    updateOrder({ selected: updated })
  }

  const prepareOrderData = () => {
    const { selected = [] } = orderVar()
    return {
      // isCollege and other attributes arent apart of input for request
      diCodes: selected.map(
        ({ isCollege, creditPolicy, policyDescription, creditAwarded, placementAwarded, collegeAPUrl, ...rest }) => rest
      ),
      channel: isStudent() ? 'W' : 'C',
    }
  }

  const preparePendingOrderData = () => {
    return {
      order: prepareOrderData(),
      returnUrl: `${window.location.origin}${ROUTE_BASENAME}${APP_LINKS.completeorder}?${ORDER_PARAM}=true`,
    }
  }

  const prepareConfirmOrderData = skipPayment => {
    const { eOrderId } = orderVar()
    const savedPendingOrderData = skipPayment ? {} : { eOrderId }
    return {
      order: { ...prepareOrderData(), ...savedPendingOrderData },
      userId: getTrackingUserId(),
    }
  }

  return {
    setOrder,
    updateOrder,
    clearDiSelections,
    disableDiSelection,
    addSelectedDi,
    removeSelectedDi,
    updateSelectedDi,
    preparePendingOrderData,
    prepareConfirmOrderData,
  }
}

export default useOrderOperations
