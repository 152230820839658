import ContentModule from '../../../_common/contentmodule/ContentModule'
import { usePropertiesQuery, useScoreAndAwardQuery } from '../../../../services/graphql/utils'
import { formatDate, FORMATS } from '../../../../utils/date'
import {APP_LINKS} from '../../../../utils/navigation'

const MissingScoresModule = () => {
  const { data: props = {} } = usePropertiesQuery()
  const { data: scores = {} } = useScoreAndAwardQuery()
  const showModule = scores.hasActiveData || !scores.hasArchiveData

  return showModule ? (
    <ContentModule title="Missing Scores?" link={{ path: APP_LINKS.help, title: 'Get Help', button: true }}>
      <p className="cb-roboto">
        If you took an AP Exam in 2018 or after but don't see it listed,{' '}
        <a href={`/view-scores${APP_LINKS.faqs}?category=missinginprogressscores`} className="cb-black1-color" title="Missing Scores FAQs">
          check possible reasons why.
        </a>
      </p>
      <p>
        If you see your course listed but the score is in progress, we are processing your scores.{' '}
        {props.missingScoresDate
          ?
          <>
            If you don't receive them by {formatDate(props.missingScoresDate, FORMATS.monthday)}, {' '}
            <a href="https://collegeboard.tfaforms.net/193" title="Contact Form (Opens in a new window)" target="_blank" className="cb-black1-color">contact AP Services for Students.</a>
          </>
          : null}
      </p>
    </ContentModule>
  ) : null
}

export default MissingScoresModule
