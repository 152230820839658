import { Modal } from '@cb/apricot-react'
import ContentLookup from '../../../../../../_common/request/ContentLookup'
import Card from '../../../../../../_common/card/Card'
import { appendEventCodes } from '../../../../../../../utils/event-codes'

const ScoreHold = props => {
  const { exCode, exNameFormatted, adminYear, exEvent, holdEventsByPrecedence, eventsInfo } = props
  const modalTriggerId = `holdModal-${adminYear}-${exCode}`
  const bannerClass = `apscores-banner apscores-banner-delay apscores-banner-${exCode}`
  const eventCode = exEvent.eventCode.toString()

  return (
    <ContentLookup
      file="eventsHold"
      type={[eventCode, 'default']}
      component={({ content = {} }) => {
        const { desc, header, more } = content[eventCode] || {}
        const { desc: defaultDesc, header: defaultHeader, more: defaultMore } = content.default || {}
        const infoEventCodesAllowed = [31, 32]
        const infoEventCodes =  eventsInfo?.filter(event => infoEventCodesAllowed.includes(event.eventCode)).map(({eventCode}) => eventCode)

        const descContent = appendEventCodes(
          desc === 'default' && defaultDesc ? defaultDesc : desc || exEvent.eventDesc,
          holdEventsByPrecedence, infoEventCodes
        )
        const moreContent = appendEventCodes(more === 'default' && defaultMore ? defaultMore : more, holdEventsByPrecedence, infoEventCodes)
        const modalTitle = header === 'default' && defaultHeader ? defaultHeader : header;

        const LeftColumn = () => <div className={`apscores-delay-badge`} />
        const RightColumn = () => (
          <>
            <p
              className="apscores-intro cb-margin-sm-up-bottom-16 cb-padding-3xl-right-32"
              dangerouslySetInnerHTML={{ __html: descContent }}
            />
            {moreContent ? (
              <a href="#" id={modalTriggerId} className="cb-padding-xs-top-4 display-xs-block-only cb-font-size-small">
                Learn more !<span className="sr-only">about your {exNameFormatted} score</span>
              </a>
            ) : null}
          </>
        )

        return (
          <>
            <Card
              type={`score-${exCode}`}
              title={exNameFormatted}
              components={[LeftColumn, RightColumn]}
              classes={[bannerClass]}
              align="right"
              testId={`score-${exNameFormatted.replaceAll(' ', '_')}-20${adminYear}-hold`}
            />
            {moreContent ? (
              <Modal title={modalTitle} trigger={modalTriggerId} cancelButtonText="Close" analytics={true} analyticsTitle={modalTitle} analyticsOnClose={false}>
                <div dangerouslySetInnerHTML={{ __html: moreContent }} />
              </Modal>
            ) : null}
          </>
        )
      }}
    />
  )
}

ScoreHold.propTypes = {
  exCode: PropTypes.string.isRequired,
  exNameFormatted: PropTypes.string.isRequired,
  adminYear: PropTypes.string.isRequired,
  exEvent: PropTypes.object,
}

export default ScoreHold
