import { getTotalPrice } from '../../../../../utils/orders'

const OrderSummary = ({ selected, standardPrice, deliveryDateRange }) => {
  return (
    <div id="apscores-order-summary">
      <h3 className="cb-roboto cb-font-weight-xs-medium cb-margin-top-8 cb-margin-bottom-24">Recipients & Order Total</h3>
      <div className="cb-border cb-gray3-border cb-padding-24 cb-padding-xs-16">
        {selected.map(s => (
          <div key={s.diCode} className="cb-paragraph1-res cb-margin-bottom-16">
            <div className="display-flex justify-content-between cb-font-weight-xs-medium">
              <div>{s.diName}</div>
              <div>${standardPrice}</div>
            </div>
          </div>
        ))}
        <div className="cb-h3 cb-roboto cb-font-weight-xs-medium cb-border-top cb-gray3-border display-flex justify-content-between cb-margin-top-24 cb-padding-top-24 cb-margin-xs-top-16 cb-padding-xs-top-16">
          <div>Total</div>
          <div>${getTotalPrice(selected, standardPrice)}</div>
        </div>
        <div className="cb-h3 cb-roboto cb-font-weight-xs-medium cb-border-top cb-gray3-border display-flex justify-content-end cb-margin-top-24 cb-padding-top-24 cb-margin-xs-top-16 cb-padding-xs-top-16">
          <div className="cb-roboto-light cb-font-weight-xs-regular">
            <div className="cb-paragraph1-res">
                <div className="cb-roboto-light cb-font-weight-xs-regular">
                  {deliveryDateRange ? (
                    <>
                      Estimated delivery: <div className="cb-no-wrap display-inline">{deliveryDateRange}</div>
                    </>
                  ) : (
                    'Not currently available'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

OrderSummary.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
  standardPrice: PropTypes.number.isRequired,
}

export default OrderSummary
