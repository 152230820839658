import ContentModule from '../../../_common/contentmodule/ContentModule'
import ButtonLink from '../../../_common/links/ButtonLink'
import { convertArrayToPrettyString } from '../../../../utils/app'
import { useScoreAndAwardQuery } from '../../../../services/graphql/utils'

const MORE_INFO_URL = 'https://apstudents.collegeboard.org/sending-scores/send-or-get-archived-scores'
const MORE_INFO_TITLE = 'Learn More'

const ArchiveFormLink = () => (
  <a
    href="https://apstudents.collegeboard.org/ap/pdf/ap-archived-score-request-form.pdf"
    className="cb-black1-color"
    title="AP Archived Scores Request Form (Opens in a new window)"
    target="_blank"
  >
    AP Archived Scores Request Form
  </a>
)

const ARCHIVED_SCORES_PARA1 =
  `If you took your last AP Exam before 2018, your AP scores are no longer viewable online and score reports can't be ordered on this site. To send these scores to a college, university, or scholarship program, or to receive a personal copy follow the steps below.`

const ARCHIVED_LIST_INFO = [
  {
    listItem: (
      <>
        Download and fill out the <ArchiveFormLink /> (pdf/463KB).
      </>
    ),
    text:
      'Fill out the form with your information, including the name and code of each institution you want to send your scores to and sign the form.',
  },
  {
    listItem: 'Have a credit card ready.',
    text: 'The fee is $25 per report.',
  },
  {
    listItem: 'Send the form and payment.',
    text:
      'Reports are mailed via first-class mail within 15 business days of receipt of the request. You’ll also get a confirmation copy of your archived AP scores within 15 business days.',
  },
  {
    listItem: `Note: Score withholds, and cancellations can't be applied to archived scores.`,
  },
]

const ArchivedScores = ({ callout }) => {
  const { data = {} } = useScoreAndAwardQuery()
  const archiveString = convertArrayToPrettyString(data.archivedYears, true, false)

  if (!data.hasArchiveData) return null

  if (callout)
    return (
      <div className="cb-white-bg cb-padding-24 cb-border cb-margin-bottom-48 cb-margin-xs-bottom-24">
        <h4>Your {archiveString} scores can't be sent online</h4>
        <p className="cb-margin-bottom-16 cb-margin-top-16 cb-font-size-small">
          These scores are archived. To request that your archived scores be sent to a college, university, or scholarship
          program, or to request a personal copy of your scores, complete and submit the <ArchiveFormLink />.
        </p>
        <ButtonLink black path={MORE_INFO_URL} title={MORE_INFO_TITLE} />
      </div>
    )

  return (
    <ContentModule
      title={`Your ${archiveString} Scores Are Archived`}
      titleClassName="cb-roboto"
      linkClassName="cb-margin-top-48"
      link={{
        path: MORE_INFO_URL,
        title: MORE_INFO_TITLE,
        button: true,
      }}
      className="cb-no-border cb-white-bg"
    >
      <p className="cb-roboto-light cb-paragraph1-res">{ARCHIVED_SCORES_PARA1}</p>
      <ul className="cb-padding-top-16">
        {ARCHIVED_LIST_INFO.map((item, index) => (
          <li className="cb-padding-top-16" key={index}>
            {item.listItem}
            {item.text ? <p className="cb-gray1-color cb-font-size-regular cb-roboto">{item.text}</p> : null}
          </li>
        ))}
      </ul>
    </ContentModule>
  )
}

ArchivedScores.propTypes = {
  callout: PropTypes.bool,
}

export default ArchivedScores
