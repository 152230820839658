import mem from 'mem'
import orderBy from 'lodash/orderBy'

export const READ_ONLY = 'ReadOnly'

// mem only uses the first property to cache responses, need custom cacheKey function to ensure isEdit is included in caching
// For edit scenario only: sequence is required to show on page, editable = 'N' fields will be readonly
export const transformApplicationProperties = mem(
  (props, isEdit) => {
    const orderedProps = orderBy(props, 'sequence')
    return orderedProps?.reduce((acc, p) => {
      if (isEdit && !p.sequence) return acc
      const { propName, propValue } = p
      const formattedPropValue = /price/i.test(propName) ? Number(propValue) : null
      const isPropEditable = p.editable === 'Y'
      const name = isEdit ? `${propName}${!isPropEditable ? READ_ONLY : ''}` : propName
      return { ...acc, [name]: formattedPropValue || propValue }
    }, {})
  },
  {
    cacheKey: args => args.join(','),
  }
)
