import { gql } from '@apollo/client'

export const MUTATION_PENDING_ORDER = gql`
  mutation createPendingOrder($partitionKey: String, $order: PendingOrderInput!, $returnUrl: String!) {
    createPendingOrder(partitionKey: $partitionKey, order: $order, returnUrl: $returnUrl) {
      channel
      eOrderId
      paymentUrl
    }
  }
`

const FRAGMENT_CONFIRM_ORDER = gql`
  fragment fragmentConfirmOrder on OrderConfirmation {
    order {
      diCodes {
        diCode
        diName
        diCity
        diCountry
        diState
        sentDate
        updateDt
      }
      orderDate
      orderId
      eOrderId
      orderTotal
      lastFourDigitsOfCard
      creditCardType
      resend
      channel
    }
    student {
      apUid
      address1
      address2
      address3
      city
      countryName
      firstName
      intlPostalCode
      lastName
      province
      state
      zip
      email
    }
  }
`

export const MUTATION_CONFIRM_ORDER = gql`
  mutation confirmOrder($partitionKey: String, $order: ConfirmOrderInput!, $userId: String) {
    confirmOrder(partitionKey: $partitionKey, order: $order, userId: $userId) {
      ...fragmentConfirmOrder
    }
  }
  ${FRAGMENT_CONFIRM_ORDER}
`

export const MUTATION_FREE_ORDER = gql`
  mutation freeScoreOrder($partitionKey: String, $order: PendingOrderInput!, $userId: String) {
    freeScoreOrder(partitionKey: $partitionKey, order: $order, userId: $userId) {
      ...fragmentConfirmOrder
    }
  }
  ${FRAGMENT_CONFIRM_ORDER}
`

export const MUTATION_RESEND_ORDER = gql`
  mutation resendOrder($partitionKey: String, $diCode: String, $userId: String) {
    resendOrder(partitionKey: $partitionKey, diCode: $diCode, userId: $userId) {
      ...fragmentConfirmOrder
    }
  }
  ${FRAGMENT_CONFIRM_ORDER}
`

export const MUTATION_CANCEL_ORDER = gql`
  mutation cancelOrder($partitionKey: String, $orderId: Int, $userId: String) {
    cancelOrder(partitionKey: $partitionKey, orderId: $orderId, userId: $userId) {
      ...fragmentConfirmOrder
    }
  }
  ${FRAGMENT_CONFIRM_ORDER}
`

export const MUTATION_UPDATE_PROPERTIES = gql`
  mutation updateProperties($properties: UpdatePropertiesInput) {
    updateProperties(properties: $properties) {
      propName
      propValue
      editable
      sequence
    }
  }
`

export const MUTATION_TRACK_ACTIVITY = gql`
  mutation trackActivity($partitionKey: String, $activity: [ActivityMessage]) {
    trackActivity(partitionKey: $partitionKey, activity: $activity)
  }
`

export const MUTATION_ACCEPT_TERMS = gql`
  mutation acceptTermsAndConditions($version: String!) {
    acceptTermsAndConditions(version: $version)
  }
`
