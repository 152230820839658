import orderBy from 'lodash/orderBy'
import { formatYear, getDateWithoutTime } from '../../../utils/date'

const formatDiName = value => value || 'Unknown'

export default {
  Order: {
    fields: {
      date(value, { readField }) {
        return readField('orderDate')
      },
    },
  },
  DiCode: {
    fields: {
      diName(value) {
        return formatDiName(value)
      },
      sentDate(value) {
        return getDateWithoutTime(value)
      },
    },
  },
  Sendscore: {
    fields: {
      exams(value) {
        return orderBy(value, 'exName', 'asc')
      },
      date(value, { readField }) {
        return readField('sentDate')
      },
      diName(value) {
        return formatDiName(value)
      },
      sentDate(value) {
        return getDateWithoutTime(value)
      },
    },
  },
  SendscoreExam: {
    fields: {
      exNameFormatted(value, { readField }) {
        return `AP ${readField('exName')}`
      },
      displayYear(value, { readField }) {
        return formatYear(readField('adminYear'))
      },
    },
  },
}
