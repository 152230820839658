// search by id fields
export const BYID_FIELD_AP = 'apUid' // default
export const BYID_FIELD_PERSON = 'personId'
export const BYID_FIELD_LOGIN = 'loginId'
export const BYID_FIELD_CANDIDATE = 'candidateId'
export const BYID_FIELD_SSD = 'ssdId'
export const BYID_FIELDS = [BYID_FIELD_AP, BYID_FIELD_PERSON, BYID_FIELD_LOGIN, BYID_FIELD_CANDIDATE, BYID_FIELD_SSD]

// search by reg fields
export const BYREG_FIELD_NUM = 'apRegNumber'
export const BYREG_FIELD_ADMINYR = 'adminYear'

// search by person fields
export const BYPERSON_FIELD_FN = 'firstName'
export const BYPERSON_FIELD_LN = 'lastName'
export const BYPERSON_FIELD_DOB = 'birthDate'
export const BYPERSON_FIELD_EMAIL = 'email'
export const BYPERSON_FIELD_AI = 'aiCode'
export const BYPERSON_FIELD_STATE = 'state'
export const BYPERSON_FIELD_APP = 'siloIds'
