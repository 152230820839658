import './creditpolicy.scss'

const CreditPolicy = () => (
  <div className="cb-padding-sm-up-48 cb-padding-xs-32 cb-border cb-border-1 credit-policy">
    <h3 className="cb-margin-bottom-24 cb-roboto">Credit Policy Search</h3>
    <p className="cb-margin-bottom-24 cb-roboto-light cb-font-weight-xs-regular">
      Find colleges that offer credit or placement for AP scores
    </p>
    <a href="https://apstudents.collegeboard.org/getting-credit-placement/search-policies" className="cb-btn cb-btn-black">
      Find College Credit
    </a>
  </div>
)

export default CreditPolicy
