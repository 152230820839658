import './card.scss'

const Card = ({ title, classes = [], components, testId }) => {
  const [LeftColumn, RightColumn] = components
  const [header, body] = classes

  return (
    <>
      <div className={`apscores-card cb-card cb-no-padding`} data-testid={testId}>
        <div className={`apscores-card-heading ${header || ''} display-flex`}>
          <h4 className="align-self-center">{title}</h4>
        </div>
        <div className={`apscores-card-body ${body || ''} display-flex`}>
          <div className={`apscores-card-col-left display-flex`}>
            <div className="align-self-center">
              <LeftColumn />
            </div>
          </div>
          <div
            className={`apscores-card-col-right display-flex cb-border-left cb-no-border-xs cb-align-xs-center cb-margin-xs-top-16`}
          >
            <div className="align-self-center">
              <RightColumn />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  components: PropTypes.arrayOf(PropTypes.func).isRequired,
  testId: PropTypes.string,
  classes: PropTypes.arrayOf(PropTypes.string),
}

export default Card
