import './togglebuttons.scss'
import { Glyph } from '@cb/apricot-react'

// Apricot has ToggleButtonGroup/ToggleButton however...
// Apricot 4.x does not support longer labels, max-width: 48px
// Apricot 3.x automatically transforms html to be not compatible with 4.x styles on load
const ToggleButtons = ({ buttons, name, onChange, defaultValue }) => {
  const [selected, setSelected] = useState(defaultValue)

  useEffect(() => {
    setSelected(defaultValue)
  }, [defaultValue])

  const updateSelected = e => {
    onChange(e.target.value)
    setSelected(e.target.value)
  }

  return (
    <div className="apscores-toggle-buttons display-flex justify-content-between cb-margin-bottom-8">
      {buttons.map(b => {
        const isSelected = selected === b.value
        return (
          <label
            key={b.value}
            className={`flex-grow-1 display-flex align-items-center ${isSelected ? 'apscores-toggle-button-selected' : ''}`}
          >
            <input type="radio" name={name} aria-label={b.label} value={b.value} checked={isSelected} onChange={updateSelected} />
            {b.icon ? <Glyph name={b.icon} decorative /> : <span className="apscores-toggle-button-label">{b.label}</span>}
          </label>
        )
      })}
    </div>
  )
}

ToggleButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
}

export default ToggleButtons
