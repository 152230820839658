import { ErrorNotification, Modal } from '@cb/apricot-react'
import { useEffect, useState } from 'react'
import { MUTATION_RESEND_ORDER } from '../../../../../services/graphql/mutations/mutations'
import { getQueryDataWithoutKey, useCustomMutation, useScoreAndAwardQuery } from '../../../../../services/graphql/utils'
import { canModifyOrder, getTrackingUserId } from '../../../../../utils/auth'
import Button from '../../../../_common/button/Button'
import { ORDER_STATUS_PLACED } from '../../utils'
import fetchDiSearchResults from '../../../../../services/rest/disearch'

export const updateResendScores = (existing, response) => {
  const { order } = getQueryDataWithoutKey(response)
  return {
    ...existing,
    orders: [
      ...existing.orders,
      {
        ...order,
        orderStatus: ORDER_STATUS_PLACED,
        dis: order.diCodes.map(d => ({ ...d, orderStatus: ORDER_STATUS_PLACED, updateDt: order.orderDate })),
      },
    ],
  }
}

const ResendScores = ({ diCode, diName, hasScoresPreviouslySent }) => {
  const [open, setOpen] = useState(false)
  const [isDiClosed, setIsDiClosed] = useState()
  const { data: scoresData } = useScoreAndAwardQuery()
  const { hasActiveScore, canSendScores } = scoresData
  const [resend, { data, loading, error }] = useCustomMutation(MUTATION_RESEND_ORDER, {
    update(cache, response) {
      cache.modify({
        fields: {
          getOrdersAndSendscores(existing = {}) {
            return updateResendScores(existing, response)
          },
        },
      })
    },
  })

  useEffect(() => {
    const checkDiStatus = async () => {
      const diInfo = await fetchDiSearchResults(diCode)
      setIsDiClosed(diInfo.length === 0)
    }
    checkDiStatus()
  }, [diCode])

  useEffect(() => {
    if (data) {
      setOpen(false)
      // in default sort, the institution card will automatically be ordered to the top
      // resetting the screen accordingly
      window.scrollTo(0, 0)
    }
  }, [data])

  return canModifyOrder() && hasScoresPreviouslySent && hasActiveScore && canSendScores ? (
    <div>
      <Button
        title="Resend Scores"
        ariaLabel={`Resend Scores to ${diName}`}
        className="cb-btn-yellow cb-no-wrap"
        onClick={() => setOpen(true)}
        disabled={open}
      />
      <Modal
        title="Resend Scores"
        footer={
          isDiClosed ? (
            <div className="cb-xs-align-center">
              <Button primary disabled={loading} onClick={() => setOpen(false)}>
                OK
              </Button>
            </div>
          ) : (
            <div className="cb-xs-align-center">
              <Button naked disabled={loading} onClick={() => setOpen(false)}>
                No, Don't Resend Scores
              </Button>
              <Button
                primary
                loading={loading}
                onClick={() => {
                  resend({ diCode, userId: getTrackingUserId() })
                }}
              >
                Yes, Resend Scores
              </Button>
            </div>
          )
        }
        onClose={() => setOpen(false)}
        open={open}
      >
        {isDiClosed ? (
          <p>
            This action cannot be completed as this institution is closed. Please have the student verify with their institution
            where to re-send their scores.
          </p>
        ) : (
          <p>
            Are you sure you want to resend scores? Note: This will send all reportable scores to <strong>{diName}</strong>.
          </p>
        )}
        {error ? <ErrorNotification title="Error Resending Scores">{error}</ErrorNotification> : null}
      </Modal>
    </div>
  ) : null
}

ResendScores.propTypes = {
  hasScoresPreviouslySent: PropTypes.bool.isRequired,
  diName: PropTypes.string.isRequired,
  diCode: PropTypes.string.isRequired,
}

export default ResendScores
