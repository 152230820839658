import ScoresOrder from '../../_common/ScoresOrder/ScoresOrder'
import ContentLookup from '../../../../_common/request/ContentLookup'
import TooltipPanel from '../../../../_common/panel/TooltipPanel'
import { PANEL_REVIEWORDER } from '../../../../../utils/analytics'

const ReviewScoresOrder = ({ scoresAndAwardsByYear, selected }) => (
  <>
    <h3 className="cb-font-weight-xs-medium cb-roboto cb-margin-bottom-16">AP Scores Order</h3>
    <div className="cb-margin-bottom-24 cb-paragraph1-res">
      <span className="cb-roboto-light cb-font-weight-xs-regular">
        Review the scores that will be sent. All scores available at the time your order is sent will be included
        with your order, with the exception of those canceled or withheld.
      </span>{' '}
      <ContentLookup
        file="orders"
        type="review"
        component={({ content = {} }) => (
          <TooltipPanel
            trigger="review-score-order"
            title="Review Score Order"
            content={content.overviewpanel}
            responsive
            tracking={PANEL_REVIEWORDER}
          />
        )}
      />
    </div>
    <ScoresOrder scoresAndAwardsByYear={scoresAndAwardsByYear} selected={selected} />
  </>
)

ReviewScoresOrder.propTypes = {
  scoresAndAwardsByYear: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ReviewScoresOrder
