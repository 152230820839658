import Band from '../../../_common/band/Band'
import Spinner from '../../../_common/spinner/Spinner'
import Dialog from '../../../_common/dialog/Dialog'
import StudentInformation from '../_common/StudentInformation/StudentInformation'
import OrderDetails from './orderdetails/OrderDetails'
import ButtonLink from '../../../_common/links/ButtonLink'
import RecipientsAndDelivery from './recipientsanddelivery/RecipientsAndDelivery'
import CompleteScoresOrder from './completescoresorder/CompleteScoresOrder'
import useOrderOperations from '../../../../services/local/order'
import { getPriceProperties } from '../../../../utils/orders'
import { MUTATION_CONFIRM_ORDER, MUTATION_FREE_ORDER } from '../../../../services/graphql/mutations/mutations'
import { APP_LINKS } from '../../../../utils/navigation'
import { useCustomMutation, usePropertiesQuery } from '../../../../services/graphql/utils'
import { CONTAINER_THIN_CLASS } from '../../../../utils/constants'
import PDFDownload from '../../../_common/pdfdownload/PDFDownload'
import { COMPLETE_ORDER_ERROR, trackSendScores, useTrackingError } from '../../../../utils/analytics'
import ActionBar from '../../../_common/actionbar/ActionBar'

const CompleteOrder = ({ stepProps, skipPayment, scoresAndAwardsByYear }) => {
  const [confirmOrder, { data, loading, error }] = useCustomMutation(skipPayment ? MUTATION_FREE_ORDER : MUTATION_CONFIRM_ORDER)
  const { data: props = {} } = usePropertiesQuery()
  const priceProperties = getPriceProperties(props, skipPayment, data?.order?.orderDate)
  const { updateOrder, prepareConfirmOrderData } = useOrderOperations()
  const pdfContainerId = 'order-confirmation-pdf-container'
  
  useEffect(() => {
    confirmOrder(prepareConfirmOrderData(skipPayment))
  }, [])
  
  useEffect(() => {
    if (data) {
      updateOrder({ complete: true })
      trackSendScores(scoresAndAwardsByYear, { ...data?.order, skipPayment }, props)
    }
  }, [data])
  
  useTrackingError(error, { message: COMPLETE_ORDER_ERROR })

  if (loading) return <Spinner center className="cb-margin-top-72" />

  if (error)
    return (
      <div className="container cb-margin-top-72">
        <Dialog error title="Error retrieving order details">
          There was an error completing your order. Please try again.
        </Dialog>
      </div>
    )

  if (data)
    return (
      <>
        <Band
          title="Your Order is Complete!"
          subtitle="Send AP Scores"
          containerClass={CONTAINER_THIN_CLASS}
          stepProps={stepProps}
        >
          <p>Your order has been submitted and a confirmation was sent to {data?.student?.email}</p>
          <p className="cb-text-italic cb-padding-bottom-16">
            College Board cannot verify that scores were received by your institution. Contact your institution directly to
            inquire about receipt.
          </p>
          <PDFDownload
            tracking="order-confirmation.pdf"
            buttonTitle="Download This Page!"
            pdfTitle="Send AP Scores"
            filename="order-confirmation.pdf"
            pdfContainerId={pdfContainerId}
          />
        </Band>
        <div className="container" id={pdfContainerId}>
          <div className="row">
            <div className={CONTAINER_THIN_CLASS}>
              <OrderDetails {...data} />
              <RecipientsAndDelivery selected={data?.order?.diCodes} {...priceProperties} {...props} skipPayment={skipPayment}/>
              <CompleteScoresOrder scoresAndAwardsByYear={scoresAndAwardsByYear} selected={data?.order?.diCodes} />
              <StudentInformation {...data?.student} title="Your Information" />
            </div>
          </div>
        </div>
        <ActionBar
          containerClass={CONTAINER_THIN_CLASS}
          buttons={[
            () => (
              <>
                <ButtonLink
                  path={APP_LINKS.pastorders}
                  primary
                  className="cb-no-print cb-margin-right-8 cb-margin-bottom-8"
                  title="View all Score Orders"
                />
                <ButtonLink path={APP_LINKS.viewscores} className="cb-no-print" title="Back to AP Scores" />
              </>
            ),
          ]}
        />
      </>
    )

  return null
}

CompleteOrder.propTypes = {
  scoresAndAwardsByYear: PropTypes.arrayOf(PropTypes.object),
  stepProps: PropTypes.object,
  complete: PropTypes.bool,
}

export default CompleteOrder
