import { Link, useLocation } from 'react-router-dom'
import { useScoreAndAwardQuery } from '../../../../services/graphql/utils'
import { CONTAINER_WIDE_CLASS } from '../../../../utils/constants'
import { STUDENT_NAVIGATION } from '../../../../utils/navigation'

import './navigation.scss'

const Navigation = () => {
  const { pathname } = useLocation()
  const { data } = useScoreAndAwardQuery()

  return (
    <nav className="apscores-nav" data-testid="apscores-nav" aria-label="AP Scores Navigation">
      <div className="container">
        <div className="row">
          <div className={`${CONTAINER_WIDE_CLASS} display-flex flex-wrap`}>
            {STUDENT_NAVIGATION.map((n, i) => {
              const isDisabled = n.linkRequiredReportableScore && !data.canSendScores
              const isActive = n.path === pathname

              return (
                <Link
                  tabIndex={isDisabled ? '-1' : ''}
                  aria-current={isActive}
                  aria-disabled={isDisabled}
                  className={`${isDisabled ? 'cb-disabled' : isActive ? 'apscores-nav-active' : ''} cb-black1-color cb-no-wrap`}
                  key={i}
                  to={n.path}
                >
                  {n.title}
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
