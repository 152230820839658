import ContentLookup from '../../../../../../_common/request/ContentLookup'
import { Glyph } from '@cb/apricot-react'
import { appendEventCodes } from '../../../../../../../utils/event-codes'

const ScoreInformational = ({ events = [] }) => 
  events.map(e => {
    const infoEventCodesAllowed = [31, 32]
    return (!infoEventCodesAllowed.includes(e.eventCode) &&
    (
      <ContentLookup
        file="eventsInformational"
        type={e.eventCode}
        key={e.eventCode}
        component={({ content = {} }) => {
          const contentDesc = appendEventCodes(content.desc || e.eventDesc, [e.eventCode])
          return (
            <div className="cb-border-top cb-padding-24 display-flex">
              <Glyph name="lightbulb" color="gray2" size="xs" decorative />
              <div className="cb-padding-left-16">
                <h5 className="cb-font-size-small">
                  <strong>Exam Note: </strong>
                  <div dangerouslySetInnerHTML={{ __html: contentDesc || e.eventDesc }} />
                </h5>
              </div>
            </div>
          )
        }}
      />
    ))
  })

ScoreInformational.propTypes = { events: PropTypes.array.isRequired }

export default ScoreInformational
