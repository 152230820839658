import { gql } from '@apollo/client'

export const QUERY_SCORES_AWARDS = gql`
  query getViewScoresAndAwards($partitionKey: String) {
    getViewScoresAndAwards(partitionKey: $partitionKey) {
      displayYear @client
      hasAtLeastOneReportableScore @client
      adminYear
      awards {
        isArchived @client
        awCode
        awName
        rowStateInd
        awNameFormatted @client
        sortOrder
      }
      scores {
        isArchived @client
        rowStateInd
        exCode
        exName
        exNameFormatted @client
        score
        eventCancel @client
        eventHold @client
        eventsProjected @client
        eventsInfo @client
        subScores {
          exCode
          exName
          exNameFormatted @client
          score
        }
        exEvents {
          eventCode
          eventDesc
          categoryCode
          actionCode
        }
        cancelEventsByPrecedence @client
        holdEventsByPrecedence @client
      }
    }
  }
`

export const QUERY_PROPERTIES = gql`
  query getProperties {
    getProperties {
      propName
      propValue
      editable
      sequence
    }
  }
`

export const QUERY_SCORE_REPORT_PDF = gql`
  query getScoreReportPDF($partitionKey: String, $date: String) {
    getScoreReportPDF(partitionKey: $partitionKey, date: $date) {
      base64Pdf
    }
  }
`

export const QUERY_AWARDS_PDF = gql`
  query getAwardPDF($partitionKey: String, $adminYear: String) {
    getAwardPDF(partitionKey: $partitionKey, adminYear: $adminYear) {
      base64Pdf
      size
    }
  }
`

export const QUERY_STUDENT_INFO = gql`
  query getStudentInfo($partitionKey: String) {
    getStudentInfo(partitionKey: $partitionKey) {
      apUid
      address1
      address2
      address3
      city
      countryName
      firstName
      middleInitial
      intlPostalCode
      lastName
      province
      state
      zip
      email
    }
  }
`

export const QUERY_ORDER_DETAILS = gql`
  query getOrderDetails($partitionKey: String, $orderId: Int) {
    getOrderDetails(partitionKey: $partitionKey, orderId: $orderId) {
      order {
        amountRefunded
        creditCardType
        diCodes {
          diCode
          diPrice
        }
        lastFourDigitsOfCard
        orderTotal
      }
      student {
        firstName
        middleInitial
        lastName
        apUid
      }
    }
  }
`

export const QUERY_ORDERS_SENDSCORES = gql`
  query getOrdersAndSendscores($partitionKey: String) {
    getOrdersAndSendscores(partitionKey: $partitionKey) {
      orders {
        channel
        orderDate
        date @client
        eOrderId
        orderId
        orderStatus
        resend
        canCancelOrder
        dis {
          sentDate
          diName
          diCode
          diCity
          diState
          diCountry
          orderStatus
          updateDt
        }
      }
      sendscores {
        sentDate
        date @client
        diName
        diCode
        exams {
          exName
          exNameFormatted @client
          exCode
          score
          adminYear
          displayYear @client
          withholdInd
          subscores {
            exName
            exNameFormatted @client
            exCode
            score
          }
        }
      }
    }
  }
`

export const QUERY_WITHHOLDS = gql`
  query getWithholds($partitionKey: String) {
    getWithholds(partitionKey: $partitionKey) {
      diName
      diCode
      adminYear
      displayYear @client
      exCode
      exName
      exNameFormatted @client
      reqDate
    }
  }
`

export const QUERY_PENDING_ORDER = gql`
  query getPendingOrder {
    getPendingOrder @client
  }
`

export const QUERY_SELECTED_STUDENT = gql`
  query getSelectedStudent {
    getSelectedStudent @client
  }
`

export const QUERY_SEARCH_CRITERIA = gql`
  query getSearchCriteria {
    getSearchCriteria @client
  }
`

export const QUERY_SEARCH_STUDENTS = gql`
  query getStudentResults($personSearchCriteria: StudentResultInput!) {
    getStudentResults(personSearchCriteria: $personSearchCriteria) {
      total
      students {
        personId
        loginId
        firstName
        lastName
        middleInitial
        birthDate
        email
        aiCode
        city
        state
        zip
        address1
        address2
        address3
        applicationIds
        personStatus
        catapultId
        orgName
        hsGradDate
        apUids {
          primaryInd
          siloId
        }
        ssdIds {
          primaryInd
          siloId
        }
        candidateIds {
          primaryInd
          siloId
        }
        genderCd
      }
    }
  }
`

export const QUERY_GET_APP_INFO = gql`
  query getAppInfo {
    getAppInfo
  }
`

export const QUERY_GET_REGISTRATIONS = gql`
  query getRegistrations($partitionKey: String!) {
    getRegistrations(partitionKey: $partitionKey) {
      aiCode
      adminYear
      sortKey
      spKey
      apUid
      aiName
      apRegNumber @client
    }
  }
`

export const QUERY_GET_EXAM_HISTORY = gql`
  query getExamHistory($partitionKeys: [String]) {
    getExamHistory(partitionKeys: $partitionKeys) {
      catapultId
      history {
        adminYear
        aiCode
        aiName
        apId
        examsTaken
        examsFormatted @client
        displayYear @client
      }
    }
  }
`

export const QUERY_FULL_STUDENT_DETAILS = gql`
  query getStudentsDetails($personId: [String], $allData: Boolean) {
    getStudentsDetails(personId: $personId, allData: $allData) {
      loginId
      personId
      preferredFirstName
      lastName
      firstName
      middleInitial
      birthDate
      hsGradDate
      address1
      address2
      address3
      city
      state
      zip
      province
      countryName
      email
      genderCd
      gender @client
      aiCode
      personStatus
      intlPostalCode
      orgName
      ssdIds {
        primaryInd
        siloId
      }
      apUids {
        primaryInd
        siloId
      }
      candidateIds {
        primaryInd
        siloId
      }
      primaryCandidateId @client
      primarySsdId @client
      nonPrimarySsdIds @client
      primaryApUid @client
      nonPrimaryApUids @client
      nonPrimaryCandidateIds @client
      oktaId
      catapultId
      accountStatus
    }
  }
`

export const QUERY_LOOKUPS = gql`
  query getLookups($lookupName: String) {
    getLookups(lookupName: $lookupName)
  }
`

export const QUERY_REPORT_URL = gql`
  query getQuicksightReportUrl($dashboardId: String) {
    getQuicksightReportUrl(dashboardId: $dashboardId)
  }
`

export const QUERY_ACTIVITY_LOG = gql`
  query getActivityLog($catapultId: String) {
    getActivityLog(catapultId: $catapultId) {
      activityDate
      activityName
      activitySource
      userId
      remarks
    }
  }
`

export const QUERY_GET_TERMS = gql`
  query getTermsAndConditions($version: String!) {
    getTermsAndConditions(version: $version)
  }
`

export const QUERY_GET_SERVER_TIME = gql`
  query getServerTime {
    getServerTime
  }
`

export const QUERY_GET_ZIPCODE_REQUIRED_COUNTRIES = gql`
  query getZipCodeRequiredCountries {
    getZipCodeRequiredCountries
  }
`
