import './aboutpanel.scss'

import Panel from '../../../../../_common/panel/Panel'
import ScoreDescription from './about/ScoreDescription'
import ScoreProjected from './about/ScoreProjected'
import ScoreInformational from './about/ScoreInformational'
import ScoreWithhold from './about/ScoreWithhold'
import { PANEL_ABOUT_SCORE } from '../../../../../../utils/analytics'

const AboutPanel = ({ title, score, subScores, bannerClass, panelTriggerId, eventsProjected, eventsInfo, exCode, adminYear, open, onClose }) => (
  <Panel title="About Your Score" triggerId={panelTriggerId} tracking={PANEL_ABOUT_SCORE} defaultOpen={open} onClose={onClose}>
    <div className={bannerClass}>
      <h4>{title}</h4>
    </div>
    <ScoreDescription score={score} subScores={subScores} />
    <ScoreProjected events={eventsProjected} />
    <ScoreWithhold exCode={exCode} adminYear={adminYear} />
    <ScoreInformational events={eventsInfo} />
  </Panel>
)

AboutPanel.propTypes = {
  title: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  subScores: PropTypes.array,
  panelTriggerId: PropTypes.string.isRequired,
  eventsProjected: PropTypes.array.isRequired,
  eventsInfo: PropTypes.array.isRequired,
  exCode: PropTypes.string.isRequired,
  bannerClass: PropTypes.string,
  adminYear: PropTypes.string.isRequired,
}

export default AboutPanel
