import { usePropertiesQuery } from '../../../services/graphql/utils'
import Spinner from '../../_common/spinner/Spinner'
import Dialog from '../../_common/dialog/Dialog'
import UpdateForm from './UpdateForm'

const ApplicationProperties = () => {
  const { data, loading, error } = usePropertiesQuery(true)

  return (
    <div className="container cb-padding-top-48">
      <h1 className="cb-h3 cb-margin-bottom-32">Application Properties</h1>
      {loading ? <Spinner center /> : null}
      {error ? (
        <Dialog error title="Error retrieving data">
          An error occurred retrieving the application properties. Please refresh and try again.
        </Dialog>
      ) : null}
      {data ? (
        <div className="row">
          <div className="col-sm-8 col-md-6">
            <UpdateForm properties={data} />{' '}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default ApplicationProperties
