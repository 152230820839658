import { CircularButton, Modal } from '@cb/apricot-react'
import CBUtils from '@cb/apricot/CBUtils'
import { usePropertiesQuery } from '../../../../services/graphql/utils'
import { isOnlineOrder, ORDER_STATUS_CANCELED, ORDER_STATUS_COMPLETE } from '../utils'
import { formatDate, FORMATS } from '../../../../utils/date'
import { getPriceProperties } from '../../../../utils/orders'

const DeliveryStatus = props => {
  const { data = {} } = usePropertiesQuery()
  const { diCode, diName, sentDate, updateDt, orderStatus, orderDate, showDisclaimer, channel } = props
  const { deliveryDateRange } = getPriceProperties(data, false, orderDate)
  const modalTriggerId = CBUtils.uniqueID('5', `${diCode}-modal-`)
  const date = formatDate(sentDate || updateDt, FORMATS.mediumdate)

  const status =
    orderStatus === ORDER_STATUS_COMPLETE ? (
      <>
        Sent: {date}
        {showDisclaimer ? (
          <>
            <CircularButton
              aria-haspopup="dialog"
              id={modalTriggerId}
              className="cb-btn-black cb-btn-xs cb-margin-left-4 vertical-align-middle"
              icon="question"
              label={`Disclaimer for ${diName}`}
            />
            <Modal trigger={modalTriggerId} title="Verifying Your Order">
              <p>
                College Board cannot verify that scores were received by your institution. Contact your institution directly to
                inquire about receipt.
              </p>
            </Modal>
          </>
        ) : null}
      </>
    ) : orderStatus === ORDER_STATUS_CANCELED ? (
      `Canceled: ${date}`
    ) : isOnlineOrder(channel) ? (
      <>
        Estimated delivery: <span className="cb-no-wrap">{deliveryDateRange}</span>
      </>
    ) : null

  return (
    <>
      {status}
    </>
  )
}

DeliveryStatus.propTypes = {
  orderStatus: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  diCode: PropTypes.string.isRequired,
  diName: PropTypes.string.isRequired,
  updateDt: PropTypes.string,
  sentDate: PropTypes.string,
  channel: PropTypes.string.isRequired,
  showDisclaimer: PropTypes.bool,
}

export default DeliveryStatus
