// @cb/apricot-react does not support className property and does not support different background colors
// TODO: revisit when apricot library has been updated
const Alert = ({ title, children, error, condensed }) => {
  const icon = error ? 'cb-exclamation cb-red1-color' : 'cb-megaphone cb-blue5-color'

  return (
    <div
      className={`${condensed ? 'cb-notification-condensed' : ''} cb-notification cb-gray5-bg cb-margin-bottom-16`}
      data-cb-apricot="dialog"
    >
      <div className="cb-notification-container">
        <div className="cb-notification-header">
          <span className={`cb-glyph cb-glyph-circular ${icon}`} aria-hidden="true" />
          <h4 className="cb-notification-title">
            {title}
          </h4>
        </div>
        <div className="cb-notification-content display-block">{children}</div>
      </div>
    </div>
  )
}

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  error: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  condensed: PropTypes.bool,
}

export default Alert
