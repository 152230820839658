import { Select } from '@cb/apricot-react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY_LOOKUPS, QUERY_REPORT_URL } from '../../../services/graphql/queries/queries'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'
import Dialog from '../../_common/dialog/Dialog'
import Spinner from '../../_common/spinner/Spinner'

import './reports.scss'
import orderBy from 'lodash/orderBy'

const Reports = () => {
  const [reportTypes, setReportTypes] = useState([])
  const [dashboardId, setDashboardId] = useState(null)
  const [currentValue, setCurrentValue] = useState('')
  const [dashboard, setDashboard] = useState(null)
  const { loading, error, data: lookupData } = useQuery(QUERY_LOOKUPS, { variables: { lookupName: 'report' } })
  const [getReportUrl, { loading: reportLoading, error: reportError, data: reportData }] = useLazyQuery(QUERY_REPORT_URL)
  const reportsRef = useRef(null)

  // retrieve embed url based on report type
  useEffect(() => {
    if (lookupData && !currentValue) {
      const reports = orderBy(
        lookupData.getLookups.map(lookup => ({ label: lookup.reportName, value: lookup.sortKey })),
        'label',
        'asc'
      )
      setCurrentValue(reports[0].value)
      setReportTypes(reports)
    }
    if (currentValue) {
      setDashboardId(lookupData.getLookups.find(r => r.sortKey === currentValue)?.sortKey)
    }
  }, [currentValue, lookupData])

  useEffect(() => {
    if (dashboardId) {
      getReportUrl({ variables: { dashboardId } })
    }
  }, [dashboardId])

  useEffect(() => {
    if (reportData && !dashboard) {
      const options = {
        url: reportData?.getQuicksightReportUrl.EmbedUrl,
        container: reportsRef.current,
        printEnabled: false,
        height: 'AutoFit',
        iframeResizeOnSheetChange: true,
        footerPaddingEnabled: true,
      }
      setDashboard(embedDashboard(options))
    }

    if (dashboard) {
      dashboard.navigateToDashboard({ dashboardId })
    }
  }, [reportData])

  return (
    <div className="container apscores-reports cb-padding-top-48">
      <h1 className="cb-h3 cb-margin-bottom-32">Reports</h1>
      <div className="row">
        <div className="col-sm-6">
          <Select
            values={reportTypes}
            name="reportType"
            floating
            floatingBg="light"
            label="Report Type"
            onChange={e => setCurrentValue(e)}
          />
        </div>
      </div>
      {loading || reportLoading ? (
        <div className="cb-margin-top-24">
          <Spinner center />
        </div>
      ) : null}
      {error || reportError ? <Dialog title="Error Retrieving Reports" error className="cb-margin-top-24" /> : null}
      <div>
        <div ref={reportsRef} className="cb-margin-top-32" />
      </div>
    </div>
  )
}

export default Reports
