import { makeVar } from '@apollo/client'
import { getSessionJSON, removeSessionItem, setSessionJSON } from '../../utils/storage'
import { getQueryParams } from '../../utils/app'

export const SELECTED_STORAGE_KEY = 'apscores-selected'
export const CRITERIA_STORAGE_KEY = 'apscores-criteria'

export const selectedStudentVar = makeVar(null)
export const searchCriteriaVar = makeVar(null)

const useStudentSearch = () => {
  const setStudentSearch = () => {
    const queryParams = getQueryParams()
    if (queryParams) {
      const { personId } = queryParams
      updateSearchCriteria({ personId })
    }

    const criteria = getSessionJSON(CRITERIA_STORAGE_KEY)
    const selected = getSessionJSON(SELECTED_STORAGE_KEY)
    searchCriteriaVar(criteria)
    selectedStudentVar(selected)
  }

  const clearSelectedStudent = () => {
    selectedStudentVar({})
    removeSessionItem(SELECTED_STORAGE_KEY)
  }

  const clearSearchCriteria = () => {
    searchCriteriaVar({})
    removeSessionItem(CRITERIA_STORAGE_KEY)
  }

  const setSelectedStudent = student => {
    selectedStudentVar(student)
    setSessionJSON(SELECTED_STORAGE_KEY, student)
  }

  const removeSearchCriterion = key => {
    const { [key]: itemToRemove, ...remaining } = searchCriteriaVar()
    updateSearchCriteria(remaining)
  }

  const updateSearchCriteria = updates => {
    searchCriteriaVar(updates)
    setSessionJSON(CRITERIA_STORAGE_KEY, updates)
  }

  const prepareSearchCriteria = criteria => {
    const { searchType, ...remaining } = criteria || searchCriteriaVar()
    if (criteria) updateSearchCriteria({ ...criteria })
    return { personSearchCriteria: remaining }
  }

  return {
    setStudentSearch,
    clearSelectedStudent,
    clearSearchCriteria,
    setSelectedStudent,
    removeSearchCriterion,
    prepareSearchCriteria,
  }
}

export default useStudentSearch
