import { Select, SpacerV } from '@cb/apricot-react'
import { validateExactLength, validateNumber, validateAlphaNumeric } from '../../../../utils/validations'
import ValidationInput from '../../../_common/forms/validationinput/ValidationInput'
import { BYID_FIELD_AP, BYID_FIELD_PERSON, BYID_FIELD_LOGIN, BYID_FIELD_CANDIDATE, BYID_FIELD_SSD } from './utils'

const ID_TYPES = {
  [BYID_FIELD_AP]: {
    label: 'AP ID',
    value: BYID_FIELD_AP,
    maxLength: 8,
    helperMsg: 'Please enter 8 characters',
    errorMsg: 'Error: AP ID must be 8 characters, made up of letters and/or numbers.',
    validate: value => validateExactLength(value, 8) && validateAlphaNumeric(value),
    transform: value => value.toUpperCase(),
  },
  [BYID_FIELD_PERSON]: {
    label: 'Person ID',
    value: BYID_FIELD_PERSON,
    maxLength: 15,
    helperMsg: 'Please enter a numeric value',
    errorMsg: 'Error: Person ID must be a numeric value greater than 0.',
    validate: value => validateNumber(value),
  },
  [BYID_FIELD_LOGIN]: {
    label: 'Login ID',
    value: BYID_FIELD_LOGIN,
    maxLength: 50,
    helperMsg: 'Enter in the student account username or Okta verified email. Wildcards are not accepted. Only exact matches returned.',
    errorMsg: '',
    validate: value => (value),
  },
  [BYID_FIELD_CANDIDATE]: {
    label: 'AP Candidate ID',
    value: BYID_FIELD_CANDIDATE,
    maxLength: 12,
    helperMsg: 'Please enter 12 digits',
    errorMsg: 'Error: AP Candidate ID must be 12 digits, inclusive of any leading zeros',
    validate: value => validateExactLength(value, 12) && validateNumber(value),
  },
  [BYID_FIELD_SSD]: {
    label: 'SSD ID',
    value: BYID_FIELD_SSD,
    maxLength: 10,
    helperMsg: 'Please enter 10 digits',
    errorMsg: 'Error: SSD ID must be 10 digits, inclusive of any leading zeros.',
    validate: value => validateExactLength(value, 10) && validateNumber(value),
  },
}
const OPTIONS = Object.values(ID_TYPES)
const FIELDNAME_TYPE = 'idType'
const FIELDNAME_ID = 'idValue'

const SearchByIDForm = ({ savedCriteria, disableFields, onFieldChange }) => {
  const [formType, setFormType] = useState(
    Object.keys(savedCriteria).find(k => Object.keys(ID_TYPES).find(t => t === k)) || BYID_FIELD_AP
  )
  const [idValue, setIDValue] = useState(savedCriteria[formType] || '')
  const { transform, ...inputProps } = ID_TYPES[formType]

  return (
    <fieldset disabled={disableFields}>
      <legend className="sr-only">Search by ID</legend>
      <Select
        values={OPTIONS}
        name={FIELDNAME_TYPE}
        floating
        label="ID Type"
        floatingBg="light"
        value={formType}
        onChange={value => {
          onFieldChange(formType)
          setIDValue('')
          setFormType(value)
        }}
      />
      <SpacerV size={24} />
      <ValidationInput
        {...inputProps}
        key={`${FIELDNAME_ID}-${formType}`}
        name={FIELDNAME_ID}
        value={idValue}
        floating
        floatingBg="light"
        onChange={(value, state) => {
          const processed = transform ? transform(value) : value
          setIDValue(processed)
          onFieldChange(formType, processed, state)
        }}
      />
    </fieldset>
  )
}

SearchByIDForm.propTypes = {
  savedCriteria: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  disableFields: PropTypes.bool,
}

export default SearchByIDForm
