import { AccordionList, AccordionItem } from '@cb/apricot-react'
import ResendScores from '../actions/resendscores/ResendScores'
import SendScoreAccordionItem from './SendScoreAccordionItem'
import ActivityAccordionHeader from './ActivityAccordionHeader'
import OrderStatus from '../orderdetails/OrderStatus'
import { getOrderStatus, getChannel, ORDER_STATUS_PLACED } from '../utils'
import { isResend } from '../../../../utils/orders'

import './byinstitution.scss'

const ByInstitution = ({ diName, diCode, items }) => (
  <div className="apscores-orders-card cb-card cb-card-event cb-no-padding-left cb-no-padding-right cb-no-padding-top cb-margin-bottom-48">
    <div className="cb-padding-24 display-sm-flex display-xs-block-only justify-content-between align-items-center">
      <h2 className="cb-h4">
        {diName} ({diCode})
      </h2>
      <div className="cb-margin-xs-top-8 cb-margin-sm-up-left-8">
        <ResendScores diName={diName} diCode={diCode} hasScoresPreviouslySent={Boolean(items.find(i => !i.order))} />
      </div>
    </div>
    <div className="apscores-accordion-list">
      <AccordionList resizeOnOpen controls title="Activity Date">
        {items.map((item, i) => {
          const subtitle = item.order
            ? `${getChannel(item.channel)} Placed ${
                item.orderStatus !== ORDER_STATUS_PLACED || isResend(item.resend)
                  ? `(Status: ${getOrderStatus(item.orderStatus, item.resend).title})`
                  : ''
              }`
            : 'Scores Sent to Institution'
          const title = <ActivityAccordionHeader date={item.orderDate || item.sentDate} subtitle={subtitle} />
          return (
            <AccordionItem key={i} title={title}>
              {item.order ? <OrderStatus order={item} /> : <SendScoreAccordionItem {...item} />}
            </AccordionItem>
          )
        })}
      </AccordionList>
    </div>
  </div>
)

ByInstitution.propTypes = {
  diName: PropTypes.string.isRequired,
  diCode: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ByInstitution
