import polyfills from './utils/polyfills'
import ReactDOM from 'react-dom/client'
import App from './components/app/App'
import { hasLoginCookie } from './utils/auth'
import { SHOW_EVENT, HIDE_EVENT, getContainers, getAppConfig, setAppConfig, APP_CONFIG } from './utils/app'

import './assets/scss/apricot3.4-overrides.scss'
import './assets/scss/apricot-helpers.scss'

// global apricot imports
import '@cb/apricot/CBBase'
import '@cb/apricot/CBCard'
import '@cb/apricot/CBBand'
import '@cb/apricot/CBLogo'

let appContainer = null

const destroy = () => appContainer && ReactDOM.unmountComponentAtNode(document.querySelector(appContainer))

const toggleLogoutContainers = hide => {
  const { options } = getAppConfig()
  options.logoutContainers.forEach(c => (c.style.display = hide ? 'none' : 'block'))
}

const toggle = show => {
  const event = new CustomEvent(show ? SHOW_EVENT : HIDE_EVENT, { bubbles: true })
  appContainer.dispatchEvent(event)
  toggleLogoutContainers(show)
}

const init = (container, { logoutContainer }) => {
  const isLoggedIn = hasLoginCookie()

  appContainer = document.querySelector(container)

  setAppConfig(getContainers(logoutContainer), `${APP_CONFIG}.options.logoutContainers`)
  toggleLogoutContainers(true)

  if (isLoggedIn) toggle(true)

  // show breakpoint information, dev use only
  if (Config.LOCAL) document.body.classList.add('cb-dev-env', 'cb-show-breakpoints')

  // the wrapper class is for apricot 3.4 overrides only
  // we need to wrap these styles so they do not conflict with drupal, which uses 3.4 and does not want the overrides
  const root = ReactDOM.createRoot(appContainer)
  root.render(
    <div className="apscores-app">
      <App />
    </div>)
}

polyfills()
setAppConfig({ init, destroy, toggle, events: { show: SHOW_EVENT, hide: HIDE_EVENT } })
