import ScoreCancel from './cards/cancel/ScoreCancel'
import ScoreAvailable from './cards/available/ScoreAvailable'
import './score.scss'
import ScoreHold from './cards/hold/ScoreHold'

const Score = props => {
  const { eventCancel, eventHold, score } = props

  if (eventCancel) return <ScoreCancel exEvent={eventCancel} {...props} />

  if (eventHold) return <ScoreHold exEvent={eventHold} {...props} />

  if (score) return <ScoreAvailable {...props} />

  return null
}

Score.propTypes = { eventCancel: PropTypes.object, eventHold: PropTypes.object }

export default Score
