import { formatDate, FORMATS } from '../../../../utils/date'

const ActivityAccordionHeader = ({ date, subtitle }) => (
  <div className="display-sm-flex display-xs-block-only align-items-center apscores-accordion-header">
    <div>{formatDate(date, FORMATS.mediumdate)}</div>
    <div className="cb-roboto-medium cb-font-size-small">{subtitle}</div>
  </div>
)

ActivityAccordionHeader.propTypes = {
  date: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default ActivityAccordionHeader
