import { Input } from '@cb/apricot-react'
import Button from '../../_common/button/Button'
import DateTimeInput from '../../_common/forms/datetimeinput/DateTimeInput'
import isEqual from 'lodash/isEqual'
import { MUTATION_UPDATE_PROPERTIES } from '../../../services/graphql/mutations/mutations'
import Dialog from '../../_common/dialog/Dialog'
import { useMutation } from '@apollo/client'
import { validateAlphaNumeric, validateNumber, validateVersion, validateUrl, validateFormattedString } from '../../../utils/validations'
import { READ_ONLY } from '../../../utils/properties'

export const BTN_SUBMIT_TXT = 'Update'

const UpdateForm = ({ properties }) => {
  const [valid, setValid] = useState({})
  const [updates, setUpdates] = useState(properties)
  const hasInvalidField = Object.values(valid).includes(false) || Object.values(valid).includes('error')
  const hasNoUpdates = isEqual(updates, properties)
  const [updateProperties, { data, error, loading }] = useMutation(MUTATION_UPDATE_PROPERTIES, {
    update(cache, { data: { updateProperties } }) {
      cache.modify({
        fields: {
          getProperties(existing = []) {
            return existing?.map(e => {
              let updatePropValue
              const { propName } = e
              if (propName === updateProperties?.find(u => u.propName === propName)?.propName)
                updatePropValue = updates[e.propName]
              return { ...e, propName, propValue: updatePropValue || e.propValue }
            })
          },
        },
      })
    },
  })

  const updateField = (key, value) => setUpdates(update => ({ ...update, [key]: value }))

  const submitForm = e => {
    e.preventDefault()
    if (!hasInvalidField) {
      const updatedProps = Object.keys(updates).reduce((acc, prop) => {
        if (properties[prop] !== updates[prop]) return { ...acc, [prop]: updates[prop] }
        else return acc
      }, {})
      updateProperties({ variables: { properties: updatedProps } })
    }
  }

  useEffect(() => {
    if (properties) setUpdates(properties)
  }, [properties])

  return (
    <>
      <form onSubmit={submitForm}>
        {Object.keys(properties).map(key => {
          const isDateField = /date/.test(key.toLowerCase())
          const isPrice = /price/.test(key.toLowerCase())
          const isVersion = /version/.test(key.toLowerCase())
          const isUrl = /url/.test(key.toLowerCase())
          const isFormattedString = /message/.test(key.toLowerCase())
          const isInteger = number => Number.isInteger(number)
          const substringKey = key.charAt(0).toUpperCase() + key.substr(1, key.length)
          const substringIndex = key.search(/[A-Z]/g)
          const validationMsg = (
            isPrice ? `Error: ${substringKey.substr(0, substringIndex)} Price should be a numerical value` :
            isVersion ? 'Error: Version needs to be in format v1, v2, v3...' : 
            isUrl ? 'Error: URL needs to be in format  https://www.example.com/' :
            `Error: ${key} should be valid string`
          );
          const isReadOnly = key.includes(READ_ONLY)
          const label = isReadOnly ? `${key.replace(READ_ONLY, '')} (Read Only)` : key

          return (
            <div className="cb-margin-bottom-48" key={key}>
              {isDateField ? (
                <DateTimeInput
                  isUTCFormat={true}
                  date={properties[key]}
                  readOnly={isReadOnly}
                  label={label}
                  onChange={(value, valid) => {
                    updateField(key, value)
                    setValid (oldValue => ({...oldValue, [key]: valid }))
                  }}
                  paragraphClassNames="cb-font-size-small"
                  boldDate
                />
              ) : (
                <Input
                  validation={valid[key]}
                  validationMsg={valid[key] === 'error' ? validationMsg : null}
                  clearable={false}
                  label={label}
                  defaultValue={properties[key]}
                  readOnly={isReadOnly}
                  onChange={e => {
                    const value = isInteger(e.target.value) ? parseInt(e.target.value) : e.target.value
                    updateField(key, value)
                    const validation = (
                      isPrice ? validateNumber(value) :
                      isVersion ? validateVersion(value) :
                      isUrl ? validateUrl(value) :
                      isFormattedString ? validateFormattedString(value) :
                      validateAlphaNumeric(value)
                    );
                    setValid (oldValue => ( {...oldValue, [key]: !validation ? 'error' : '' }))
                  }}
                />
              )}
            </div>
          )
        })}
        {error ? (
          <Dialog error title="Error Updating" className="cb-margin-top-32">
            An error occurred updating the application properties. Please try again.
          </Dialog>
        ) : null}
        {data ? (
          <Dialog success title="Successful Update" className="cb-margin-top-32">
            The application properties have been successfully updated.
          </Dialog>
        ) : null}
        <Button
          primary
          title={BTN_SUBMIT_TXT}
          disabled={hasInvalidField || hasNoUpdates || loading}
          loading={loading}
          type="submit"
          className="cb-margin-top-24"
        />
      </form>
    </>
  )
}

UpdateForm.propTypes = {
  properties: PropTypes.object.isRequired,
}

export default UpdateForm
