import { Link, useHistory } from 'react-router-dom'
import { Dropdown, DropdownItem } from '@cb/apricot-react'
import { ADMIN_NAVIGATION } from '../../../utils/navigation'
import CBLocalNavigation from '@cb/apricot/CBLocalNavigation'
import { APP_LINKS } from '../../../utils/navigation'

import './navigation.scss'

const AdminNavigation = ({ selectedStudent }) => {
  const navRef = useRef(null)
  const history = useHistory()
  const isPath = path => history.location.pathname.includes(path)

  useEffect(() => {
    CBLocalNavigation({
      elem: navRef.current,
      mobileInteractionClose: true,
    })
  }, [])

  const navigate = location => history.push(location)

  const NavigationItem = n =>
    n.paths ? (
      <li className="cb-nav-link">
        <Dropdown title={n.title} closeOnClick>
          {n.paths.map((p, i) => (
            <DropdownItem label={p.title} onClick={() => navigate(p.path)} key={i} aria-current={isPath(p.path)} />
          ))}
        </Dropdown>
      </li>
    ) : (
      <li className="cb-nav-link cb-procedural" key={n.path}>
        <Link to={n.path} className={`cb-menu-link ${isPath(n.path) ? 'cb-selected' : ''}`} aria-current={isPath(n.path)}>
          <span>{n.title}</span>
        </Link>
      </li>
    )

  return (
    <div ref={navRef} className="cb-local-navigation cb-white-bg cb-box-shadow-bottom" data-cb-apricot="local-navigation">
      <div className="cb-desktop-navigation container">
        <div className="row">
          <nav className="col-xs cb-nav-container" aria-label="Admin Navigation">
            <div className="cb-site-name">
              <Link to="/">AP OSRS Admin Panel</Link>
            </div>
            <div className="cb-nav-items hidden-xs-only flex-fill flex-grow-0">
              <div className="cb-menu-list cb-menu-list-horizontal">
                <ul>
                  {ADMIN_NAVIGATION.map((n, i) => (!n.authCheck || n.authCheck() ? <NavigationItem {...n} key={i} /> : null))}
                  {selectedStudent.catapultId ? (
                    <li className="cb-nav-link">
                      <Dropdown
                        closeOnClick
                        title={
                          <span className="cb-roboto-medium">
                            {/* this is needed because apricot currently does not allow icons in the dropdown title */}
                            <i className="cb-glyph cb-user" aria-hidden={true} />
                            {selectedStudent.firstName} {selectedStudent.lastName}
                          </span>
                        }
                      >
                        <DropdownItem label="View Student Info" onClick={() => navigate(APP_LINKS.studentdetails)} />
                        <DropdownItem label="View Scores & Orders" onClick={() => navigate(APP_LINKS.viewscores)} />
                        <DropdownItem label="View Activity Log" onClick={() => navigate(APP_LINKS.activitylog)} />
                      </Dropdown>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <div className="cb-mobile-trigger hidden-sm-up">
              <a
                href="#"
                className="cb-glyph cb-menu cb-no-text-decoration cb-link-black"
                aria-expanded="false"
                tabIndex="-1"
                aria-hidden="true"
              >
                <span className="sr-only">Display Site Navigation</span>
              </a>
            </div>
          </nav>
        </div>
      </div>
      <div className="cb-mobile-navigation" />
    </div>
  )
}

AdminNavigation.propTypes = {
  selectedStudent: PropTypes.object,
}

export default AdminNavigation
