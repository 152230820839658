import TooltipPanel from '../../../../../../_common/panel/TooltipPanel'
import ContentLookup from '../../../../../../_common/request/ContentLookup'
import { appendEventCodes } from '../../../../../../../utils/event-codes'
import { PANEL_PROJECTED } from '../../../../../../../utils/analytics'

const ProjectedCard = ({ events, adminYear, exCode, border, exName }) =>
  events.length ? (
    <>
      <div
        className={`cb-align-center cb-margin-top-16 ${
          border ? 'cb-padding-bottom-16 cb-gray4-border cb-border-solid cb-border-xs-bottom' : ''
        }`}
      >
        {events.map((p, i) => (
          <ContentLookup
            file="eventsProjected"
            type={p.eventCode}
            key={p.eventCode}
            component={({ content = {} }) => {
              const contentMore = appendEventCodes(content.more, [p.eventCode])
              const displayCodes = appendEventCodes('', [p.eventCode])

              return (
                <div
                  className={`cb-font-size-xsmall cb-gray1-color cb-padding-right-4 cb-margin-md-left-8 cb-line-height-15 ${
                    i ? 'cb-padding-top-8' : ''
                  }`}
                >
                  <span dangerouslySetInnerHTML={{ __html: content.desc || p.eventDesc }} />{' '}
                  <TooltipPanel
                    trigger={`projectedBtn-${adminYear}-${exCode}-${p.eventCode}`}
                    title="Projected Score"
                    content={contentMore}
                    exName={exName}
                    tracking={PANEL_PROJECTED}
                  />
                  <span dangerouslySetInnerHTML={{ __html: displayCodes }} />
                </div>
              )
            }}
          />
        ))}
      </div>
    </>
  ) : null

ProjectedCard.propTypes = {
  events: PropTypes.array.isRequired,
  adminYear: PropTypes.string.isRequired,
  exCode: PropTypes.string.isRequired,
  border: PropTypes.bool,
}

export default ProjectedCard
