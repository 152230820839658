import CBUtils from '@cb/apricot/CBUtils'

const useViewport = () => {
  const set = view => ({
    mobile: view === 'xs',
    tablet: view === 'sm',
    desktop: view === 'lg',
    oversize: view.includes('xl'),
  })
  const [viewport, setViewport] = useState(set(CBUtils.viewport().prefix))

  useEffect(() => {
    CBUtils.breakpoints()
    const viewportChanged = e => setViewport(set(e.data.prefix))
    document.addEventListener('apricot_breakpointChange', viewportChanged)
    return () => document.removeEventListener('apricot_breakpointChange', viewportChanged)
  }, [])

  return viewport
}

export default useViewport
