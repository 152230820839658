import Alert from '../../../../_common/alert/Alert'
import { getEcommStatus } from '../../../../../utils/orders'

const ERROR_MESSAGES = {
  2: 'The credit card number you entered is invalid. Please check the number and try again.',
  3: 'We are unable to process your payment at this time. Please try again later.',
  4: 'We are unable to process your payment at this time. Please try again later.',
  5: 'We are unable to process your payment at this time. Please try again later.',
  9999: 'Your request has timed out due to inactivity. Please try again.',
  submission: 'We are unable to create your order at this time. Please try again later.',
}

const ErrorAlert = ({ submissionError }) => {
  const status = getEcommStatus()
  const message = ERROR_MESSAGES[status] || (submissionError && ERROR_MESSAGES.submission)

  return message ? (
    <Alert error focusable title="Error">
      {message}
    </Alert>
  ) : null
}

ErrorAlert.propTypes = {
  submissionError: PropTypes.bool,
}

export default ErrorAlert
