import { convertArrayToPrettyString } from '../../../../../../utils/app'
import Alert from '../../../../../_common/alert/Alert'

const CanceledAlerts = ({ cancelEvents = [] }) => {
  const canceledScores = cancelEvents.map(c => `${c.exNameFormatted} (${c.displayYear})`)
  const canceledScoreLength = canceledScores.length
  const hasMoreThanOneScore = canceledScoreLength > 1

  return canceledScoreLength ? (
    <>
      <Alert title={`Canceled Score${hasMoreThanOneScore ? 's' : ''}`}>
        Your score for <strong className="cb-font-weight-medium">{convertArrayToPrettyString(canceledScores)}</strong>{' '}
        {hasMoreThanOneScore ? 'were' : 'was'} canceled and will not be sent with your order.
      </Alert>
    </>
  ) : null
}

CanceledAlerts.propTypes = {
  cancelEvents: PropTypes.arrayOf(PropTypes.object),
}

export default CanceledAlerts
