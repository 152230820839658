import { getCBEnv } from './auth'

const { VERSION, LOCAL } = Config

const contentConfig = env => {
  const prefix = LOCAL
    ? ''
    : env === 'prod'
    ? 'https://apscoreadmin.collegeboard.org'
    : `https://apscores-${env}.ap-nonprod.collegeboard.org`
  return `${prefix}/${VERSION}/content`
}

const orgSearchConfig = (isProd, env) => ({
  orgSearchApiKey: isProd ? 'lXija5iNmqalxCfY030fJ5wQBIU33a4m6dvxzP1i' : '8yOf50e7rQ5agbNW0rcC23KCPT1NdtVYAmEsY0G8',
  orgSearchUrl: `https://organization.cds-${isProd ? 'prod' : 'nonprod'}.collegeboard.org/${isProd ? 'pine' : `${env}`}/disearch`,
})

const helpContentConfig = (isProd, env) => {
  const isStagingEnv = env === 'oat' || env === 'pt'
  const drupalEnv = isStagingEnv ? '-stg' : isProd ? '' : '-dev.cms-sites'
  return {
    cmsUrl: `https://apstudents${drupalEnv}.collegeboard.org/jsonapi/node/profile_collection_index`,
    collectionId: '4582a489-63bf-4528-8172-8338802e1e91',
  }
}

const CB_ENV_MAP = {
  oak: 'dev',
  palm: 'qa',
  maple: 'oat',
  apple: 'uat',
  spruce: 'pt',
  pine: 'prod',
}

let config = null
const getEnvConfig = () => {
  if (!config) {
    const cbEnv = getCBEnv() || 'pine' // default to prod
    const apEnv = Config.ENV || CB_ENV_MAP[cbEnv] // set environment to one set during build or dynamically set it based off cb env (oak, palm, etc)
    const isProd = cbEnv === 'pine'
    config = {
      contentUrl: contentConfig(apEnv),
      appSyncUrl: isProd
        ? 'https://apscores-api.collegeboard.org/graphql'
        : `https://apscores-service-${apEnv}.ap-nonprod.collegeboard.org/graphql`,
      ...orgSearchConfig(isProd, cbEnv),
      ...helpContentConfig(isProd, cbEnv),
    }
  }

  return config
}

export default getEnvConfig
