export const WITHHOLD_EVENT_CODE = 98
export const HOLD_EVENT_CODE = 7
export const PRIMARY_IND = 'Y'

export const CONTAINER_WIDE_CLASS = 'col-xs-12 offset-2xl-1 col-2xl-9'
export const CONTAINER_THIN_CLASS = 'offset-xl-3 col-xl-6 offset-md-2 col-md-8 offset-sm-1 col-sm-10'
export const ROW_CLASS = 'row'
export const LEFT_COLUMN_CLASS = 'col-md-7 col-2xl-6 offset-2xl-1'
export const RIGHT_COLUMN_CLASS = 'col-md-4 col-2xl-3 offset-md-1'

export const FORM_LINK = 'https://collegeboard.tfaforms.net/193'
