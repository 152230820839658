import { useQuery } from '@apollo/client'
import { getStorageJSON, removeStorageItem, setStorageJSON } from '../../utils/storage'
import { MUTATION_TRACK_ACTIVITY } from '../graphql/mutations/mutations'
import { getAuthSession, getCatapultId, getTrackingUserId, isStudent } from '../../utils/auth'
import { getTimestamp } from '../../utils/date'
import { getQueryDataWithoutKey, useCustomMutation } from '../graphql/utils'
import { QUERY_SELECTED_STUDENT } from '../graphql/queries/queries'

const MAX_RETRIES = 5

export const TRACK_STUDENT_SCORES = 'apscores-tracking-scores'
export const TRACK_STUDENT_LOGIN = 'apscores-tracking-student-login'

const trackData = {
  [TRACK_STUDENT_SCORES]: { key: 'SCV', name: 'Scores Viewed' },
  [TRACK_STUDENT_LOGIN]: { key: 'SAPL', name: 'Successful AP Login' },
}

export const removeTrackingData = () => {
  removeStorageItem(TRACK_STUDENT_SCORES)
  removeStorageItem(TRACK_STUDENT_LOGIN)
}

const useActivity = type => {
  const [retries, setRetries] = useState(0)
  const student = getQueryDataWithoutKey(useQuery(QUERY_SELECTED_STUDENT))
  const [trackActivity, { data, error }] = useCustomMutation(MUTATION_TRACK_ACTIVITY)
  const { sessionId: authSessionId } = getAuthSession()
  const { trackSessionId, trackedStudents = [] } = getStorageJSON(type)
  const user = student?.catapultId || getCatapultId()
  const isSameSession = authSessionId === trackSessionId
  const { key, name } = trackData[type]
  const trackComplete = trackedStudents.find(s => s === user)

  useEffect(() => {
    if (data && !trackComplete) {
      setStorageJSON(type, {
        trackSessionId: authSessionId,
        trackedStudents: isSameSession ? [...trackedStudents, user] : [user],
      })
    }
  }, [data])

  useEffect(() => {
    if (error && !trackComplete && retries < MAX_RETRIES) {
      track()
      setRetries(retries + 1)
    }
  }, [error])

  const getActivityEntry = () => ({
    key,
    activitySource: isStudent() ? 'Web' : 'Admin Panel',
    activityName: name,
    userId: getTrackingUserId(),
  })

  const track = () => {
    if (!isSameSession || !trackComplete) trackActivity({ activity: getActivityEntry() })
  }

  return {
    track,
  }
}

export default useActivity
