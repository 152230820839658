import get from 'lodash/get'
import set from 'lodash/set'
import jwt_decode from 'jwt-decode'
import { getQueryParams } from './app'
import { getSessionJSON, setSessionJSON } from './storage'
import { SELECTED_STORAGE_KEY } from '../services/local/studentsearch'

const MOCKED_KEY = 'mockedStudent'
const savedMockedUser = getSessionJSON(MOCKED_KEY)
const { cbtoken, user } = Object.keys(savedMockedUser).length ? savedMockedUser : getQueryParams()
const isMocked = cbtoken && user

// set mocked user info
if (isMocked) {
  const fakeUser = { firstName: 'Mickey', lastName: 'Mock' }
  setSessionJSON(SELECTED_STORAGE_KEY, { ...fakeUser, personId: '1234567', catapultId: user })
  setSessionJSON(MOCKED_KEY, { cbtoken, user })
  set(window, 'cb.core.iam.getAuthSession', () => ({
    sessionId: 'mocked',
    isLoggedIn: true,
    basicProfile: { ...fakeUser, isStudent: true, userName: 'MickeyMock' },
  }))
}

export const iam = () => get(window, 'cb.core.iam')
export const getCreds = () => iam().getTempAWSCreds('ap')
export const getCatapultId = () => user || iam().getTempAWSCredsSubject()
export const getCBEnv = () => (isMocked ? 'palm' : iam().getCBEnv())
export const getIAMEvents = () => iam().events
export const getAuthSession = () => iam().getAuthSession() || {}
export const hasLoginCookie = () => Boolean(cbtoken) || document.cookie.includes('cb_login')
export const getJWTToken = () => cbtoken || iam().getJWTToken()

export const ROLE_CSR = '2043'
export const ROLE_QC = '2042'
export const ROLE_READONLY = '2041'
export const ROLE_ADMIN = '2044'

let decodedToken = undefined
export const getDecodedToken = () => {
  const token = getJWTToken()
  try {
    if (!decodedToken) decodedToken = token && jwt_decode(token)
  } catch (err) {}
  return decodedToken
}
export const checkRole = role => {
  const { cb } = getDecodedToken() || {}
  return cb?.ro?.find(r => r.rid === role)
}
export const isLoginAs = () => {
  const { cb } = getDecodedToken() || {}
  return cb?.lasroles?.length
}
export const getLoginAsUser = () => {
  const { cb } = getDecodedToken() || {}
  return { userName: cb?.lasid }
}
export const getUserInfo = () => (isLoginAs() ? getLoginAsUser() : getAuthSession().basicProfile)
export const getTrackingUserId = () => (isLoginAs() || !isStudent() ? getUserInfo().userName : null)
export const isAdmin = () => checkRole(ROLE_ADMIN)
export const isCSR = () => checkRole(ROLE_CSR)
export const isQC = () => checkRole(ROLE_QC)
export const isReadOnly = () => checkRole(ROLE_READONLY)
export const isStudent = () => {
  const { basicProfile } = getAuthSession()
  return basicProfile && basicProfile.isStudent
}

// app permissions
export const isRoleAdmin = () => isCSR() || isQC() || isReadOnly() || isAdmin()
export const canAccessApp = () => isStudent() || isCSR() || isAdmin() || isQC() || isReadOnly()
export const canCreateOrders = () => isStudent() || isCSR() || isAdmin()
export const canModifyOrder = () => isCSR() || isAdmin() || isMocked // cancel, resend, and free score
export const canPreviewScores = () => isQC() || isAdmin()
export const canViewEventCodes = () => isRoleAdmin()
export const canViewArchives = () => isCSR() || isReadOnly() || isAdmin()
export const canViewMerges = () => isRoleAdmin()
export const requiresTAndCs = () => isStudent() && !isMocked
export const requiresPartitionKey = () => !isStudent() || isMocked

const DEFAULT_AUTH = { loading: false, loggedIn: false, loggedOut: true, error: false }

export const useAuth = () => {
  const bus = iam().getEventBus()
  const hasAWSCreds = Boolean(getCatapultId())
  const hasCookie = hasLoginCookie()
  const [auth, setAuth] = useState({
    loading: hasCookie && !hasAWSCreds,
    loggedIn: hasAWSCreds,
    loggedOut: !hasCookie && !hasAWSCreds,
    error: false,
  })

  bus.on(cb.core.iam.events.Logout, () => setAuth(DEFAULT_AUTH))
  bus.on(cb.core.iam.events.AWSLogin, () => setAuth({ ...DEFAULT_AUTH, loggedIn: true, loggedOut: false }))
  bus.on(cb.core.iam.events.AWSLoginFailed, () => setAuth({ ...DEFAULT_AUTH, loggedIn: true, error: true, loggedOut: false }))

  return auth
}
