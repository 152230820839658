import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import ErrorWrapper from '../_common/error/ErrorWrapper'
import Routes from './Routes'
import { canAccessApp, useAuth } from '../../utils/auth'
import { getAppConfig, HIDE_EVENT, SHOW_EVENT } from '../../utils/app'
import { appsyncClient } from '../../services/graphql/appsync/client'
import useStudentSearch from '../../services/local/studentsearch'
import { ROUTE_BASENAME } from '../../utils/navigation'
import { removeTrackingData } from '../../services/local/tracking'
import Layout from '../_common/layout/Layout'
import Spinner from '../_common/spinner/Spinner'
import { CATAPULT_ERROR, useTrackingError } from '../../utils/analytics'

const App = () => {
  const { clearSelectedStudent, clearSearchCriteria } = useStudentSearch()
  const auth = useAuth()
  const { toggle } = getAppConfig()
  const [show, setShow] = useState(false)

  useEffect(() => {
    const showApp = () => setShow(true)
    const hideApp = () => setShow(false)
    document.addEventListener(SHOW_EVENT, showApp)
    document.addEventListener(HIDE_EVENT, hideApp)

    if (!window.location.pathname.includes(ROUTE_BASENAME))
      window.location.replace(`${window.location.origin}${ROUTE_BASENAME}${location.pathname}${location.search}`)

    return () => {
      document.removeEventListener(SHOW_EVENT, showApp)
      document.removeEventListener(HIDE_EVENT, hideApp)
    }
  }, [])

  useEffect(() => toggle(show), [show])
  useEffect(() => {
    setShow(!auth.loggedOut)

    // clear any data saved in session on logout
    if (auth.loggedOut) {
      removeTrackingData()
      clearSelectedStudent()
      clearSearchCriteria()
    }
  }, [auth])

  useTrackingError(auth.error, { message: CATAPULT_ERROR })

  if (!auth.loggedIn || !show) return null

  if (auth.loading) return <Spinner center className="cb-margin-top-72" />

  if (auth.error) return <Layout useNav={false} error={auth.error ? 'Temporarily unavailable. Please try again later.' : null} />

  if (!canAccessApp())
    return (
      <Layout
        useNav={false}
        error="You are not authorized to access the AP Scores CSR Student Admin Panel. This tool is for Customer Service Administrators. Please contact your Customer Service Manager to request access."
      />
    )

  if (auth.loggedIn)
    return (
      <ErrorWrapper>
        <ApolloProvider client={appsyncClient()}>
          <BrowserRouter basename={ROUTE_BASENAME}>
            <Routes />
          </BrowserRouter>
        </ApolloProvider>
      </ErrorWrapper>
    )

  return null
}

export default App
