import partition from 'lodash/partition'
import groupBy from 'lodash/groupBy'
import Alert from '../../../_common/alert/Alert'
import { convertArrayToPrettyString } from '../../../../utils/app'
import {formatSubscore} from '../../../../utils/scores'

const SendScoreAccordionItem = ({ exams, diName }) => {
  const [withholds, examsByDate] = partition(exams, value => value?.withholdInd)
  const examsByAdminYear = examsByDate && groupBy(examsByDate, 'displayYear')

  return (
    <div className="cb-padding-left-24 cb-padding-right-24">
      <p className="cb-font-size-small cb-margin-bottom-24">
        <em>
          College Board cannot verify that scores were received by your institution. Contact your institution directly to inquire
          about receipt.
        </em>
      </p>

      {withholds.length ? (
        <Alert title="Withheld Scores" condensed>
          The following scores were withheld from {diName} at your request:{' '}
          <strong className="cb-roboto-medium">
            {convertArrayToPrettyString(withholds.map(w => `${w.exNameFormatted} (${w.displayYear})`))}
          </strong>
          .
        </Alert>
      ) : null}
      {Object.keys(examsByAdminYear)
        .reverse()
        .map(key => (
          <div className="cb-margin-bottom-16 cb-padding-top-16" key={key}>
            <h3 className="cb-h5 cb-font-weight-xs-medium cb-padding-bottom-8 cb-border-bottom">{key} Scores</h3>
            {examsByAdminYear[key].map((score, i) => (
              <div key={i} className="cb-padding-bottom-16 cb-padding-top-16 cb-border-bottom">
                <div className="display-flex justify-content-between" key={`${key}-${score.exCode}`}>
                  <h4 className="cb-font-size-regular">{score.exNameFormatted}</h4>
                  <div>{score.score}</div>
                </div>
                {score.subscores?.map((s, i) => (
                  <div className="cb-font-size-small display-flex justify-content-between" key={i}>
                    <div>Subscore: {formatSubscore(s.exName)}</div>
                    <div>{s.score}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </div>
  )
}

SendScoreAccordionItem.propTypes = {
  diName: PropTypes.string.isRequired,
  exams: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SendScoreAccordionItem
