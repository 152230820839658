import Card from '../../../../../../_common/card/Card'
import ContentLookup from '../../../../../../_common/request/ContentLookup'
import { Modal } from '@cb/apricot-react'
import { appendEventCodes } from '../../../../../../../utils/event-codes'

const ScoreCancel = props => {
  const { exCode, exNameFormatted, adminYear, exEvent, cancelEventsByPrecedence } = props
  const modalTriggerId = `cancelModal-${adminYear}-${exCode}`
  const bannerClass = `apscores-banner apscores-banner-cancel`
  const eventCode = exEvent.eventCode.toString()
  const modalTitle = 'Canceled Score'

  return (
    <ContentLookup
      file="eventsCancel"
      type={eventCode}
      component={({ content = {} }) => {
        let descContent = appendEventCodes(content.desc || exEvent.eventDesc, cancelEventsByPrecedence)
        let moreContent = appendEventCodes(content.more, cancelEventsByPrecedence)

        const LeftColumn = () => <div className={`apscores-cancel-badge`} />
        const RightColumn = () => (
          <>
            <p
              className="apscores-intro cb-margin-sm-up-bottom-16 cb-padding-3xl-right-32"
              dangerouslySetInnerHTML={{ __html: descContent }}
            />
            {moreContent ? (
              <a href="#" id={modalTriggerId} className="cb-padding-xs-top-4 display-xs-block-only cb-font-size-small">
                Learn more <span className="sr-only">about your {exNameFormatted} score</span>
              </a>
            ) : null}
          </>
        )

        return (
          <>
            <Card
              type={`score-${exCode}`}
              title={exNameFormatted}
              components={[LeftColumn, RightColumn]}
              classes={[bannerClass]}
              align="right"
              testId={`score-${exNameFormatted.replaceAll(' ', '_')}-20${adminYear}-cancel`}
            />
            {moreContent ? (
              <Modal title={modalTitle} trigger={modalTriggerId} cancelButtonText="Close" analytics={true} analyticsTitle={modalTitle} analyticsOnClose={false}>
                <div dangerouslySetInnerHTML={{ __html: moreContent }} />
              </Modal>
            ) : null}
          </>
        )
      }}
    />
  )
}

ScoreCancel.propTypes = {
  exCode: PropTypes.string.isRequired,
  exNameFormatted: PropTypes.string.isRequired,
  adminYear: PropTypes.string.isRequired,
  exEvent: PropTypes.object,
}

export default ScoreCancel
