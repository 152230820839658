import './award.scss'
import Button from '../../../../_common/button/Button'
import Card from '../../../../_common/card/Card'
import ContentLookup from '../../../../_common/request/ContentLookup'
import Panel from '../../../../_common/panel/Panel'
import { PANEL_ABOUT_AWARD } from '../../../../../utils/analytics'

const Award = ({ awCode, awName, awNameFormatted, adminYear }) => {
  const panelTriggerId = `awardBtn-${adminYear}-${awCode}`
  const bannerClass = `apscores-banner apscores-banner-award apscores-banner-award-${awCode}`

  return (
    <ContentLookup
      file="awards"
      type={awCode}
      component={({ content }) => {
        const LeftColumn = () => (
          <div className={`apscores-badge apscores-badge-award apscores-badge-award-${awCode}`} aria-hidden />
        )

        const RightColumn = () => (
          <>
            <p className="apscores-intro cb-margin-bottom-24">
              <strong>Congratulations!</strong> You've earned the {awNameFormatted}.
            </p>
            {content ? <Button id={panelTriggerId} title="Learn about this award" className="cb-btn-black" /> : null}
          </>
        )

        return (
          <>
            <Card
              type={`award-${awCode}`}
              title={awName}
              components={[LeftColumn, RightColumn]}
              classes={[bannerClass]}
              testId={`award-${awName.replaceAll(' ', '_')}-20${adminYear}`}
            />
            {content ? (
              <Panel title="About Your Award" triggerId={panelTriggerId} tracking={PANEL_ABOUT_AWARD}>
                <div className={bannerClass}>
                  <h4>{awName}</h4>
                </div>
                <div className="cb-padding-24">
                  <div className="cb-margin-top-24" dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </Panel>
            ) : null}
          </>
        )
      }}
    />
  )
}

Award.propTypes = {
  awCode: PropTypes.string.isRequired,
  awName: PropTypes.string.isRequired,
  awNameFormatted: PropTypes.string.isRequired,
  adminYear: PropTypes.string.isRequired,
}

export default Award
