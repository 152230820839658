import { Link } from 'react-router-dom'
import Autosuggest from '../../../../_common/forms/autosuggest/Autosuggest'
import fetchDiSearchResults from '../../../../../services/rest/disearch'
import { APP_LINKS } from '../../../../../utils/navigation'
import useOrderOperations from '../../../../../services/local/order'

import './diautosuggest.scss'

const DIAutosuggest = ({ previous, selected }) => {
  const { addSelectedDi, disableDiSelection } = useOrderOperations()

  const fetch = async term => {
    const data = await fetchDiSearchResults(term, selected)
    return {
      term,
      data: data.map(d => ({
        label: `<div data-cb-term="${term}"><div class="cb-roboto-medium cb-font-size-regular">${d.diName} (${d.diCode})</div>${
          d.diCity ? `${d.diCity},` : ''
        } ${d.diState || d.diCountry}</div>`,
      })),
    }
  }

  const select = async label => {
    const term = label.match(/data-cb-term=\"([^"]*)\"/)[1]
    const diCode = label.match(/\((\d+)\)/)[1]
    const results = await fetchDiSearchResults(term)
    const addition = results.find(r => r.diCode === diCode)
    addSelectedDi(addition)
  }

  return (
    <div className="apscores-sendscores-autosuggest">
      <p className="cb-margin-bottom-24 cb-roboto-light">Search for colleges, universities, and scholarship programs to send your scores to.</p>
      <Autosuggest label="Enter name or code" id="sendscores" fetch={fetch} select={select} disabled={disableDiSelection()} />
      <p className="cb-margin-top-24">
        Can't find your recipient?{' '}
        <a href="https://collegeboard.tfaforms.net/193" target="_blank" title="Contact Form (Opens in a new window)" className="cb-link-black">
          Contact Us
        </a>
      </p>
    </div>
  )
}

DIAutosuggest.propTypes = {
  previous: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default DIAutosuggest
