import { getTotalPrice } from '../../../../../utils/orders'
import DeliveryAlerts from '../../deliverymethod/alerts/DeliveryAlerts'
import Alert from '../../../../_common/alert/Alert'
import ContentLookup from '../../../../_common/request/ContentLookup'
const FILE = 'alerts'
const RecipientsAndDelivery = ({
  selected,
  standardPrice,
  deliveryDateRange,
  isBlackoutPeriod,
  blackoutStartDate,
  blackoutEndDate,
  skipPayment,
  isUpdatedDeliveryAlertPeriod
}) => (
  <div id="apscores-recipients-delivery" className="cb-margin-bottom-24">
    <h3 className="cb-roboto cb-font-weight-xs-medium cb-margin-xs-top-48 cb-margin-bottom-24 cb-margin-xs-bottom-16">
      Recipients &amp; Order Total
    </h3>
    <>
      <DeliveryAlerts
        isBlackoutPeriod={isBlackoutPeriod}
        blackoutStartDate={blackoutStartDate}
        blackoutEndDate={blackoutEndDate}
      />
      {isUpdatedDeliveryAlertPeriod ? <ContentLookup
          file={FILE}
          type={['upgradedDelivery']}
          component={({ content = {} }) => 
          <Alert content={content}  title={content.upgradedDelivery?.title || ''} id="upgradedDeliveryAlert">
             {content.upgradedDelivery?.body}
          </Alert>}
      /> : ''
      }
    </>
    {selected.map(s => (
      <div key={s.diCode} className="cb-paragraph1-res cb-margin-bottom-16">
        <div className="display-flex justify-content-between cb-roboto-medium">
          <div>
            {s.diName} ({s.diCode})
          </div>
          <div>${skipPayment ? 0 : standardPrice}</div>
        </div>
      </div>
    ))}
    <div className="cb-paragraph1-res cb-roboto-medium cb-border-top cb-gray3-border display-flex justify-content-between cb-margin-top-24 cb-padding-top-24 cb-margin-xs-top-16 cb-padding-xs-top-16">
      <div>Total</div>
      <div>${getTotalPrice(selected, skipPayment ? 0 : standardPrice)}</div>
    </div>
    <div className="cb-h3 cb-roboto cb-font-weight-xs-medium display-flex justify-content-end cb-padding-top-24 cb-margin-xs-top-16 cb-padding-xs-top-16">
      <div className="cb-roboto-light cb-font-weight-xs-regular">
        <div className="cb-paragraph1-res">
            <div className="cb-roboto-light cb-font-weight-xs-regular">
              {deliveryDateRange ? (
                <>
                  Estimated delivery: <div className="cb-no-wrap display-inline">{deliveryDateRange}</div>
                </>
              ) : (
                'Not currently available'
              )}
            </div>
          </div>
        </div>
      </div>
  </div>
)

RecipientsAndDelivery.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
  standardPrice: PropTypes.number,
  deliveryDateRange: PropTypes.string,
  isBlackoutPeriod: PropTypes.bool,
  blackoutStartDate: PropTypes.string,
  blackoutEndDate: PropTypes.string,
  skipPayment: PropTypes.bool
}

export default RecipientsAndDelivery
