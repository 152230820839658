import ContentLookup from '../../../../../../_common/request/ContentLookup'
import { appendEventCodes } from '../../../../../../../utils/event-codes'

const ScoreProjected = ({ events = [] }) =>
  events.map(e => (
    <ContentLookup
      file="eventsProjected"
      type={e.eventCode}
      key={e.eventCode}
      component={({ content = {} }) => {
        const contentString = appendEventCodes(content.about || e.eventDesc, [e.eventCode])

        return (
          <div key={e.eventCode} className="cb-gray5-bg cb-padding-16 cb-margin-24">
            <h5 className="cb-roboto-bold cb-margin-bottom-8">Projected Score</h5>
            <div className="cb-gray1-color cb-font-size-small" dangerouslySetInnerHTML={{ __html: contentString }} />
          </div>
        )
      }}
    />
  ))

ScoreProjected.propTypes = {
  events: PropTypes.array.isRequired,
}

export default ScoreProjected
