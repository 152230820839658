import { useLazyQuery } from '@apollo/client'
import {
  QUERY_FULL_STUDENT_DETAILS,
  QUERY_GET_EXAM_HISTORY,
  QUERY_SELECTED_STUDENT
} from '../../../services/graphql/queries/queries'
import { getQueryDataWithoutKey } from '../../../services/graphql/utils'
import { useHistory } from 'react-router-dom'
import { APP_LINKS } from '../../../utils/navigation'
import StudentCompareTable from './table/StudentCompareTable'
import Spinner from '../../_common/spinner/Spinner'
import Dialog from '../../_common/dialog/Dialog'
import { getQueryParams } from '../../../utils/app'
import useStudentSearch from '../../../services/local/studentsearch'

const StudentCompare = () => {
  const history = useHistory()
  const [fetchStudent, student] = useLazyQuery(QUERY_SELECTED_STUDENT)
  const [fetchExamHistories, examHistory] = useLazyQuery(QUERY_GET_EXAM_HISTORY)
  const [fetchStudentDetails, studentDetails] = useLazyQuery(QUERY_FULL_STUDENT_DETAILS)
  const examHistoryData = getQueryDataWithoutKey(examHistory)
  const studentDetailsData = getQueryDataWithoutKey(studentDetails)
  const queryParams = getQueryParams()
  const { setSelectedStudent } = useStudentSearch()

  /*
  * If query parameters are present, then the first useEffect will fetch the catapultIds for both given personIds.
  * Afterward useEffect will set the selected student to the first and last name of the first student and the
  * person and catapult ids for both students.
  * Second useEffect will fetch student details and exam histories for both students if available
  * */
  useEffect(() => {
    const getStudentInfo = () => {
      fetchStudentDetails({ variables: { personId: [queryParams.personId, queryParams.matchingPersonId], allData: true } })

      const details = getQueryDataWithoutKey(studentDetails)
      if (details) {
        const [studentOne, studentTwo] = details
        setSelectedStudent({
          firstName: studentOne.firstName,
          lastName: studentOne.lastName,
          personId: studentOne.personId,
          catapultId: studentOne.catapultId,
          comparePersonId: studentTwo.personId,
          compareCatapultId: studentTwo.catapultId
        })
        // Clear query params from current URL
        const { origin } = history?.location
        history.replace(origin)

        fetchStudent()
      }
    }

    if (queryParams?.personId && queryParams?.matchingPersonId) getStudentInfo()
    else fetchStudent()
  }, [studentDetails.data])

  useEffect(() => {
    if (student.data) {
      const { catapultId, compareCatapultId, personId, comparePersonId } = student.data?.getSelectedStudent
      if (!catapultId && !compareCatapultId) history.replace(APP_LINKS.studentsearch)
      else if (catapultId && compareCatapultId) {
        fetchExamHistories({ variables: { partitionKeys: [catapultId, compareCatapultId] } })
        fetchStudentDetails({ variables: { personId: [personId, comparePersonId], allData: false } })
      }
    }
  }, [student.data])

  return (
    <div className="container cb-padding-top-48">
      <h1 className="cb-h3 cb-margin-bottom-32">Compare Students</h1>
      {examHistory.loading || studentDetails.loading ? <Spinner center /> : null}
      {examHistory.error || studentDetails.error ? (
        <Dialog error title="Error retrieving data">
          An error occurred retrieving student details. Please refresh and try again.
        </Dialog>
      ) : null}
      {examHistoryData?.length && studentDetailsData?.length ? (
        <StudentCompareTable history={examHistoryData} studentDetails={studentDetailsData} />
      ) : null}
    </div>
  )
}

export default StudentCompare
