import {
  QUERY_GET_ZIPCODE_REQUIRED_COUNTRIES,
  QUERY_PROPERTIES,
  QUERY_STUDENT_INFO,
} from '../../../../services/graphql/queries/queries'
import { Spinner } from '@cb/apricot-react'
import { ORDER_PARAM } from '../../../../services/local/order'
import { useCustomQuery } from '../../../../services/graphql/utils'
import Alert from '../../../_common/alert/Alert'

export const MissingZipCodeAlert = ({loading, address1, address2, address3, city, province, state, zip, intlPostalCode, countryName, appId}) => (
  <Alert error title='Postal Code Required'>
    {loading && <Spinner size='32' />}
    {!loading && <>
      <div>
        <p>
          Below is the address we have associated to your account. A postal code is required to complete your order.
          Please update
          your address{' '}
          <a
            href='https://my.collegeboard.org/profile/information'
            className='cb-black1-color'
          >
            here
          </a>
          {' '}and then return back to this page to complete your order.
        </p>
        <p>
          <span>{address1}</span><br />
          <>
            {address2 || address3 ? <><span>{address2 && address3 ? `${address2}, ${address3}` : address2 ? address2 : ''}</span><br /></> : null}
          </>
          <span>{city && province ? `${city}, ${province}` : city ? `${city},` : ''} {state} {zip || intlPostalCode}</span><br />
          <span>{countryName ? countryName : ''}</span>
        </p>
      </div>
    </>}
  </Alert>
)

const useMissingZip = () => {
  const [showMissingZipAlert, setShowMissingZipAlert] = useState(false)
  const { loading, data = [] } = useCustomQuery([
    { query: QUERY_PROPERTIES },
    { query: QUERY_STUDENT_INFO },
    { query: QUERY_GET_ZIPCODE_REQUIRED_COUNTRIES }
  ])

  const [properties = [], studentInfo, countries] = data
  let appId

  if (properties) appId = properties?.find(p => p.propName === 'appId')?.propValue

  useEffect(() => {
    if (studentInfo?.countryName && countries?.length) {
      if (countries.includes(studentInfo.countryName)) {
        if ((studentInfo.countryName === 'USA' && !studentInfo.zip) || (studentInfo.countryName !== 'USA' && !studentInfo.intlPostalCode)) setShowMissingZipAlert(true)
      }
    }
  }, [countries, studentInfo?.countryName])

  return { component: MissingZipCodeAlert, showMissingZipAlert, props: { appId, ...studentInfo, loading } }
}

export default useMissingZip
