import { setContext } from '@apollo/client/link/context'
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client'
import getEnvConfig from '../../../utils/environment'
import { getJWTToken } from '../../../utils/auth'
import scoresandawards from '../policies/scoresandawards'
import query from '../policies/queries'
import searchresult from '../policies/searchresult'
import ordersandsendscores from '../policies/ordersandsendscores'

let client = null
export const createCache = () =>
  new InMemoryCache({ typePolicies: { ...searchresult, ...scoresandawards, ...ordersandsendscores, ...query } })

export const appsyncClient = () => {
  const { appSyncUrl } = getEnvConfig()
  const headers = setContext((request, { headers }) => ({
    headers: {
      ...headers,
      Authorization: `cbtoken-${getJWTToken()}`,
    },
  }))
  const appsyncLink = new ApolloLink.from([createHttpLink({ uri: appSyncUrl })])

  if (!client)
    client = new ApolloClient({
      link: headers.concat(appsyncLink),
      cache: createCache(),
    })

  return client
}
