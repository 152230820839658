const StudentInformation = ({
  firstName,
  lastName,
  address1,
  state,
  city,
  zip,
  address2,
  address3,
  countryName,
  intlPostalCode,
  province,
  title,
  middleInitial,
}) => (
  <div id="apscores-student-info">
    <h3 className="cb-margin-top-72 cb-margin-xs-top-48 cb-roboto cb-font-weight-xs-medium cb-margin-bottom-16">{title}</h3>
    <p className="cb-paragraph1-res cb-roboto-light cb-font-weight-xs-regular">
      Score reports will be sent with the information we have on file for you:
    </p>
    <div className="cb-paragraph1-res cb-roboto-light cb-font-weight-xs-regular cb-margin-top-24">
      <div>
        {firstName} {middleInitial ? `${middleInitial}` : ''} {lastName}
      </div>
      <div>{address1}</div>
      <div>{address2 && address3 ? `${address2}, ${address3}` : address2 ? address2 : ''}</div>
      <div>
        {city && province ? `${city}, ${province}` : city ? `${city}` : ''} {state} {zip || intlPostalCode}
        <div>{countryName ? countryName : ''}</div>
      </div>
    </div>
  </div>
)

StudentInformation.propTypes = {
  title: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  middleInitial: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  address3: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  state: PropTypes.string,
  countryName: PropTypes.string,
  zip: PropTypes.string,
  intlPostalCode: PropTypes.string,
}

export default StudentInformation
