import { Select, SpacerV } from '@cb/apricot-react'
import { validateAlphaNumeric, validateExactLength } from '../../../../utils/validations'
import { getYears } from '../../../../utils/date'
import ValidationInput from '../../../_common/forms/validationinput/ValidationInput'
import { BYREG_FIELD_ADMINYR, BYREG_FIELD_NUM } from './utils'

const maxLength = 8

const SearchByRegistration = ({ savedCriteria, disableFields, onFieldChange }) => {
  return (
    <fieldset disabled={disableFields}>
      <legend className="sr-only">Search By AP Registration Number</legend>
      <ValidationInput
        label="AP Registration Number"
        name={BYREG_FIELD_NUM}
        defaultValue={savedCriteria[BYREG_FIELD_NUM] || ''}
        floating
        floatingBg="light"
        maxLength={8}
        errorMsg={`Error: The value needs to be ${maxLength} characters.`}
        helperMsg={`Please enter ${maxLength} characters`}
        validate={value => validateAlphaNumeric(value) && validateExactLength(value, maxLength)}
        onChange={(value, state) => onFieldChange(BYREG_FIELD_NUM, value, state)}
      />
      <SpacerV size="24" />
      <Select
        label="Admin Year"
        name={BYREG_FIELD_ADMINYR}
        values={getYears(1998, 2)}
        defaultValue={savedCriteria[BYREG_FIELD_ADMINYR] || ''}
        floatingBg="light"
        floating
        onChange={value => onFieldChange(BYREG_FIELD_ADMINYR, value)}
      />
    </fieldset>
  )
}

SearchByRegistration.propTypes = {
  savedCriteria: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  disableFields: PropTypes.bool,
}

export default SearchByRegistration
