import ContentModule from '../../../_common/contentmodule/ContentModule'
import { usePropertiesQuery, useScoreAndAwardQuery } from '../../../../services/graphql/utils'
import { isDateWithinRange } from '../../../../utils/date'

import './registerforap.scss'

const RegisterForAPModule = () => {
  const { data: scores = {} } = useScoreAndAwardQuery()
  const { data: props = {} } = usePropertiesQuery()
  const showModule = scores?.hasNoScores && isDateWithinRange(props.registerForAPStartDate, props.registerForAPEndDate)

  return showModule ? (
    <ContentModule
      title="Get Started With AP"
      link={{ path: 'https://apstudents.collegeboard.org/what-is-ap', title: 'Explore AP', light: true }}
      className="cb-white-color cb-margin-bottom-24 apscores-register-ap"
    >
      <p>
        Taking AP gives you an opportunity to earn college credit or placement while in high school, along with many other
        benefits. With 40 AP courses, whatever your academic interest, you’ll find a course to match it.
      </p>
    </ContentModule>
  ) : null
}
export default RegisterForAPModule
