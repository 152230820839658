import CBUtils from '@cb/apricot/CBUtils'
import { formatDate, FORMATS } from '../../../../utils/date'
import { useCustomLazyQuery } from '../../../../services/graphql/utils'
import { QUERY_ORDER_DETAILS, QUERY_STUDENT_INFO } from '../../../../services/graphql/queries/queries'
import { canModifyOrder } from '../../../../utils/auth'
import Panel from '../../../_common/panel/Panel'
import Button from '../../../_common/button/Button'
import DeliveryStatus from './DeliveryStatus'
import { getDeliveryTitle } from '../../../../utils/orders'
import { useEffect } from 'react'
import PDFDownload from '../../../_common/pdfdownload/PDFDownload'
import { isFreeScoreSend, isOnlineOrder } from '../utils'
import {  ORDER_DETAILS_ERROR, PANEL_ORDERDETAILS, useTrackingError } from '../../../../utils/analytics'

// data can take two formats:
// QUERY_ORDER_DETAILS - { order, student }
// QUERY_STUDENT_INFO - { firstName, lastName, apUid }
const process = data => {
  const { order, student } = data || {}
  if (order && student) return { ...order, ...student }
  return data || {}
}

const OrderDetails = props => {
  const { dis, orderDate, eOrderId, orderId, channel } = props
  const triggerId = CBUtils.uniqueID(5, `${orderId}OrderDetails`)
  const [fetch, { data, loading, error }] = useCustomLazyQuery(orderId ? QUERY_ORDER_DETAILS : QUERY_STUDENT_INFO, { orderId })
  const [open, setOpen] = useState(false)
  const isOrder = isOnlineOrder(channel)
  const showPrice = isOrder || isFreeScoreSend(channel)
  const pdfContainerId = 'order-details-print-container'
  const {
    firstName,
    lastName,
    middleInitial,
    apUid,
    amountRefunded = 0,
    orderTotal = 0,
    lastFourDigitsOfCard,
    creditCardType,
    diCodes,
  } = process(data)

  const Section = ({ title, children, items }) => (
    <div>
      <h3 className="cb-text-uppercase cb-font-size-xsmall cb-roboto-medium cb-padding-left-24 cb-border-bottom cb-margin-top-16">
        {title}
      </h3>
      {items ? (
        <div className="cb-padding-left-24 cb-padding-right-24 cb-margin-top-8 cb-margin-bottom-32">
          {items.map((d, i) =>
            d.value && (!d.check || d.check()) ? (
              <div key={i} className="display-flex justify-content-between cb-margin-bottom-8">
                <h4 className="cb-font-size-regular cb-font-weight-regular">{d.label}</h4>
                <div className="cb-roboto-medium">{d.value}</div>
              </div>
            ) : null
          )}
        </div>
      ) : (
        children
      )}
    </div>
  )

  useEffect(() => {
    if (open && !data) fetch()
  }, [open])

  useTrackingError(error, { message: ORDER_DETAILS_ERROR })

  return (
    <>
      <Button id={triggerId} primary onClick={() => setOpen(true)} loading={loading}>
        View Order Details
      </Button>
      {error ? <p className="cb-red-color cb-font-size-small cb-margin-top-8">An error occurred retrieving your order details. Please try again later.</p> : null}
      {open && data ? (
        <Panel defaultOpen={true} title="Order Details" triggerId={triggerId} tracking={PANEL_ORDERDETAILS}>
          <div className="cb-padding-24 cb-margin-bottom-32 cb-gray5-bg cb-border-bottom">
            <p className="cb-font-size-small">
              <em>Below is your order receipt, please download it for your records.</em>
            </p>
            <PDFDownload
              tracking="past-order-detail.pdf"
              buttonTitle="Download Receipt"
              pdfTitle="Your AP Score Send Order Summary"
              className="cb-margin-top-16"
              pdfContainerId={pdfContainerId}
              filename="order-summary.pdf"
              pdfMaxHeight={800}
            />
          </div>
          <div id={pdfContainerId}>
            <Section
              title="Student Details"
              items={[
                {
                  label: 'Name',
                  value: `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`,
                },
                { label: 'AP ID', value: apUid },
              ]}
            />
            <Section
              title="Order Details"
              items={[
                { label: 'Order Date', value: formatDate(orderDate, FORMATS.mediumdate) },
                { label: 'Order Number', value: orderId },
                { label: 'eComm Order Number', value: eOrderId, check: canModifyOrder },
              ]}
            />
            <Section title="Institutions">
              {dis.map(d => {
                const price = diCodes?.find(di => di.diCode === d.diCode)?.diPrice || 0
                return (
                  <div key={d.diCode} className="cb-border-bottom cb-padding-16 cb-padding-left-24 cb-padding-right-24">
                    <div className="display-flex justify-content-between">
                      <div>
                        <h4 className="cb-h5 cb-font-size-regular cb-margin-right-8">
                          {d.diName} ({d.diCode})
                        </h4>
                        {d.diCity || d.diState || d.diCountry ? (
                          <div className="cb-font-size-small">
                            {d.diCity}
                            {d.diCity && (d.diState || d.diCountry) ? `, ${d.diState || d.diCountry}` : ''}
                          </div>
                        ) : null}
                      </div>
                      {showPrice ? <div className="cb-roboto-medium">${price}</div> : null}
                    </div>
                    <div className="cb-font-size-small">
                      <DeliveryStatus
                        {...d}
                        channel={channel}
                        orderDate={orderDate}
                        orderId={orderId}
                      />
                    </div>
                  </div>
                )
              })}
              <div className="cb-align-right cb-padding-right-24 cb-padding-top-16 cb-margin-bottom-48">
                {amountRefunded ? (
                  <div className="cb-font-size-small cb-margin-bottom-16">
                    <div>Original Cost: ${orderTotal}</div>
                    <div className="cb-green1-color cb-roboto-medium">Refund: -${amountRefunded}</div>
                  </div>
                ) : null}
                {showPrice ? <div className="cb-roboto-medium">Total Cost: ${orderTotal - amountRefunded}</div> : null}
                {creditCardType && lastFourDigitsOfCard ? (
                  <div className="cb-font-size-small">
                    Payment Method: {creditCardType} (ending in {lastFourDigitsOfCard})
                  </div>
                ) : null}
              </div>
            </Section>
          </div>
        </Panel>
      ) : null}
    </>
  )
}

OrderDetails.propTypes = {
  orderId: PropTypes.number,
}

export default OrderDetails
