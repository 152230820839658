import {ErrorNotification, SuccessNotification, InfoNotification} from '@cb/apricot-react-notification'

const Dialog = ({error, success, children, ...props}) => {
  const Component = error ? ErrorNotification : success ? SuccessNotification : InfoNotification
  return <Component {...props}>{children}</Component>
}

Dialog.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  title: PropTypes.string.isRequired,
  dismissable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inModal: PropTypes.bool,
  onDismiss: PropTypes.func
}

export default Dialog