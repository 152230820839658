import isEqual from 'lodash/isEqual'
import { Select } from '@cb/apricot-react'
import Button from '../../../_common/button/Button'
import SearchByID from './SearchByID'
import SearchByPersonalInfo from './SearchByPersonalInfo'
import SearchByRegistration from './SearchByRegistration'
import { VALID_STATE_ERR } from '../../../../utils/validations'
import {BYID_FIELDS, BYREG_FIELD_NUM, BYPERSON_FIELD_EMAIL, BYPERSON_FIELD_FN, BYPERSON_FIELD_LN} from './utils'
import useStudentSearch from '../../../../services/local/studentsearch'

const VALUE_ID = 'byId' //default
const VALUE_INFO = 'byInfo'
const VALUE_REG = 'byReg'
const SEARCH_TYPES = [
  { label: 'Search by ID', value: VALUE_ID },
  { label: 'Search by Personal Information', value: VALUE_INFO },
  { label: 'Search by AP Registration', value: VALUE_REG },
]
const FIELDNAME_TYPE = 'searchType'
let firstTimeSettingValue = true

const getFieldsetProps = type => {
  switch (type) {
    case VALUE_INFO:
      return {
        Component: SearchByPersonalInfo,
        validate: criteria => criteria[BYPERSON_FIELD_EMAIL] || Object.keys(criteria).length > 1,
      }
    case VALUE_REG:
      return { Component: SearchByRegistration, validate: criteria => criteria[BYREG_FIELD_NUM] }
    default:
      return { Component: SearchByID, validate: criteria => BYID_FIELDS.find(f => criteria[f]) }
  }
}

const SearchForm = ({ savedCriteria = {}, fetch, fetching }) => {
  const { clearSearchCriteria } = useStudentSearch()
  const { [FIELDNAME_TYPE]: savedType, ...remainingCriteria } = savedCriteria
  const [type, setType] = useState(savedType)
  const [criteria, setCriteria] = useState(remainingCriteria)
  const [clearedCount, setClearedCount] = useState(0)
  const { Component, validate } = getFieldsetProps(type)
  const isFormValid =
    !Object.values(criteria).find(c => c === VALID_STATE_ERR) && validate(criteria) && !isEqual(remainingCriteria, criteria)

  const submitForm = e => {
    e.preventDefault()
    if (isFormValid) fetch({ [FIELDNAME_TYPE]: type, ...criteria })
  }

  useEffect(() => {
    if (validate(criteria)) fetch()
  }, [])

  // This is needed because when this component is re-rendered after state update, it triggers onFieldChange function for
  // The child component being used which in turn updates criteria variable's state. So, we avoid that by setting this variable
  // 'firstTimeSettingValue' to use in the onFieldChange function.
  useEffect(() => {
      if (Object.values(criteria).length) firstTimeSettingValue = false
  }, [criteria[BYPERSON_FIELD_FN], criteria[BYPERSON_FIELD_LN]])

  return (
    <form onSubmit={submitForm}>
      <Select
        values={SEARCH_TYPES}
        name={FIELDNAME_TYPE}
        floating
        label="Search Type"
        floatingBg="light"
        defaultValue={type}
        onChange={value => {
          setType(value)
          setCriteria({})
          clearSearchCriteria()
        }}
      />
      <hr className="cb-margin-top-32 cb-margin-bottom-48 cb-border-top" />
      <Component
        key={`${type}-${clearedCount}`}
        savedCriteria={criteria}
        onFieldChange={(name, value, state) => {
          const hasError = state === VALID_STATE_ERR
          const { [name]: field, ...remaining } = criteria
          if ((name === BYPERSON_FIELD_FN || name === BYPERSON_FIELD_LN) && !criteria[name] && value.length && !firstTimeSettingValue) { firstTimeSettingValue = true }
          else if (hasError) setCriteria({ ...remaining, [name]: VALID_STATE_ERR })
          else if (Array.isArray(value) && !value?.length) setCriteria(remaining)
          else if (value) setCriteria({ ...remaining, [name]: value })
          else setCriteria(remaining)
        }}
        disableFields={fetching}
      />
      <div className="cb-margin-top-48">
        <Button primary title="Search" disabled={!isFormValid || fetching} type="submit" />
        <Button
          naked
          title="Clear search criteria"
          onClick={() => {
            setClearedCount(clearedCount + 1)
            setCriteria({})
          }}
          disabled={Object.values(criteria).filter(c => c).length === 0 || fetching}
        />
      </div>
    </form>
  )
}

SearchForm.propTypes = {
  savedCriteria: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
}

export default SearchForm
