import SendScores from '../components/student/sendscores/SendScores'
import ViewScores from '../components/student/viewscores/ViewScores'
import PastOrders from '../components/student/pastorders/PastOrders'
import HelpCenter from '../components/student/help/helpcenter/HelpCenter'
import FAQs from '../components/student/help/faqs/FAQs'
import ScoreRecipient from '../components/student/sendscores/scorerecipient/ScoreRecipient'
import ReviewOrder from '../components/student/sendscores/revieworder/ReviewOrder'
import DeliveryMethod from '../components/student/sendscores/deliverymethod/DeliveryMethod'
import CompleteOrder from '../components/student/sendscores/completeorder/CompleteOrder'
import StudentSearch from '../components/admin/studentsearch/StudentSearch'
import StudentDetails from '../components/admin/studentdetails/StudentDetails'
import StudentCompare from '../components/admin/studentcompare/StudentCompare'
import ActivityLog from '../components/admin/activitylog/ActivityLog'
import Reports from '../components/admin/reports/Reports'
import Properties from '../components/admin/properties/Properties'
import TermsAndConditions from '../components/student/terms/TermsAndConditions'
import { isAdmin, isRoleAdmin } from './auth'

export const ROUTE_BASENAME = '/view-scores'

export const APP_LINKS = {
  viewscores: '/',
  sendscores: '/send-scores/select-schools',
  revieworder: '/send-scores/review-order',
  deliverymethod: '/send-scores/select-delivery',
  completeorder: '/send-scores/order-confirmation',
  pastorders: '/past-score-sends',
  help: '/help',
  faqs: '/faqs',
  studentsearch: '/student-search',
  studentdetails: '/student-details',
  studentcompare: '/student-compare',
  activitylog: '/activity-log',
  reports: '/reports',
  properties: '/properties',
  terms: '/terms',
}

export const ROUTES = [
  { path: APP_LINKS.viewscores, component: ViewScores, pagetitle: 'View AP Scores' },
  { path: APP_LINKS.sendscores, component: SendScores, pagetitle: 'Send Your AP Scores' },
  { path: APP_LINKS.revieworder, component: SendScores, pagetitle: 'Review AP Scores' },
  { path: APP_LINKS.deliverymethod, component: SendScores, pagetitle: 'Delivery Method for AP Scores' },
  { path: APP_LINKS.completeorder, component: SendScores, pagetitle: 'Order Confirmation for AP Score Send' },
  { path: APP_LINKS.pastorders, component: PastOrders, pagetitle: 'Past Score Orders' },
  { path: APP_LINKS.help, component: HelpCenter, pagetitle: 'Need Help with Scores?' },
  { path: APP_LINKS.faqs, component: FAQs, pagetitle: 'Frequently Asked Questions' },
  { path: APP_LINKS.terms, component: TermsAndConditions, pagetitle: 'Terms and Conditions for AP Scores' },
  { path: APP_LINKS.studentsearch, component: StudentSearch, pagetitle: 'CSR Admin Panel', admin: true, authCheck: isRoleAdmin },
  {
    path: APP_LINKS.studentdetails,
    component: StudentDetails,
    pagetitle: 'Student Details',
    admin: true,
    authCheck: isRoleAdmin,
  },
  {
    path: APP_LINKS.studentcompare,
    component: StudentCompare,
    pagetitle: 'Compare Students',
    admin: true,
    authCheck: isRoleAdmin,
  },
  { path: APP_LINKS.activitylog, component: ActivityLog, pagetitle: 'Activity Log', admin: true, authCheck: isRoleAdmin },
  { path: APP_LINKS.reports, component: Reports, pagetitle: 'Reports', admin: true, authCheck: isAdmin },
  { path: APP_LINKS.properties, component: Properties, pagetitle: 'Application Properties', admin: true, authCheck: isAdmin },
]

export const ADMIN_NAVIGATION = [
  { path: APP_LINKS.studentsearch, title: 'Student Search' },
  {
    title: 'Program Admin',
    authCheck: isAdmin,
    paths: [
      { path: APP_LINKS.reports, title: 'Internal Reports' },
      { path: APP_LINKS.properties, title: 'Application Properties' },
    ],
  },
]

export const STUDENT_NAVIGATION = [
  { path: APP_LINKS.viewscores, title: 'Scores and Awards' },
  { path: APP_LINKS.sendscores, title: 'Send Scores', linkRequiredReportableScore: true },
  { path: APP_LINKS.pastorders, title: 'Past Score Sends' },
  { path: APP_LINKS.help, title: 'Help' },
]

export const SENDSCORES_ROUTES = [
  { path: APP_LINKS.sendscores, component: ScoreRecipient, step: 1 },
  { path: APP_LINKS.revieworder, component: ReviewOrder, step: 2 },
  { path: APP_LINKS.deliverymethod, component: DeliveryMethod, step: 3 },
  { path: APP_LINKS.completeorder, component: CompleteOrder, step: 5 },
]
