import { Checkbox } from '@cb/apricot-react'
import useOrderOperations from '../../../../../services/local/order'
import { canModifyOrder } from '../../../../../utils/auth'

import './deliveryselection.scss'

const DeliverySelection = ({ skipPayment }) => {
  const { updateOrder } = useOrderOperations()

  return (
    <div className="apscores-delivery-selection">
      {canModifyOrder() ? (
        <div className="cb-margin-bottom-48 cb-margin-xs-bottom-32">
          <Checkbox
            label={<div className="cb-paragraph1-res cb-font-weight-xs-medium">Process as Free Score Send</div>}
            defaultChecked={skipPayment}
            onChange={checked => {
              updateOrder({ skipPayment: checked })
            }}
          />
        </div>
      ) : null}
    </div>
  )
}


export default DeliverySelection
