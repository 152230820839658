import axios from 'axios'
import getEnvConfig from '../../utils/environment'

const QUERY_STRING =
  'jsonapi_include=1&include=field_items,field_items.field_items&fields[node--profile_collection_index]=title,field_items,field_external_description&fields[node--profile_collection]=title,field_items,field_external_description,field_short_title,field_overview&fields[node--help_detail]=title,body&fields[node--help_detail.body]=processed'

const convertData = cmsData => ({
  title: cmsData?.title,
    byCategory: cmsData?.field_items.map(d => ({
      id: d.id,
      topic: d.title,
      shortTitle: d.field_short_title,
      overviewDescription: d.field_overview.processed,
      description: d.field_external_description,
      faqs: d.field_items.map(item => ({
        question: item.title,
        answer: item.body?.processed,
      })),
    })),
  byQuestion: cmsData?.field_items.reduce(
    (arr, d) => [
      ...arr,
      ...d.field_items.map((item, index) => ({
        label: item.title,
        category: d.field_short_title,
        index,
      })),
    ],
    []
  ),
})

let cache = undefined
const useCMS = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(cache)

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true)
        const { cmsUrl, collectionId } = getEnvConfig()
        const url = `${cmsUrl}/${collectionId}?${QUERY_STRING}`
        const results = await axios.get(url)
        const formatted = convertData(results.data.data)
        setData(formatted)
        cache = formatted
      } catch (err) {
        console.warn(`Drupal Error: ${err}`)
        setError(`Error: ${err}`)
      }
      setLoading(false)
    }

    if (!data) fetch()
  }, [data])

  return { error, loading, data, loaded: Boolean(data && !loading) }
}

export default useCMS
