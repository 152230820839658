import { CONTAINER_WIDE_CLASS } from '../../../../utils/constants'
import './header.scss'

const Header = ({ title = 'AP Scores', subtitle = 'Your AP scores and awards' }) => {
  return (
    <div className="apscores-image-hero" data-testid="apscores-header">
      <div className="container">
        <div className="row">
          <div className={`${CONTAINER_WIDE_CLASS} align-self-center`}>
            <h2 className="cb-roboto">{title}</h2>
            <p className="cb-roboto-light cb-paragraph1-res">{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default Header
